import propertyMessages from '@/models/properties/property.messages';
import { AttributeType, SystemAttributeIdIdEnum } from '@/serverapi/api';
import { LocalesService } from '@/services/LocalesService';

export enum StyleFormat {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    NONE = 'NONE',
}

export enum Areas {
    LEFT_TOP = 'left_top',
    LEFT_MIDDLE = 'left_middle',
    LEFT_BOTTOM = 'left_bottom',
    CENTER_TOP = 'center_top',
    CENTER_MIDDLE = 'center_middle',
    CENTER_BOTTOM = 'center_bottom',
    RIGHT_TOP = 'right_top',
    RIGHT_MIDDLE = 'right_middle',
    RIGHT_BOTTOM = 'right_bottom',
}

export const edgeDisabledAreas = [
    Areas.LEFT_TOP,
    Areas.LEFT_MIDDLE,
    Areas.LEFT_BOTTOM,
    Areas.RIGHT_TOP,
    Areas.RIGHT_MIDDLE,
    Areas.RIGHT_BOTTOM,
    Areas.CENTER_TOP,
    Areas.CENTER_BOTTOM,
];

const intl = LocalesService.useIntl();

type TSystemAttributeType = AttributeType & { id: SystemAttributeIdIdEnum };

export const systemAttributeTypes: TSystemAttributeType[] = [
    {
        id: 'name',
        presetId: '',
        name: intl.formatMessage(propertyMessages.Name),
        valueType: 'STRING',
    },
    {
        id: 'objectTypeId',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Object Type']),
        valueType: 'STRING',
    },
    {
        id: 'edgeTypeId',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Edge Type']),
        valueType: 'STRING',
    },
    {
        id: 'symbolId',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Symbol type']),
        valueType: 'STRING',
    },
    {
        id: 'nodeId',
        presetId: '',
        name: 'ID',
        valueType: 'STRING',
    },
    {
        id: 'updatedBy',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Updated By']),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'createdBy',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Created By']),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'createdAt',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Created At']),
        valueType: 'DATE_TIME',
    },
    {
        id: 'deletedBy',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Deleted By']),
        valueType: 'PRINCIPAL',
    },
    {
        id: 'deletedAt',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Deleted At']),
        valueType: 'DATE_TIME',
    },
    {
        id: 'updatedAt',
        presetId: '',
        name: intl.formatMessage(propertyMessages['Updated At']),
        valueType: 'DATE_TIME',
    },
    {
        id: 'confidential',
        presetId: '',
        name: intl.formatMessage(propertyMessages.Confidential),
        valueType: 'BOOLEAN',
    },
    {
        id: 'userEditDisabled',
        presetId: '',
        name: intl.formatMessage(propertyMessages.UserEditDisabled),
        valueType: 'BOOLEAN',
    },
    {
        id: 'scriptEngineEditDisabled',
        presetId: '',
        name: intl.formatMessage(propertyMessages.ScriptEngineEditDisabled),
        valueType: 'BOOLEAN',
    },
];

export const modelSystemAttributeTypes: TSystemAttributeType[] = systemAttributeTypes.filter(
    ({ id }) => id !== 'objectTypeId' && id !== 'symbolId',
);
