import { fork, put, select, takeEvery } from 'redux-saga/effects';
import { workspaceAddTab, workspaceChangeTabTitle } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemContextMenuAction } from '../actions/tree.actions';
import { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_IN_DIALOG,
    OPEN_SEARCH_PATH_DIALOG,
    SET_SEARCH_PATH_ELEMENT,
} from '../actionsTypes/search.actionTypes';
import { setRootSearchNodeId, setSearchData } from '../actions/search.actions';
import {
    TSetSearchPathElementAction,
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
    TOpenSearchInDialogAction,
} from '../actions/search.actions.types';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { NodeId, PathResponse, SearchRequest, SearchResult } from '../serverapi/api';
import { SearchSelectors } from '../selectors/dbSearch.selector';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TSearchDataListItem, TSearcParams } from '../reducers/search.reducer.types';
import { OPEN_BD_SEARCH_ACTION } from '../actionsTypes/editor.actionTypes';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import { openNode } from '../actions/openNode.actions';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { generateCustomNodeId } from '../utils/nodeId.utils';
import {
    isTabSearchable,
    searchRulesToSearchRuleWithValueId,
    searchRuleWithValueIdToSearchRules,
} from '../utils/bdSearchTab.utils';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import { filterTreeIncludeTypes, TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode, TTreeEntityState } from '@/models/tree.types';
import { initSearchParams } from '@/reducers/search.reducer';

type TGetSearchPathAction = {
    payload: {
        id: string;
        nodeId: NodeId;
    };
};

function* handleOpenSearchTab({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.DB_SEARCH) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));

        const contentLoadingPageTab: TWorkspaceTab = {
            title: `${intl.formatMessage(messages.dbSearch)} «${name}»`,
            nodeId: generateCustomNodeId(nodeId, 'SearchTab'),
            type: WorkSpaceTabTypes.DB_SEARCH,
            mode: EditorMode.Read,
            params: {
                nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };

        yield fork(getSearchPath, { payload: { id: nodeId.id, nodeId } });
        yield put(
            setSearchData({
                id: nodeId.id,
                nodeId,
                name,
                type,
                searchParams: {
                    ...initSearchParams,
                    rootSearchNodeId: nodeId,
                },
            }),
        );
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleOpenBdSearchAction() {
    const tab: TWorkspaceTab | undefined = yield select(TabsSelectors.getActiveTab);
    const treeStructure: TreeNode[] = yield select(TreeSelectors.treeStructure);
    const treeStructureChildren: TreeNode[] = treeStructure[0]?.children || [];
    const onlyRepositories: TreeNode[] = filterTreeIncludeTypes(treeStructureChildren, [TreeItemType.Repository]);
    const isSingleRepository: boolean = onlyRepositories.length === 1;

    let nodeId: NodeId | undefined;

    if (isSingleRepository) {
        const singleRepositoryNodeId: NodeId | undefined = treeStructureChildren[0]?.nodeId;

        if (!singleRepositoryNodeId) return;

        nodeId = singleRepositoryNodeId;
    } else if (tab) {
        if (!isTabSearchable(tab)) return;

        nodeId = tab.nodeId;
    } else {
        return;
    }

    yield openDbSearchTab(nodeId);
}

function* openDbSearchTab(nodeId: NodeId) {
    const { serverId, repositoryId } = nodeId;
    const repositoryNodeId: NodeId = { id: repositoryId, repositoryId, serverId };
    const repositoryName: string = yield select(TreeSelectors.getNodeNameById(repositoryNodeId));

    yield put(
        treeItemContextMenuAction({
            nodeId: repositoryNodeId,
            name: repositoryName,
            action: TreeItemContextMenuAction.DB_SEARCH,
            type: TreeItemType.Repository,
        }),
    );
}

function* getSearchPath({ payload: { id, nodeId } }: TGetSearchPathAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const { serverId, repositoryId } = nodeId;
    const response: PathResponse = yield TreeDaoService.getNodePath(serverId, nodeId.id, repositoryId);

    yield put(setSearchData({ id, path: `${server.name}/${response.path}` }));
}

function* handleOpenSearchPathDialod() {
    yield put(openDialog(DialogType.SEARCH_PATH));
}

function* handleSetSearchPathElement({ payload: { nodeId, type, name } }: TSetSearchPathElementAction) {
    const id = yield select(SearchSelectors.getId);
    const tab = yield select(TabsSelectors.getActiveTab);
    const currentNodeId: NodeId = { ...tab.nodeId, id };
    const isDialogSearch = yield select(SearchSelectors.isDialogSearch(currentNodeId));
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (tab && !isDialogSearch) {
        yield put(workspaceChangeTabTitle(tab, `${intl.formatMessage(messages.dbSearch)} «${name}»`));
    }

    yield fork(getSearchPath, { payload: { id, nodeId } });
    yield put(setRootSearchNodeId({ ...tab.nodeId, id }, nodeId));
    yield put(setSearchData({ id, name, type }));
}

function* handleGetSearchResult({
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, rootSearchNodeId },
}: TGetSearchResultAction) {
    const serverId = yield select(SearchSelectors.getServerId);
    const id = yield select(SearchSelectors.getId);

    if (serverId) {
        const nodeId: NodeId = yield select(SearchSelectors.getNodeId);

        try {
            yield put(setSearchData({ id, isLoading: true }));

            const response: SearchResult[] = yield SearchDaoService.getExtendedSearchResponse({
                rootSearchNodeId,
                searchText,
                includePath: true,
                includeCount: false,
                searchVisibility,
                searchRules: searchRuleWithValueIdToSearchRules(searchRules),
                nodeTypes: searchNodeTypes,
            });

            const searchResult: TSearchDataListItem[] = response.map((item) => ({
                multilingualName: item.multilingualName,
                path: `${item.path}`,
                type: item.nodeType as TreeItemType,
                elementType: item.elementTypeId || '',
                nodeId: {
                    ...item.nodeId,
                    serverId: nodeId.serverId,
                },
                deleted: item.deleted,
            }));

            yield put(setSearchData({ id, searchResult }));
        } finally {
            yield put(setSearchData({ id, isLoading: false }));
        }
    }
}

function* handleOpenModelOnCanvas({ payload: { nodeId, type, multilingualName } }: TOpenModelOnCanvasAction) {
    if (
        [
            TreeItemType.Model,
            TreeItemType.Matrix,
            TreeItemType.Wiki,
            TreeItemType.Spreadsheet,
            TreeItemType.Kanban,
            TreeItemType.SimulationModeling,
        ].includes(type)
    ) {
        yield put(openNode({ nodeId, type }));
    } else {
        if (type === TreeItemType.Folder) {
            yield put(openNode({ nodeId, type }));
        }

        yield put(
            treeItemContextMenuAction({
                nodeId,
                name: LocalesService.internationalStringToString(multilingualName),
                type,
                action: TreeItemContextMenuAction.PROPERTIES,
            }),
        );
    }
}

function* handleOpenInDialog({ payload: { nodeId, searchRequests } }: TOpenSearchInDialogAction) {
    if (searchRequests.length === 0) {
        const repositoryNodeId: NodeId = { ...nodeId, id: nodeId.repositoryId };
        yield fork(getSearchPath, { payload: { id: nodeId.id, nodeId: repositoryNodeId } });
        const treeNode: TTreeEntityState | undefined = yield select(TreeSelectors.itemById(repositoryNodeId));
        if (treeNode) {
            yield put(
                setSearchData({
                    id: nodeId.id,
                    nodeId,
                    name: treeNode.name,
                    type: treeNode.type,
                    searchParams: {
                        ...initSearchParams,
                        rootSearchNodeId: repositoryNodeId,
                    },
                }),
            );
        }
    } else {
        const searchRequest: SearchRequest = searchRequests[0];

        const {
            rootSearchNodeId,
            searchVisibility = 'NOT_DELETED',
            searchText = '',
            searchRules = [],
            nodeTypes = [],
        } = searchRequest;

        const treeNode: TTreeEntityState | undefined = yield select(TreeSelectors.itemById(rootSearchNodeId));

        const searchParams: TSearcParams = {
            rootSearchNodeId,
            nodeTypes,
            searchRules: searchRulesToSearchRuleWithValueId(searchRules),
            searchText,
            searchVisibility,
        };
        yield fork(getSearchPath, { payload: { id: nodeId.id, nodeId: rootSearchNodeId } });
        yield put(
            setSearchData({ id: nodeId.id, nodeId: nodeId, name: treeNode?.name, type: treeNode?.type, searchParams }),
        );
    }
}

export function* searchSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenSearchTab);
    yield takeEvery(OPEN_SEARCH_PATH_DIALOG, handleOpenSearchPathDialod);
    yield takeEvery(SET_SEARCH_PATH_ELEMENT, handleSetSearchPathElement);
    yield takeEvery(GET_SEARCH_RESULT, handleGetSearchResult);
    yield takeEvery(OPEN_MODEL_ON_CANVAS, handleOpenModelOnCanvas);
    yield takeEvery(OPEN_BD_SEARCH_ACTION, handleOpenBdSearchAction);
    yield takeEvery(OPEN_SEARCH_IN_DIALOG, handleOpenInDialog);
}
