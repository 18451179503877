import { addUndoRedoState, clearUndoRedoState } from '@/actions/undoRedo.actions';
import { getLockingTool, SaveModelLockTool } from '@/modules/Editor/classes/SaveModelLockTool';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { objectDefinitionsAdd } from '../../../actions/entities/objectDefinition.actions';
import { unlock } from '../../../actions/lock.actions';
import { showNotificationByType } from '../../../actions/notification.actions';
import { recentAddModel } from '../../../actions/recent.actions';
import { workspaceActivateTab, workspaceAddTab, workspaceRemoveTab } from '../../../actions/tabs.actions';
import { TWorkspaceTabsRemoveAction } from '../../../actions/tabs.actions.types';
import { treeItemFetchChildSuccess } from '../../../actions/tree.actions';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '../../../actionsTypes/tabs.actionTypes';
import { TabsBusActions } from '../../../actionsTypes/tabsBus.actionTypes';
import { ObjectDefinitionImpl } from '../../../models/bpm/bpm-model-impl';
import { IMatrixNode } from '../../../models/bpm/bpm-model-impl.types';
import { EditorMode } from '../../../models/editorMode';
import { NotificationType } from '../../../models/notificationType';
import { IWorkspaceTabItemMatrixParams, TMatrixTabType, TWorkspaceTab } from '../../../models/tab.types';
import { TreeNode, TTreeEntityState } from '../../../models/tree.types';
import { presetLoadModelTypes } from '../../../sagas/notation.saga';
import { getContentLoadingPageTab } from '../../../sagas/utils';
import { getAbsentNodesId } from '../../../sagas/utils/matrix.saga.utils';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { TabsSelectors } from '../../../selectors/tabs.selectors';
import { getNodeWithChildrenById, getTreeItems, TreeSelectors } from '../../../selectors/tree.selectors';
import { MatrixLane, NodeId, ObjectDefinitionNode } from '../../../serverapi/api';
import { LocalStorageDaoService } from '../../../services/dao/LocalStorageDaoService';
import { LocalesService } from '../../../services/LocalesService';
import { objectDefinitionService } from '../../../services/ObjectDefinitionService';
import { setServerIdToNodeOriginal } from '../../../utils/nodeId.utils';
import { TreeItemType } from '../../Tree/models/tree';
import {
    matrixCreateSuccess,
    matrixOpen,
    matrixRequestSuccess,
    matrixSaveRequest,
    matrixSaveRequestFailure,
    matrixSaveRequestSuccess,
    matrixSaveFromStoreRequest,
    changeMatrixNodeProperties,
} from '../actions/matrix.actions';
import {
    TMatrixDefaultAction,
    TMatrixDefaultRequestAction,
    TMatrixOpenAction,
    TMatrixOpenByNodeIdAction,
    TMatrixSaveFromStoreRequestAction,
    TRefreshMatrixAction,
} from '../actions/matrix.actions.types';
import {
    MATRIX_CREATE,
    MATRIX_OPEN,
    MATRIX_OPEN_BY_NODE_ID,
    MATRIX_SAVE_FROM_STORE_REQUEST,
    MATRIX_SAVE_REQUEST,
    REFRESH_MATRIX,
} from '../actionTypes/matrix.actionTypes';
import { MatrixDaoService } from '../dao/MatrixDaoService';
import messages from '../MatrixEditor/MatrixEditor.messages';
import { MatrixSelectors } from '../selectors/matrix.selectors';
import { MatrixEditorSelectors } from '../selectors/matrixEditor.selectors';
import { matrixClearSelectedCells } from '../actions/matrixEditor.actions';

function* handleMatrixOpen(action: TMatrixOpenAction) {
    const { matrix, mode } = action.payload;
    const workspaceTab: TMatrixTabType = <TMatrixTabType>{
        title: matrix.name,
        type: WorkSpaceTabTypes.MARTIX_EDITOR,
        nodeId: matrix.nodeId,
        content: matrix,
        mode: mode || EditorMode.Read,
        params: <IWorkspaceTabItemMatrixParams>{ content: matrix },
    };
    yield put(workspaceAddTab(workspaceTab));

    const locale: Locale = yield select(getCurrentLocale);

    yield put(
        recentAddModel({
            nodeId: matrix.nodeId,
            type: TreeItemType.Matrix,
            parentId: matrix.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: matrix.name,
            modelTypeId: WorkSpaceTabTypes.MARTIX_EDITOR,
            modelTypeName: LocalesService.useIntl(locale).formatMessage(messages.matrixModel),
            messageDescriptor: messages.matrixModel,
        }),
    );
    if (matrix.content) {
        yield put(changeMatrixNodeProperties(matrix));
        yield put(addUndoRedoState(matrix.nodeId, matrix.content, matrix.type));
    }
}

export function* handleMatrixCreate(action: TMatrixDefaultAction) {
    let { matrix } = action.payload;
    const columns: MatrixLane[] = [];
    const rows: MatrixLane[] = [];
    for (let i = 0; i < 15; i++) {
        columns.push({ id: uuid() });
        rows.push({ id: uuid() });
    }

    matrix = {
        ...matrix,
        content: {
            cells: [],
            columns,
            rows,
            rowHeaderWidth: 165,
            columnHeaderHeight: 165,
            cellSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            columnSettings: {
                id: uuid(),
                isAutomatic: false,
            },
            rowSettings: {
                id: uuid(),
                isAutomatic: false,
            },
        },
        type: TreeItemType.Matrix,
        serverId: matrix.parentNodeId?.serverId,
    } as IMatrixNode;

    const newTreeNode: TreeNode = {
        nodeId: matrix.nodeId,
        name: matrix.name,
        parentNodeId: matrix.parentNodeId && matrix.parentNodeId,
        type: TreeItemType.Matrix,
        hasChildren: !!(matrix.children && matrix.children.length > 0),
        countChildren: matrix?.children?.length || 0,
    };
    const parentNodeWithChildren: TTreeEntityState = yield select(getNodeWithChildrenById(matrix.nodeId));

    if (parentNodeWithChildren.childrenIds) {
        parentNodeWithChildren.childrenIds.push(matrix.nodeId.id);
    } else {
        parentNodeWithChildren.childrenIds = [matrix.nodeId.id];
    }

    const createdMatrix: IMatrixNode = yield handleMatrixSaveRequest(matrixSaveRequest(matrix));
    yield put(changeMatrixNodeProperties(createdMatrix));

    yield put(
        treeItemFetchChildSuccess({
            parentNodeId: createdMatrix.parentNodeId!,
            child: [newTreeNode],
        }),
    );

    yield put(matrixOpen(createdMatrix, EditorMode.Edit));
    yield put(matrixCreateSuccess(createdMatrix));
}

function* handleMatrixSaveRequest(action: TMatrixDefaultRequestAction) {
    const {
        matrix,
        matrix: {
            nodeId: { serverId },
        },
    } = action.payload;

    try {
        const savedMatrix: IMatrixNode = yield MatrixDaoService.saveMatrix(matrix as IMatrixNode);
        yield put(matrixSaveRequestSuccess(savedMatrix));
        return savedMatrix;
    } catch (e) {
        yield put(matrixSaveRequestFailure(serverId));
        throw e;
    }
}

function* handleMatrixSaveFromStoreRequest(action: TMatrixSaveFromStoreRequestAction) {
    const { nodeId } = action.payload;

    const isMatrixUnsaved: IMatrixNode = yield select(MatrixEditorSelectors.isMatrixUnsaved(nodeId));
    if (!isMatrixUnsaved) return;

    const matrix: IMatrixNode | undefined = yield select(MatrixSelectors.byId(nodeId));

    if (matrix) {
        const lock: SaveModelLockTool = getLockingTool();

        lock.addLock(nodeId.id);
        try {
            const data: IMatrixNode = yield MatrixDaoService.saveMatrix(matrix as IMatrixNode);
            yield put(matrixSaveRequestSuccess(data));
        } catch (e) {
            yield put(matrixSaveRequestFailure(nodeId.serverId));
            throw e;
        } finally {
            lock.deleteLock(nodeId.id);
        }
    }
}

function* handleMatrixOpenByNodeId(action: TMatrixOpenByNodeIdAction) {
    const { nodeId } = action.payload;
    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(nodeId));
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    if (tab) {
        yield put(workspaceActivateTab(tab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }

    try {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));

        yield put(workspaceAddTab(contentLoadingPageTab));
        yield presetId && presetLoadModelTypes(nodeId.serverId, presetId);

        const matrix: IMatrixNode | undefined = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);
        if (matrix) {
            setServerIdToNodeOriginal(matrix, nodeId.serverId);

            const treeItemsById: { [id: string]: TTreeEntityState } = yield select(
                getTreeItems(nodeId.serverId, nodeId.repositoryId),
            );

            const absentNodesId: NodeId[] = getAbsentNodesId(matrix, treeItemsById);

            if (absentNodesId.length) {
                const objDefinitions: ObjectDefinitionNode[] = yield call(() =>
                    objectDefinitionService().loadObjectsFromServer(nodeId.serverId, absentNodesId),
                );
                if (objDefinitions.length) {
                    yield put(objectDefinitionsAdd(objDefinitions as ObjectDefinitionImpl[]));
                }
            }

            yield put(matrixRequestSuccess(matrix));
            yield put(matrixOpen(matrix));
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        }
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }
}

function* handleTabMatrixClose(action: TWorkspaceTabsRemoveAction) {
    const {
        workspaceTab,
        workspaceTab: { content, nodeId, mode },
    } = action.payload;

    if (content?.type === TreeItemType.Matrix) {
        yield put(workspaceRemoveTab(workspaceTab));
        yield put(clearUndoRedoState(nodeId));
        yield put(matrixClearSelectedCells(nodeId));
        if (mode === EditorMode.Edit) {
            yield put(matrixSaveFromStoreRequest(nodeId));
            yield put(unlock(nodeId, 'MATRIX'));
        }
    }
}

function* handleRefreshMatrix(action: TRefreshMatrixAction) {
    const { nodeId } = action.payload;

    const lock: SaveModelLockTool = getLockingTool();
    if (lock.isLocked(nodeId.id)) {
        yield put(showNotificationByType(NotificationType.MATRIX_IS_SAVING));
        return;
    }

    try {
        yield handleMatrixSaveFromStoreRequest(matrixSaveFromStoreRequest(nodeId));

        const matrix: IMatrixNode | undefined = yield MatrixDaoService.getMatrix(nodeId.repositoryId, nodeId.id);
        if (matrix) {
            setServerIdToNodeOriginal(matrix, nodeId.serverId);
            yield put(changeMatrixNodeProperties(matrix));
            yield put(matrixSaveRequestSuccess(matrix));
            yield put(clearUndoRedoState(nodeId));
            yield put(showNotificationByType(NotificationType.MATRIX_HAS_REFRESHED));
        }
    } catch (e) {
        yield put(matrixSaveRequestFailure(nodeId.serverId));
        throw e;
    }
}

export function* matrixSaga() {
    yield takeEvery(MATRIX_CREATE, handleMatrixCreate);
    yield takeEvery(MATRIX_OPEN, handleMatrixOpen);
    yield takeEvery(MATRIX_OPEN_BY_NODE_ID, handleMatrixOpenByNodeId);
    yield takeEvery(MATRIX_SAVE_REQUEST, handleMatrixSaveRequest);
    yield takeEvery(MATRIX_SAVE_FROM_STORE_REQUEST, handleMatrixSaveFromStoreRequest);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabMatrixClose);
    yield takeEvery(REFRESH_MATRIX, handleRefreshMatrix);
}
