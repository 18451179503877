import { put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import {
    REPORT_CREATE,
    REPORT_DROP_NODES,
    REPORT_OPEN,
    REPORT_OPEN_BY_NODE_ID,
    REPORT_REFRESH,
    REPORT_SAVE_REQUEST,
    REPORT_SEARCH_REQUEST,
} from '../actions/report.actionTypes';
import {
    TReportCreateAction,
    TReportDropNodesAction,
    TReportOpenAction,
    TReportOpenByNodeIdAction,
    TReportRefreshAction,
    TReportSaveRequestAction,
    TReportSearchRequestAction,
} from '../actions/report.actions.types';
import { TreeNode } from '@/models/tree.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { NodeId, ReportNode, SearchRequest, SearchResult } from '@/serverapi/api';
import { ReportDaoService } from '../dao/ReportDaoService';
import {
    reportAddNodes,
    reportClearSelectColumn,
    reportOpen,
    reportRequestSuccess,
    reportSaveRequest,
    reportSaveRequestFailure,
    reportSaveRequestSuccess,
    reportSearchRequestFailure,
    reportSearchRequestSuccess,
} from '../actions/report.actions';
import { treeItemFetchChildSuccess } from '@/actions/tree.actions';
import { EditorMode } from '@/models/editorMode';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { workspaceActivateTab, workspaceAddTab, workspaceRemoveTab } from '@/actions/tabs.actions';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { recentAddModel } from '@/actions/recent.actions';
import { LocalesService } from '@/services/LocalesService';
import messages from './reportSaga.messages';
import attributeMessages from '../AttributeSelectDialog/AttributeSelectDialog.messages';
import { TReportTabType, TWorkspaceTab } from '@/models/tab.types';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { getContentLoadingPageTab } from '@/sagas/utils';
import { LocalStorageDaoService } from '@/services/dao/LocalStorageDaoService';
import { TabsBusActions } from '@/actionsTypes/tabsBus.actionTypes';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { presetLoadModelTypes } from '@/sagas/notation.saga';
import { compareNodeIds, setServerIdToNodeOriginal } from '@/utils/nodeId.utils';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { fetchNodesWithAttributes } from '@/actions/nodes.actions';
import { ReportSelectors } from '../selectors/report.selectors';
import { getLockingTool, SaveModelLockTool } from '@/modules/Editor/classes/SaveModelLockTool';
import { WORKSPACE_TABS_REMOVE_REQUEST } from '@/actionsTypes/tabs.actionTypes';
import { TWorkspaceTabsRemoveAction } from '@/actions/tabs.actions.types';
import { unlock } from '@/actions/lock.actions';
import { INode } from '@/models/bpm/bpm-model-impl.types';
import { TSearchDataListItem, TSearcParams } from '@/reducers/search.reducer.types';
import { searchRulesToSearchRuleWithValueId } from '@/utils/bdSearchTab.utils';
import { setSearchData } from '@/actions/search.actions';
import { SearchDaoService } from '@/services/dao/SearchDAOService';

export enum ReportDefaultAttributeTypeIds {
    name = 'name',
    nodeType = 'nodeType',
}

export function* handleReportCreate(action: TReportCreateAction) {
    let {
        report,
        report: { parentNodeId },
    } = action.payload;

    if (!parentNodeId) return;

    const locale: Locale = yield select(getCurrentLocale);

    report.reportData = {
        fillingType: 'MANUAL',
        columns: [
            {
                attributeType: 'SYSTEM',
                attributeTypeId: ReportDefaultAttributeTypeIds.name,
                columnId: uuid(),
                orderNumber: 0,
                columnName: LocalesService.useIntl(locale).formatMessage(attributeMessages.Name),
                filterEnabled: true,
                visibilityEnabled: true,
            },
            {
                attributeType: 'SYSTEM',
                attributeTypeId: ReportDefaultAttributeTypeIds.nodeType,
                columnId: uuid(),
                orderNumber: 1,
                columnName: LocalesService.useIntl(locale).formatMessage(attributeMessages.NodeType),
                filterEnabled: true,
                visibilityEnabled: true,
            },
        ],
    };

    const newTreeNode: TreeNode = {
        nodeId: report.nodeId,
        name: report.name,
        parentNodeId,
        type: TreeItemType.Report,
        hasChildren: false,
        countChildren: 0,
    };

    const createdReport: ReportNode = yield handleReportSaveRequest(reportSaveRequest(report));

    yield put(
        treeItemFetchChildSuccess({
            parentNodeId,
            child: [newTreeNode],
        }),
    );

    yield put(reportOpen(createdReport, EditorMode.Edit));
}

function* handleReportSaveRequest(action: TReportSaveRequestAction) {
    const {
        report,
        report: { nodeId },
    } = action.payload;

    const isReportUnsaved: boolean = yield select(ReportSelectors.isUnsaved(nodeId));

    if (!isReportUnsaved) return;

    const lock: SaveModelLockTool = getLockingTool();
    lock.addLock(nodeId.id);

    try {
        const savedReport: ReportNode = yield ReportDaoService.saveReport(report);
        yield put(reportSaveRequestSuccess(savedReport));
        return savedReport;
    } catch (e) {
        yield put(reportSaveRequestFailure());
        throw e;
    } finally {
        lock.deleteLock(nodeId.id);
    }
}

function* handleReportOpen(action: TReportOpenAction) {
    const { report, mode } = action.payload;
    const workspaceTab: TReportTabType = <TReportTabType>{
        title: report.name,
        type: WorkSpaceTabTypes.REPORT_EDITOR,
        nodeId: report.nodeId,
        content: report,
        mode: mode || EditorMode.Read,
    };
    yield put(workspaceAddTab(workspaceTab));

    const locale: Locale = yield select(getCurrentLocale);

    yield put(
        recentAddModel({
            nodeId: report.nodeId,
            type: TreeItemType.Report,
            parentId: report.parentNodeId || null,
            createdAt: new Date().toISOString(),
            title: report.name,
            modelTypeId: WorkSpaceTabTypes.REPORT_EDITOR,
            modelTypeName: LocalesService.useIntl(locale).formatMessage(messages.reportModel),
            messageDescriptor: messages.reportModel,
        }),
    );
}

function* handleReportOpenByNodeId(action: TReportOpenByNodeIdAction) {
    const { reportNodeId: nodeId } = action.payload;
    const tab: TWorkspaceTab = yield select(TabsSelectors.byId(nodeId));
    const contentLoadingPageTab = yield getContentLoadingPageTab(nodeId);

    if (tab) {
        yield put(workspaceActivateTab(tab));
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);

        return;
    }

    try {
        const presetId: string = yield select(TreeSelectors.presetById(nodeId));

        yield put(workspaceAddTab(contentLoadingPageTab));
        yield presetId && presetLoadModelTypes(nodeId.serverId, presetId);

        const report: (ReportNode & INode) | undefined = yield ReportDaoService.getReport(
            nodeId.repositoryId,
            nodeId.id,
        );
        if (report) {
            setServerIdToNodeOriginal(report, nodeId.serverId);

            yield put(reportRequestSuccess(report));
            yield put(reportOpen(report));
            LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_SUCCESSFUL);
        }
    } catch (e) {
        LocalStorageDaoService.setTabsBusAction(TabsBusActions.NODE_OPEN_FAILED);
        throw e;
    } finally {
        yield put(workspaceRemoveTab(contentLoadingPageTab));
    }
}

function* handleReportDropNodes(action: TReportDropNodesAction) {
    const { reportNodeId, initDroppedNodeId } = action.payload;
    const selectedNodes: TreeNode[] = yield select(SelectedNodesSelector.getNodes());

    const isDroppedNodeSelected: boolean = selectedNodes.some((node) => compareNodeIds(node.nodeId, initDroppedNodeId));
    let selectedNodeIds: NodeId[] = [];
    if (!isDroppedNodeSelected) {
        selectedNodeIds = [initDroppedNodeId];
    } else {
        selectedNodeIds = selectedNodes.map((node) => node.nodeId);
    }

    yield put(fetchNodesWithAttributes(selectedNodeIds));
    yield put(reportAddNodes(reportNodeId, selectedNodeIds));
}

function* handleTabReportClose(action: TWorkspaceTabsRemoveAction) {
    const {
        workspaceTab,
        workspaceTab: { content, nodeId, mode },
    } = action.payload;

    if (content?.type === TreeItemType.Report) {
        yield put(workspaceRemoveTab(workspaceTab));

        yield put(reportClearSelectColumn(nodeId));
        if (mode === EditorMode.Edit) {
            const report: ReportNode | undefined = yield select(ReportSelectors.byId(nodeId));
            if (report) {
                yield put(reportSaveRequest(report));
            }
            yield put(unlock(nodeId, 'REPORT'));
        }
    }
}

function* reportSearchRequest({ payload: { reportNodeId, searchRequests } }: TReportSearchRequestAction) {
    const { id } = reportNodeId;

    if (searchRequests.length === 0) {
        yield put(reportSearchRequestSuccess(reportNodeId, []));
        return;
    }

    try {
        yield put(setSearchData({ id, isLoading: true }));
        const searchRequest: SearchRequest = searchRequests[0];
        const response: SearchResult[] = yield SearchDaoService.getExtendedSearchResponse({
            ...searchRequest,
            includeAttributes: true,
            includeCount: false,
        });
        const searchResult: TSearchDataListItem[] = response.map((item) => ({
            multilingualName: item.multilingualName,
            path: `${item.path}`,
            type: item.nodeType as TreeItemType,
            elementType: item.elementTypeId || '',
            nodeId: {
                ...item.nodeId,
                serverId: reportNodeId.serverId,
            },
            deleted: item.deleted,
        }));
        const {
            rootSearchNodeId,
            searchVisibility = 'NOT_DELETED',
            searchText = '',
            searchRules = [],
            nodeTypes = [],
        } = searchRequest;
        const searchParams: TSearcParams = {
            rootSearchNodeId,
            nodeTypes,
            searchRules: searchRulesToSearchRuleWithValueId(searchRules),
            searchText,
            searchVisibility,
        };
        yield put(reportSearchRequestSuccess(reportNodeId, response));
        yield put(setSearchData({ id, nodeId: reportNodeId, searchResult, searchParams }));
    } catch {
        yield put(reportSearchRequestFailure(reportNodeId));
    } finally {
        yield put(setSearchData({ id, isLoading: false }));
    }
}

function* handleReportRefresh(action: TReportRefreshAction) {
    const {
        reportNodeId: { id, repositoryId, serverId },
    } = action.payload;

    const report: (ReportNode & INode) | undefined = yield ReportDaoService.getReport(repositoryId, id);
    if (report) {
        setServerIdToNodeOriginal(report, serverId);

        yield put(reportRequestSuccess(report));
    }
}

export function* reportSaga() {
    yield takeEvery(REPORT_CREATE, handleReportCreate);
    yield takeEvery(REPORT_OPEN, handleReportOpen);
    yield takeEvery(REPORT_SAVE_REQUEST, handleReportSaveRequest);
    yield takeEvery(REPORT_OPEN_BY_NODE_ID, handleReportOpenByNodeId);
    yield takeEvery(REPORT_DROP_NODES, handleReportDropNodes);
    yield takeEvery(WORKSPACE_TABS_REMOVE_REQUEST, handleTabReportClose);
    yield takeEvery(REPORT_SEARCH_REQUEST, reportSearchRequest);
    yield takeEvery(REPORT_REFRESH, handleReportRefresh);
}
