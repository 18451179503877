import { defineMessages } from 'react-intl';

export default defineMessages({
    scriptSchedulerOpen: {
        id: 'TreeContextMenu.scriptSchedulerOpen',
        defaultMessage: 'Расписание скриптов',
    },
    subscribe: {
        id: 'TreeContextMenu.subscribe',
        defaultMessage: 'Подписаться',
    },
    unsubscribe: {
        id: 'TreeContextMenu.unsubscribe',
        defaultMessage: 'Отписаться',
    },
    scriptResultOpen: {
        id: 'TreeContextMenu.scriptResultOpen',
        defaultMessage: 'Результаты скриптов',
    },
    connect: {
        id: 'TreeContextMenu.connect',
        defaultMessage: 'Подключиться',
    },
    changeProfile: {
        id: 'TreeContextMenu.changeProfile',
        defaultMessage: 'Профиль {profileName}',
    },
    refresh: {
        id: 'TreeContextMenu.refresh',
        defaultMessage: 'Обновить',
    },
    collapse: {
        id: 'TreeContextMenu.collapse',
        defaultMessage: 'Свернуть',
    },
    addDB: {
        id: 'TreeContextMenu.addDB',
        defaultMessage: 'Создать базу данных',
    },
    addFolder: {
        id: 'TreeContextMenu.addFolder',
        defaultMessage: 'Создать папку',
    },
    dbSearch: {
        id: 'TreeContextMenu.dbSearch',
        defaultMessage: 'Поиск',
    },
    dbSearchDeletedElements: {
        id: 'TreeContextMenu.dbSearchDeletedElements',
        defaultMessage: 'Поиск удаленных элементов',
    },
    erase: {
        id: 'TreeContextMenu.erase',
        defaultMessage: 'Удалить полностью',
    },
    properties: {
        id: 'TreeContextMenu.properties',
        defaultMessage: 'Свойства',
    },
    addModel: {
        id: 'TreeContextMenu.addModel',
        defaultMessage: 'Создать модель',
    },
    cloneModel: {
        id: 'TreeContextMenu.cloneModel',
        defaultMessage: 'Создать копию модели',
    },
    addWiki: {
        id: 'TreeContextMenu.addWiki',
        defaultMessage: 'Создать wiki-страницу',
    },
    addMatrix: {
        id: 'TreeContextMenu.addMatrix',
        defaultMessage: 'Создать матричную модель',
    },
    addObject: {
        id: 'TreeContextMenu.addObject',
        defaultMessage: 'Создать объект',
    },
    open: {
        id: 'TreeContextMenu.open',
        defaultMessage: 'Открыть',
    },
    edit: {
        id: 'TreeContextMenu.edit',
        defaultMessage: 'Изменить',
    },
    editAttributes: {
        id: 'TreeContextMenu.editAttributes',
        defaultMessage: 'Редактировать атрибуты',
    },
    delete: {
        id: 'TreeContextMenu.delete',
        defaultMessage: 'Удалить',
    },
    export: {
        id: 'TreeContextMenu.export',
        defaultMessage: 'Экспорт',
    },
    ItemExportAsDocx: {
        id: 'TreeContextMenu.ItemExportAsDocx',
        defaultMessage: 'Экспорт в docx',
    },
    exportBpmn: {
        id: 'TreeContextMenu.exportBpmn',
        defaultMessage: 'Экспорт BPMN',
    },
    import: {
        id: 'TreeContextMenu.import',
        defaultMessage: 'Импорт',
    },
    importDb: {
        id: 'TreeContextMenu.importDb',
        defaultMessage: 'Импорт базы данных',
    },
    importVisio: {
        id: 'TreeContextMenu.importVisio',
        defaultMessage: 'Импорт из Visio',
    },
    importAris: {
        id: 'TreeContextMenu.importAris',
        defaultMessage: 'Импорт из ARIS',
    },
    scriptMenuName: {
        id: 'TreeContextMenu.scriptMenuName',
        defaultMessage: 'Скрипт',
    },
    executeScript: {
        id: 'TreeContextMenu.executeScript',
        defaultMessage: 'Запустить',
    },
    scheduleScript: {
        id: 'TreeContextMenu.scheduleScript',
        defaultMessage: 'Запланировать скрипт',
    },
    executeAnalyseTool: {
        id: 'TreeContextMenu.executeAnalyseTool',
        defaultMessage: 'Инструменты анализа',
    },
    addSimulationModeling: {
        id: 'TreeContextMenu.addSimulationModeling',
        defaultMessage: 'Создать имитационную модель',
    },
    addDashboard: {
        id: 'TreeContextMenu.addDashboard',
        defaultMessage: 'Создать дашборд',
    },
    rename: {
        id: 'TreeContextMenu.rename',
        defaultMessage: 'Переименовать',
    },
    managePermissions: {
        id: 'TreeContextMenu.managePermissions',
        defaultMessage: 'Управление доступом',
    },
    create: {
        id: 'TreeContextMenu.create',
        defaultMessage: 'Создать',
    },
    addScript: {
        id: 'TreeContextMenu.addScript',
        defaultMessage: 'Создать скрипт',
    },
    addScriptFolder: {
        id: 'TreeContextMenu.addScriptFolder',
        defaultMessage: 'Создать папку',
    },
    copyLink: {
        id: 'TreeContextMenu.copyLink',
        defaultMessage: 'Копировать ссылку',
    },
    addFile: {
        id: 'TreeContextMenu.addFile',
        defaultMessage: 'Добавить файл',
    },
    replace: {
        id: 'TreeContextMenu.replace',
        defaultMessage: 'Заменить',
    },
    restore: {
        id: 'TreeContextMenu.restore',
        defaultMessage: 'Восстановить',
    },
    addToFavorites: {
        id: 'TreeContextMenu.addToFavorites',
        defaultMessage: 'Добавить в избранное',
    },
    removeFromFavorites: {
        id: 'TreeContextMenu.removeFromFavorites',
        defaultMessage: 'Удалить из избранного',
    },
    downloadFile: {
        id: 'TreeContextMenu.downloadFile',
        defaultMessage: 'Скачать файл',
    },
    addSpreadsheet: {
        id: 'TreeContextMenu.addSpreadsheet',
        defaultMessage: 'Создать таблицу',
    },
    cannotAddFolderType: {
        id: 'TreeContextMenu.cannotAddFolderType',
        defaultMessage: 'Эта папка не может содержать вложенных папок',
    },
    cannotAddSpreadsheet: {
        id: 'TreeContextMenu.cannotAddSpreadsheet',
        defaultMessage: 'Эта папка не может содержать таблицу',
    },
    cannotAddFile: {
        id: 'TreeContextMenu.cannotAddFile',
        defaultMessage: 'Эта папка не может содержать файл',
    },
    cannotAddSimulationModeling: {
        id: 'TreeContextMenu.cannotAddSimulationModeling',
        defaultMessage: 'Эта папка не может содержать имитационное моделирование',
    },
    cannotAddModel: {
        id: 'TreeContextMenu.cannotAddModel',
        defaultMessage: 'Эта папка не может содержать никаких моделей',
    },
    cannotAddObject: {
        id: 'TreeContextMenu.cannotAddObject',
        defaultMessage: 'Эта папка не может содержать никаких объектов',
    },
    cannotRemoveScriptFolder: {
        id: 'TreeContextMenu.cannotRemoveScriptFolder',
        defaultMessage: 'Корневой каталог со скриптами не может быть удален',
    },
    cannotRenameScriptFolder: {
        id: 'TreeContextMenu.cannotRenameScriptFolder',
        defaultMessage: 'Корневой каталог со скриптами не может быть переименован',
    },
    alreadyExporting: {
        id: 'TreeContextMenu.alreadyExporting',
        defaultMessage: 'Дождитесь окончания текущего процесса экспорта',
    },
    alreadyImporting: {
        id: 'TreeContextMenu.alreadyImporting',
        defaultMessage: 'Дождитесь окончания текущего процесса импорта',
    },
    needLicense: {
        id: 'TreeContextMenu.needLicense',
        defaultMessage: 'Для этого действия вам необходима лицензия',
    },
    forbiddenForDeletedElement: {
        id: 'TreeContextMenu.forbiddenForDeletedElement',
        defaultMessage: 'Это действие запрещено для удаленного объекта',
    },
    additionalToolsMenuName: {
        id: 'TreeContextMenu.additionalToolsMenuName',
        defaultMessage: 'Дополнительные инструменты',
    },
    changeEntityType: {
        id: 'TreeContextMenu.changeEntityType',
        defaultMessage: 'Изменить тип элементов',
    },
    findDuplicates: {
        id: 'TreeContextMenu.findDuplicates',
        defaultMessage: 'Консолидация элементов',
    },
    noAccess: {
        id: 'TreeContextMenu.noAccess',
        defaultMessage: 'Действие запрещено профилем пользователя',
    },
    createModel: {
        id: 'TreeContextMenu.createModel',
        defaultMessage: 'Создать модель',
    },
    createObject: {
        id: 'TreeContextMenu.createObject',
        defaultMessage: 'Создать объект',
    },
    historyLog: {
        id: 'TreeContextMenu.historyLog',
        defaultMessage: 'История изменений',
    },
    createFolder: {
        id: 'TreeContextMenu.createFolder',
        defaultMessage: 'Создать папку',
    },
    deletedElements: {
        id: 'TreeContextMenu.deletedElements',
        defaultMessage: 'Удалённые элементы',
    },
    compareModels: {
        id: 'TreeContextMenu.compareModels',
        defaultMessage: 'Сравнить модели',
    },
    scriptDocumentation: {
        id: 'TreeContextMenu.scriptDocumentation',
        defaultMessage: 'Документация',
    },
    scriptDocumentationScripts: {
        id: 'TreeContextMenu.scriptDocumentationScripts',
        defaultMessage: 'Скрипты (docx)',
    },
    scriptDocumentationApiDocx: {
        id: 'TreeContextMenu.scriptDocumentationApiDocx',
        defaultMessage: 'API (docx)',
    },
    scriptDocumentationApiHtml: {
        id: 'TreeContextMenu.scriptDocumentationApiHtml',
        defaultMessage: 'API (html)',
    },
    myApprovals: {
        id: 'TreeContextMenu.myApprovals',
        defaultMessage: 'Мои согласования',
    },
    copyDb: {
        id: 'TreeContextMenu.copyDb',
        defaultMessage: 'Создать копию базы данных',
    },
    copyingDbInfo: {
        id: 'TreeContextMenu.copyingDbInfo',
        defaultMessage: 'Дождитесь окончания текущего процесса копирования базы данных',
    },
    createModelTemplate: {
        id: 'TreeContextMenu.createModelTemplate',
        defaultMessage: 'Создать шаблон модели',
    },
});
