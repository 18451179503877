import React, { useState } from 'react';
import { Radio } from './Radio.component';
import { TRadioOption } from './Radio.types';
import theme from './Radio.scss';

type TRadioGroupProps<T> = {
    options: TRadioOption<T>[];
    value?: T;
    initialValue?: T;
    onChange?: (value: T) => void;
    disabled?: boolean;
    dataTest?: string;
    direction?: "Column" | "Row";
    margin?: number;
};

/**
 * @param {TRadioOption<T>[]} options массив всех опций
 * @param {T | undefined} value текущее выбранное значение (управление снаружи), является ключом
 * @param {T | undefined} initialValue текущее выбранное начальное значение (игнорируется если передан value)
 * @param {((value: T) => void) | undefined} onChange функция, которая будет вызвана при выборе опции
 * @param {boolean | undefined} disabled дизейбл
 * @param {string | undefined} dataTest идентификатор для тестов
 * @param {"Column" | "Row"} direction расположение в строчку или в колонке (по умолчанию в колонке)
 * @param {number | undefined} margin отступ между радиобаттонами
 */
export const RadioGroup = <T,>(props: TRadioGroupProps<T>) => {
    const { options, value: selectedValue, initialValue, onChange, disabled, dataTest, direction = 'Column', margin } = props;
    const [value, setValue] = useState<T | undefined>(initialValue);

    const handleChange = (selectedOption: TRadioOption<T>) => {
        setValue(selectedOption.value);

        if (onChange) {
            onChange(selectedOption.value);
        }
    };

    const marginTop: string | undefined = direction === 'Column' ? `${margin}px` : undefined;
    const marginLeft: string | undefined = direction !== 'Column' ? `${margin}px` : undefined;

    return (
        <div className={direction === 'Column' ? theme.radioGroupColumn : theme.radioGroupRow} data-test={dataTest}>
            {options.map((option: TRadioOption<T>, index: number) => (
                <div style={margin && !!index ? { marginTop, marginLeft } : undefined} key={`${option.value}`}>
                    <Radio
                        checked={selectedValue ? selectedValue === option.value : value === option.value}
                        disabled={disabled || option.disabled}
                        onChange={() => handleChange(option)}
                        title={option.title}
                        subtitle={option.subtitle}
                        dataTest={option.dataTest}
                    />
                </div>
            ))}
        </div>
    );
};
