import { defineMessages } from 'react-intl';

export default defineMessages({
    appDb: {
        id: 'SystemProperties.appDb',
        defaultMessage: 'База Данных',
    },
    appDbHost: {
        id: 'SystemProperties.appDbHost',
        defaultMessage: 'Адрес сервера БД',
    },
    appDbName: {
        id: 'SystemProperties.appDbName',
        defaultMessage: 'Имя БД',
    },
    blockAuthTime: {
        id: 'SystemProperties.blockAuthTime',
        defaultMessage: 'Время блокировки после исчерпания попыток, секунды',
    },
    maxFailAuthAttempts: {
        id: 'SystemProperties.maxFailAuthAttempts',
        defaultMessage: 'Максимальное количество неудачных попыток входа',
    },
    appDbPort: {
        id: 'SystemProperties.appDbPort',
        defaultMessage: 'Порт БД',
    },
    appDbUser: {
        id: 'SystemProperties.appDbUser',
        defaultMessage: 'Имя пользователя БД',
    },
    appDbUrl: {
        id: 'SystemProperties.appDbUrl',
        defaultMessage: 'URL БД',
    },
    scriptEngine: {
        id: 'SystemProperties.scriptEngine',
        defaultMessage: 'Скрипты',
    },
    scriptEngineUrl: {
        id: 'SystemProperties.scriptEngineUrl',
        defaultMessage: 'Адрес сервиса запуска скриптов',
    },
    checkServer: {
        id: 'SystemProperties.checkServer',
        defaultMessage: 'Проверить сервер',
    },
    check: {
        id: 'SystemProperties.check',
        defaultMessage: 'Проверка',
    },
    works: {
        id: 'SystemProperties.works',
        defaultMessage: 'Работает',
    },
    notFoundUI: {
        id: 'SystemProperties.notFoundUI',
        defaultMessage: 'Не найден UI',
    },
    addressNotFound: {
        id: 'SystemProperties.addressNotFound',
        defaultMessage: 'Не найден обратный адрес',
    },
    notWork: {
        id: 'SystemProperties.notWork',
        defaultMessage: 'Не работает',
    },
    server: {
        id: 'SystemProperties.server',
        defaultMessage: 'Сервер',
    },
    serverCallbackUrl: {
        id: 'SystemProperties.serverCallbackUrl',
        defaultMessage: 'URL для запроса данных',
    },
    serverUrl: {
        id: 'SystemProperties.serverUrl',
        defaultMessage: 'URL сервера',
    },
    serverUrlPlaceholder: {
        id: 'SystemProperties.serverUrlPlaceholder',
        defaultMessage: 'пример: http://www.silaunion.ru',
    },
    fileTempStorage: {
        id: 'SystemProperties.fileTempStorage',
        defaultMessage: 'Временное хранилище файлов',
    },
    fileTempStoragePath: {
        id: 'SystemProperties.fileTempStoragePath',
        defaultMessage: 'Расположение временного хранилища файлов',
    },
    serverName: {
        id: 'SystemProperties.serverName',
        defaultMessage: 'Имя сервера',
    },
    serverIp: {
        id: 'SystemProperties.serverIp',
        defaultMessage: 'IP сервера',
    },
    serverPort: {
        id: 'SystemProperties.serverPort',
        defaultMessage: 'Порт сервера',
    },
    serverTime: {
        id: 'SystemProperties.serverTime',
        defaultMessage: 'Время сервера',
    },
    image: {
        id: 'SystemProperties.image',
        defaultMessage: 'Изображения',
    },
    imageSilaUrl: {
        id: 'SystemProperties.imageSilaUrl',
        defaultMessage: 'Адрес UI',
    },
    imageUrl: {
        id: 'SystemProperties.imageUrl',
        defaultMessage: 'Адрес сервиса изображений',
    },
    ldap: {
        id: 'SystemProperties.ldap',
        defaultMessage: 'LDAP',
    },
    ldapServerId: {
        id: 'SystemProperties.ldapServerId',
        defaultMessage: 'ID сервера',
    },
    ldapBaseDn: {
        id: 'SystemProperties.ldapBaseDn',
        defaultMessage: 'Корневой DN',
    },
    ldapBindDn: {
        id: 'SystemProperties.ldapBindDn',
        defaultMessage: 'DN системного пользователя',
    },
    ldapBindPasswd: {
        id: 'SystemProperties.ldapBindPasswd',
        defaultMessage: 'Пароль системного пользователя',
    },
    ldapBindPasswdNone: {
        id: 'SystemProperties.ldapBindPasswdNone',
        defaultMessage: 'Не сохранен',
    },
    ldapBindPasswdSave: {
        id: 'SystemProperties.ldapBindPasswdSave',
        defaultMessage: 'Сохранен',
    },
    ldapBindPasswdSafe: {
        id: 'SystemProperties.ldapBindPasswdSafe',
        defaultMessage: 'Сохранен в хранилище',
    },
    receiveGroupFromToken: {
        id: 'SystemProperties.receiveGroupFromToken',
        defaultMessage: 'Загружать группы',
    },
    mailPasswdNone: {
        id: 'SystemProperties.mailPasswdNone',
        defaultMessage: 'Не сохранен',
    },
    mailPasswdSave: {
        id: 'SystemProperties.mailPasswdSave',
        defaultMessage: 'Сохранен',
    },
    mailPasswdSafe: {
        id: 'SystemProperties.mailPasswdSafe',
        defaultMessage: 'Сохранен в хранилище',
    },
    ldapDefaultLicenses: {
        id: 'SystemProperties.ldapDefaultLicenses',
        defaultMessage: 'Лицензии по-умолчанию',
    },
    ldapIgnoreFailCert: {
        id: 'SystemProperties.ldapIgnoreFailCert',
        defaultMessage: 'Игнорировать невалидный сертификат',
    },
    ldapIgnoreServer: {
        id: 'SystemProperties.ldapIgnoreServer',
        defaultMessage: 'Игнорировать сервер',
    },
    ldapSearchFilter: {
        id: 'SystemProperties.ldapSearchFilter',
        defaultMessage: 'Фильтр поиска',
    },
    ldapServerUrl: {
        id: 'SystemProperties.ldapServerUrl',
        defaultMessage: 'Адрес сервера',
    },
    ldapServerUrlAlt: {
        id: 'SystemProperties.ldapServerUrlAlt',
        defaultMessage: 'Альтернативный адрес сервера',
    },
    isEnLocaleAllowed: {
        id: 'SystemProperties.isEnLocaleAllowed',
        defaultMessage: 'Разрешить вход с использованием английской локали',
    },
    ldapNetBiosName: {
        id: 'SystemProperties.ldapNetBiosName',
        defaultMessage: 'NetBios имя',
    },
    ldapDomainName: {
        id: 'SystemProperties.ldapDomainName',
        defaultMessage: 'Домен',
    },
    ldapUsersGroup: {
        id: 'SystemProperties.ldapUsersGroup',
        defaultMessage: 'Группа пользователей',
    },
    ldapDefaultDomain: {
        id: 'SystemProperties.ldapDefaultDomain',
        defaultMessage: 'Домен по умолчанию',
    },
    ldapServer: {
        id: 'SystemProperties.ldapServer',
        defaultMessage: 'Сервер',
    },
    ldapRemoveServer: {
        id: 'SystemProperties.ldapRemoveServer',
        defaultMessage: 'Удалить сервер',
    },
    deleteButton: {
        id: 'SystemProperties.deleteButton',
        defaultMessage: 'Удалить',
    },
    ldapAddServer: {
        id: 'SystemProperties.ldapAddServer',
        defaultMessage: 'Добавить сервер',
    },
    ldapAddAnotherServer: {
        id: 'SystemProperties.ldapAddAnotherServer',
        defaultMessage: 'Добавить еще один сервер',
    },
    ldapCheckServer: {
        id: 'SystemProperties.ldapCheckServer',
        defaultMessage: 'Проверить сервер',
    },
    ldapServerChecking: {
        id: 'SystemProperties.ldapServerChecking',
        defaultMessage: 'Проверка',
    },
    ldapServerStatusOk: {
        id: 'SystemProperties.ldapServerStatusOk',
        defaultMessage: 'Работает',
    },
    ldapServerStatusNotOk: {
        id: 'SystemProperties.ldapServerStatusNotOk',
        defaultMessage: 'Не работает',
    },
    ldapServerCheckError: {
        id: 'SystemProperties.ldapServerCheckError',
        defaultMessage: 'Ошибка при запросе',
    },
    kerberos: {
        id: 'SystemProperties.kerberos',
        defaultMessage: 'Kerberos',
    },
    kerberosDebugStatus: {
        id: 'SystemProperties.kerberosDebugStatus',
        defaultMessage: 'Сохранять логи Kerberos',
    },
    kerberosKrb5Path: {
        id: 'SystemProperties.kerberosKrb5Path',
        defaultMessage: 'Путь файла krb5.conf/krb5.ini',
    },
    model: {
        id: 'SystemProperties.model',
        defaultMessage: 'Модель',
    },
    modelAutoSaveTimeout: {
        id: 'SystemProperties.modelAutoSaveTimeout',
        defaultMessage: 'Частота автосохранения модели, сек.',
    },
    modelVersionSaveTimeout: {
        id: 'SystemProperties.modelVersionSaveTimeout',
        defaultMessage: 'Частота сохранения версии модели, сек.',
    },
    userSession: {
        id: 'SystemProperties.userSession',
        defaultMessage: 'Пользовательские сессии',
    },
    userSessionLengthMax: {
        id: 'SystemProperties.userSessionLengthMax',
        defaultMessage: 'Максимальная длина сессии пользователя, сек.',
    },
    userSessionInactivity: {
        id: 'SystemProperties.userSessionInactivity',
        defaultMessage: 'Время бездействия пользователя, сек.',
    },
    save: {
        id: 'SystemProperties.save',
        defaultMessage: 'Сохранить',
    },
    cancel: {
        id: 'SystemProperties.cancel',
        defaultMessage: 'Отмена',
    },
    missingRequiredParameter: {
        id: 'SystemProperties.missingRequiredParameter',
        defaultMessage: 'Введите значение параметра',
    },
    mailSetting: {
        id: 'SystemProperties.mailSetting',
        defaultMessage: 'Настройки почты',
    },
    mailHost: {
        id: 'SystemProperties.mailHost ',
        defaultMessage: 'Адрес сервера',
    },
    mailPort: {
        id: 'SystemProperties.mailPort ',
        defaultMessage: 'Порт сервера',
    },
    mailUser: {
        id: 'SystemProperties.mailUser',
        defaultMessage: 'Пользователь почтового сервера',
    },
    mailFrom: {
        id: 'SystemProperties.mailFrom',
        defaultMessage: 'Адрес, с которого отправляются письма ',
    },
    mailPassword: {
        id: 'SystemProperties.mailPassword',
        defaultMessage: 'Пароль пользователя',
    },
    mailProtocol: {
        id: 'SystemProperties.mailProtocol',
        defaultMessage: 'Протокол почты',
    },
    mailSsl: {
        id: 'SystemProperties.mailSsl',
        defaultMessage: 'Использование SSL соединения',
    },
    mailSslProtocols: {
        id: 'SystemProperties.mailSslProtocols',
        defaultMessage: 'SSL протоколы',
    },
    mailCheckServer: {
        id: 'SystemProperties.mailCheckServer',
        defaultMessage: 'Проверить сервер',
    },
    interfaceSetting: {
        id: 'SystemProperties.interfaceSetting',
        defaultMessage: 'Настройки интерфейса',
    },
    colorSchemeSetting: {
        id: 'SystemProperties.ColorSchemeSetting',
        defaultMessage: 'Настройки цветовой темы',
    },
    homePageSetting: {
        id: 'SystemProperties.homePageSetting',
        defaultMessage: 'Настройки главной страницы',
    },
    isOpenNavigator: {
        id: 'SystemProperties.isOpenNavigator',
        defaultMessage: 'Открывать навигатор',
    },
    isCompactMainMenu: {
        id: 'SystemProperties.isCompactMainMenu',
        defaultMessage: 'Компактное меню',
    },
    isOpenMainMenu: {
        id: 'SystemProperties.isOpenMainMenu',
        defaultMessage: 'Открывать меню',
    },
    mainColor: {
        id: 'SystemProperties.mainColor',
        defaultMessage: 'Основной цвет приложения',
    },
    secondColor: {
        id: 'SystemProperties.secondColor',
        defaultMessage: 'Дополнительный цвет приложения',
    },
    selectedRowColor: {
        id: 'SystemProperties.selectedRowColor',
        defaultMessage: 'Цвет выделения',
    },
    extraButtonLabel: {
        id: 'SystemProperties.extraButtonLabel',
        defaultMessage: 'Пользовательская ссылка',
    },
    extraButtonUrl: {
        id: 'SystemProperties.extraButtonUrl',
        defaultMessage: 'Внешняя ссылка',
    },
    extraButtonImageId: {
        id: 'SystemProperties.extraButtonImageId',
        defaultMessage: 'ID картинки',
    },
    extraButtonSelect: {
        id: 'SystemProperties.extraButtonSelect',
        defaultMessage: 'Выбрать',
    },
    extraButtonText: {
        id: 'SystemProperties.extraButtonText',
        defaultMessage: 'Текст кнопки',
    },
    extraButtonTooltip: {
        id: 'SystemProperties.extraButtonTooltip',
        defaultMessage: 'Подсказка',
    },
    additionalSetting: {
        id: 'SystemProperties.additionalSetting',
        defaultMessage: 'Дополнительные функции',
    },
    security: {
        id: 'SystemProperties.security',
        defaultMessage: 'Безопасность',
    },
    macrosEnable: {
        id: 'SystemProperties.macrosEnable',
        defaultMessage: 'Включить макросы',
    },
    resetToDefaultColors: {
        id: 'SystemProperties.resetToDefaultColors',
        defaultMessage: 'Сброс цвета',
    },
    isActiveHidingUsers: {
        id: 'SystemProperties.isActiveHidingUsers',
        defaultMessage: 'Ограничить видимость пользователей своими группами',
    },
    isActiveAuthorizationSettings: {
        id: 'SystemProperties.isActiveAuthorizationSettings',
        defaultMessage: 'Включить настройку авторизации',
    },
    activeAuthorizationMethods: {
        id: 'SystemProperties.activeAuthorizationMethods',
        defaultMessage: 'Методы авторизации',
    },
    description: {
        id: 'AcceptAgreement.description',
        defaultMessage: 'Текст соглашения',
    },
    silaAgreementLabel: {
        id: 'AcceptAgreement.silaAgreementLabel',
        defaultMessage: 'Включить проверку лиц. соглашения SILA Union',
    },
    agreementLabel: {
        id: 'AcceptAgreement.agreementLabel',
        defaultMessage: 'Включить проверку лиц. соглашения',
    },
    agreementRequired: {
        id: 'AcceptAgreement.agreementRequired',
        defaultMessage: 'Обязательные поля лицензионных соглашений должны быть заполнены',
    },
    licenseAgreement: {
        id: 'AcceptAgreement.licenseAgreement',
        defaultMessage: 'Лицензионные соглашения',
    },
    reset: {
        id: 'ResetAgreement.reset',
        defaultMessage: 'Сбросить',
    },
    resetAgreement: {
        id: 'ResetAgreement.resetAgreement',
        defaultMessage: 'Сброс согласия',
    },
    resetSilaAgreementBtn: {
        id: 'ResetAgreement.resetSilaAgreementBtn',
        defaultMessage: 'Сбросить согласие с лиц. соглашением SILA Union',
    },
    resetAgreementBtn: {
        id: 'ResetAgreement.resetAgreementBtn',
        defaultMessage: 'Сбросить согласие с лиц. соглашением',
    },
    resetSilaAgreementConfirm: {
        id: 'ResetAgreement.resetSilaAgreementConfirm',
        defaultMessage: 'Вы уверены, что хотите сбросить соглашение SILA Union?',
    },
    resetAgreementConfirm: {
        id: 'ResetAgreement.resetAgreementConfirm',
        defaultMessage: 'Вы уверены, что хотите сбросить соглашение?',
    },
    resetAgreementWarningConfirm: {
        id: 'ResetAgreement.resetAgreementWarningConfirm',
        defaultMessage: 'Согласие будет сброшено для всех пользователей сервера!',
    },
    minPasswordLength: {
        id: 'SystemProperties.minPasswordLength',
        defaultMessage: 'Минимальная длина пароля',
    },
    minPasswordLengthValidation: {
        id: 'SystemProperties.minPasswordLengthValidation',
        defaultMessage: 'Минимальная длина пароля должна быть не менее 6 символов',
    },
    isActiveUpperAndLowerCase: {
        id: 'SystemProperties.isActiveUpperAndLowerCase',
        defaultMessage: 'Обязательность букв верхнего и нижнего регистра в пароле',
    },
    isActiveNumbers: {
        id: 'SystemProperties.isActiveNumbers',
        defaultMessage: 'Обязательность цифр в пароле',
    },
    isActiveSpecialCharacters: {
        id: 'SystemProperties.isActiveSpecialCharacters',
        defaultMessage: 'Обязательность спецсимволов в пароле',
    },
    passwordMinDurationTime: {
        id: 'SystemProperties.passwordMinDurationTime',
        defaultMessage: 'Минимальный срок действия пароля, дней (0 - настройка неактивна)',
    },
    passwordMaxDurationTime: {
        id: 'SystemProperties.passwordMaxDurationTime',
        defaultMessage: 'Максимальный срок действия пароля, дней (0 - настройка неактивна)',
    },
    passwordDurationTimeValidation: {
        id: 'SystemProperties.passwordDurationTimeValidation',
        defaultMessage: 'Максимальный срок действия пароля не может быть меньше минимального срока действия пароля',
    },
    countOfLastUsedPasswords: {
        id: 'SystemProperties.countOfLastUsedPasswords',
        defaultMessage: 'Количество последних паролей, запрещенных для использования',
    },
    notValidFields: {
        id: 'SystemProperties.notValidFields',
        defaultMessage: 'Некорректные значения полей',
    },
    rangeValue: {
        id: 'SystemProperties.rangeValue',
        defaultMessage: 'Введите значение от {min} до {max}',
    },
    userIdleTimeIsTooLong: {
        id: 'SystemProperties.userIdleTimeIsTooLong',
        defaultMessage: 'Время бездействия пользователя не должно быть больше максимальной длины сессии пользователя',
    },
    messageStorageDays: {
        id: 'SystemProperties.messageStorageDays',
        defaultMessage: 'Время хранения сообщений (дней)',
    },
    keycloak: {
        id: 'SystemProperties.keycloak',
        defaultMessage: 'Keycloak',
    },
    ALWAYS: {
        id: 'SystemProperties.ALWAYS',
        defaultMessage: 'Всегда',
    },
    CREATE: {
        id: 'SystemProperties.CREATE',
        defaultMessage: 'При первом входе пользователя',
    },
    LOGIN: {
        id: 'SystemProperties.LOGIN',
        defaultMessage: 'Только при входе существующего пользователя',
    },
    NEVER: {
        id: 'SystemProperties.NEVER',
        defaultMessage: 'Никогда',
    },
    userInfo: {
        id: 'SystemProperties.userInfo',
        defaultMessage: 'Обновлять информацию о пользователе',
    },
    greetingLogo: {
        id: 'SystemProperties.greetingLogo',
        defaultMessage: 'Дополнительный логотип',
    },
    uploaLogodDescriptionType: {
        id: 'SystemProperties.uploaLogodDescriptionType',
        defaultMessage: 'Тип файла: SVG',
    },
    uploadLogo: {
        id: 'SystemProperties.uploadLogo',
        defaultMessage: 'Загрузить логотип',
    },
    fileMaxSize: {
        id: 'SystemProperties.fileMaxSize',
        defaultMessage: 'Размер файла не должен превышать 500 КБ',
    },
    passwordChangePermission: {
        id: 'SystemProperties.passwordChangePermission',
        defaultMessage: 'Самостоятельная смена пароля пользователем',
    },
    buttonHover: {
        id: 'SystemProperties.buttonHover',
        defaultMessage: 'При наведении указателя мышки',
    },
    buttons: {
        id: 'SystemProperties.buttons',
        defaultMessage: 'Кнопки',
    },
    buttonBackgroundColor: {
        id: 'SystemProperties.buttonBackground',
        defaultMessage: 'Заливка',
    },
    buttonTextColor: {
        id: 'SystemProperties.buttonTextColor',
        defaultMessage: 'Текст',
    },
    buttonBorderColor: {
        id: 'SystemProperties.buttonBorderColor',
        defaultMessage: 'Граница',
    },
    colorThemeObject: {
        id: 'SystemProperties.colorThemeObject',
        defaultMessage: 'Название',
    },
    primary: {
        id: 'SystemProperties.primary',
        defaultMessage: 'Основная',
    },
    primaryDanger: {
        id: 'SystemProperties.primaryDanger',
        defaultMessage: 'Основная предупреждающая',
    },
    secondary: {
        id: 'SystemProperties.secondary',
        defaultMessage: 'Дополнительная',
    },
    secondaryDanger: {
        id: 'SystemProperties.secondaryDanger',
        defaultMessage: 'Дополнительная предупреждающая',
    },
    buttonText: {
        id: 'SystemProperties.buttonText',
        defaultMessage: 'Текст',
    },
    buttonTextDanger: {
        id: 'SystemProperties.buttonTextDanger',
        defaultMessage: 'Текст предупреждающий',
    },
    general: {
        id: 'SystemProperties.general',
        defaultMessage: 'Общие',
    },
});
