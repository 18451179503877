import type { IScriptNode, ISpreadsheetNode, IWikiNode } from '../models/bpm/bpm-model-impl.types';
import type { TImportDialogState } from '../reducers/importDialog.reducer.types';
import type { Locale } from '../modules/Header/components/Header/header.types';
import type { TExecutingProcess } from '../reducers/statusBar.reducer.types';
import type { ObjectDefinitionImpl } from '../models/bpm/bpm-model-impl';
import type { TNotificationEntity } from '../models/notification.types';
import type { TreeNode, TTreeEntityState } from '../models/tree.types';
import type { TServerEntity } from '../models/entities.types';
import type { IModelContext } from './utils.types';
import {
    RootNodeId,
    AllowedScriptContext,
    BpmnExportResponse,
    BpmnExportResponseStatusEnum,
    EdgeDefinitionNode,
    ImportRequest,
    ImportResponse,
    MatrixNode,
    ModelNode,
    Node,
    NodeAttributes,
    NodeId,
    NodeLockRequestLockTypeEnum,
    NodeTypeEnum,
    ObjectDefinitionNode,
    RepositoryNode,
    ScriptNodeAttributes,
    StubResponse,
    Symbol,
    UserDTO,
} from '../serverapi/api';
import type {
    TTreeItemExpandAction,
    TNodePropertiesUpdateAction,
    TTreeItemTitleChangeRequestAction,
    TTreePartFetchAction,
    TTreeItemContextMenuAction,
    TTreeItemSecondaryAction,
    TTreeItemSelectAction,
    TTreeItemStartDragAction,
    TTreeImportNodeAction,
    TTreeItemPropertiesChangeRequest,
    TTreeExportNodeAction,
    TOpenChooseTreeNodeAction,
    TTreeItemRefreshAction,
    TTreeItemUmlClassObjectSaveAction,
    TTreePartFetchAllScriptsAction,
    TSetShowDeletedObjectsFilterRequestAction,
    TTreeNodeSubscribeAction,
    TTreeNodeUnsubscribeAction,
} from '../actions/tree.actions.types';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import { isUndefined } from 'is-what';
import { v4 as uuid } from 'uuid';
import { loadAdminToolByTreeNode } from '../actions/admintools.actions';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { changeMatrixNodeProperties, matrixOpenByNodeId } from '../modules/Matrix/actions/matrix.actions';
import {
    objectDefinitionsAdd,
    objectDefinitionUmlUpdate,
    saveObjectDefinition,
} from '../actions/entities/objectDefinition.actions';
import { scriptOpenByNodeId, changeScriptData, scriptsDashboardOpen } from '../actions/entities/script.actions';
import { openAuthServerDialog } from '../actions/entities/servers.actions';
import { changeWikiData, wikiOpenById } from '../actions/entities/wiki.actions';
import { importDialogInit, importDialogSetState, importRequestDone } from '../actions/importDialog.actions';
import { loadModelById } from '../actions/loadModel.actions';
import { changeModelData } from '../actions/model.actions';
import { PRESET_META_DATA_REQUEST_SUCCESS } from '../actionsTypes/notation.actionTypes';
import {
    presetMetaDataRequest,
    presetMetaDataRequestFail,
    presetMetaDataRequestSuccess,
} from '../actions/notation.actions';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import { recentAddModel } from '../actions/recent.actions';
import { scriptSelectDialogInit } from '../actions/scriptSelectDialog.actions';
import { openSimulationModeling } from '../actions/simulationModeling.actions';
import { setProcessIndicator } from '../actions/statusBar.actions';
import { workspaceChangeTabTitle } from '../actions/tabs.actions';
import {
    TREE_IMPORT_NODE,
    TREE_ITEM_CLICK_DRAG_ICON,
    TREE_ITEM_CONTEXT_MENU_ACTION,
    TREE_ITEM_EXPAND,
    TREE_ITEM_SECONDARY_ACTION,
    TREE_ITEM_START_DRAG,
    TREE_ITEM_TITLE_CHANGE_REQUEST,
    TREE_PART_FETCH_REQUEST,
    NODE_PROPERTIES_UPDATE,
    TREE_ITEM_PROPERTIES_CHANGE_REQUEST,
    TREE_EXPORT_NODE,
    OPEN_CHOOSE_TREE_NODE,
    TREE_ITEM_REFRESH,
    TREE_ITEM_UML_CLASS_OBJECT_SAVE,
    TREE_PART_FETCH_ALL_SCRIPTS_REQUEST,
    SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST,
    TREE_NODE_SUBSCRIBE,
    TREE_NODE_UNSUBSCRIBE,
    TREE_COLLAPSE,
} from '../actionsTypes/tree.actionTypes';
import {
    treeItemCollapseSuccess,
    treeItemExpandSuccess,
    treeItemFetchChildSuccess,
    treeItemRenameRequest,
    treeItemUpdate,
    treePartFetchFailure,
    treePartFetchRequest,
    treePartFetchSuccess,
    nodePropertiesUpdate,
    treeExportNode,
    treeItemAdd,
    treeItemDeleteNodeFromServerRequest,
    treeItemEraseRequest,
    treeItemDeleteServer,
    treeItemSelect,
    treeItemDelete,
    treeItemExpanding,
    treeItemContextMenuAction,
    treeItemOpenPropertyAction,
    treeItemUmlObjectsUpdate,
    setShowDeletedObjectsFilterSuccess,
    treeNodeAddSubscriptionToStore,
    treeNodeDeleteSubscribtionFromStore,
    treeItemExpand,
    applyTreeFiltersByParentTypes,
    treeItemScroll,
    deleteSeveralNodesFromServerRequest,
} from '../actions/tree.actions';
import { NotificationType } from '../models/notificationType';
import { getAdminToolTreeItems } from '../modules/AdminTools/data/admintool';
import { AdminToolTreeType } from '../modules/AdminTools/data/admintool.types';
import AppNotificationsMessages from '../modules/App/messages/AppNotifications.messages';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import messages from '../modules/ImportDialog/messages/ImportDialog.messages';
import ProcessIndicatorMessages from '../modules/StatusBar/components/ProcessIndicator/ProcessIndicator.messages';
import { parseTreeItemType, TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import { ProcessType } from '../reducers/statusBar.reducer.types';
import { getUser } from '../selectors/authorization.selectors';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { ImportDialogSelectors } from '../selectors/importDialog.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { getRecentModel } from '../selectors/recent.selector';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { getRepositories, getTreeItems, TreeSelectors } from '../selectors/tree.selectors';
import { ImportErrorType } from '../services/api/custom/TransferApi';
import { LocalesService } from '../services/LocalesService';
import { getStore } from '../store';
import { setServerIdToNodeInterface } from '../utils/nodeId.utils';
import { getActiveModelContext, getAllParentNodeIds } from './utils';
import { TMP_DOWNLOAD_PATH } from '../services/api/api-bundle';
import SaveDialogOptions = Electron.SaveDialogOptions;
import getActiveTab = TabsSelectors.getActiveTab;
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { nodeService } from '../services/NodeService';
import { RepositoryDAOService } from '../services/dao/RepositoryDAOService';
import { TreeBLLService } from '../services/TreeBLLService';
import { fileDownload } from '../actions/file.actions';
import { spreadsheetOpenById, spreadsheetUpdateAfterEditInPropertyTab } from '../actions/entities/spreadsheet.actions';
import { FileDaoService } from '../services/dao/FileDaoService';
import { copyLinkAction } from '../actions/copyLinkDialog.actions';
import { ServerErrorType } from '../models/serverErrorType';
import { navigateToFolderNode } from '../actions/folderDialog.actions';
import { NAVIGATOR_STRUCTURE } from '../utils/consts';
import { ExpandStatus } from '../reducers/tree.reducer.types';
import { kanbanOpenById, kanbanSetNode } from '../actions/entities/kanban.actions';
import { setScriptsNodesAction } from '../actions/fetchScripts.actions';
import { BrowserDaoService } from '../services/dao/BrowserDaoService';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import { edgeDefinitionsAdd, edgeDefinitionsUpdate } from '../actions/entities/edgeDefinition.actions';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { updateRepository } from '../actions/databaseDialog.actions';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { getActiveGraph } from '../selectors/editor.selectors';
import { ScriptSelectors } from '../selectors/script.selectors';
import { TreeSubscriptionService } from '../services/ws/notification/TreeSubscriptionService';
import { initHistoryDialogAction } from '../actions/historyDialog.actions';
import { loadDashboardById } from '@/actions/dashboard.actions';
import { getNodeName } from '../modules/Navigator/navigatorUtils/navigatorTreeSearch.utils';
import { favoritesAdd, favoritesRemove } from '@/actions/favorites.actions';
import { openCompareModelsTab } from '@/actions/compareModels.actions';
import { updateAllCellsOverlays } from '@/actions/overlay.actions';
import { MxCell } from 'MxGraph';
import { ObjectTypesForLink, TObjectTypesForLink } from '@/services/bll/ExternalLinkBLLService.types';
import { IWorkspaceTabItemModelParams, TWorkspaceTab } from '@/models/tab.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { ExpandedStatusSelector } from '@/selectors/expandedStatus.selectors ';
import { TREE_IMPORT_EXTENSIONS } from '../modules/FileUpload/types/FileUploadDialog.constants';
import { TFileUploadImportDialogOwnProps } from '../modules/FileUpload/types/FileUploadDialog.types';
import { NodeLockDAOService } from '../services/dao/NodeLockDAOService';
import { TEditorMoveToDirectAction } from '../actions/editor.actions.types';
import { EDITOR_MOVE_TO_DIRECT } from '../actionsTypes/editor.actionTypes';
import { isEmpty } from 'lodash-es';
import { reportOpenByNodeId } from '@/modules/Report/actions/report.actions';

function* treePartFetch({ payload: { nodeId, serverId, user, treeName = NAVIGATOR_STRUCTURE } }: TTreePartFetchAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    let nodes: Node[];

    try {
        if (isUndefined(nodeId)) {
            const crntServerUser: UserDTO | undefined = user || (yield select(getUser));
            const locale = yield select(getCurrentLocale);
            const start: TreeNode[] = getAdminToolTreeItems(serverId, locale, crntServerUser);

            yield put(
                treeItemFetchChildSuccess({
                    parentNodeId: {
                        id: serverId,
                        repositoryId: serverId,
                        serverId,
                    },
                    child: [...start],
                }),
            );

            nodes = yield call(() => server.api.tree.rootNodes());
        } else {
            nodes = yield call(() => TreeDaoService.getNodesByParentNode(nodeId));

            if (!nodes.length) {
                const node: Node | undefined = yield TreeDaoService.getNode(serverId, nodeId);

                yield put(
                    treeItemUpdate({
                        nodeId,
                        data: {
                            childrenIds: [],
                            hasChildren: false,
                            countChildren: 0,
                            deleted: node?.deleted,
                            deletedAt: node?.deletedAt,
                            deletedBy: node?.deletedBy,
                        },
                    }),
                );
            }
        }

        nodes.forEach((n) => setServerIdToNodeInterface(n, serverId));

        yield put(treePartFetchSuccess(nodeId, nodes, serverId));

        const node: TTreeEntityState = yield select(TreeSelectors.itemById(nodeId));
        if (nodeId && (node?.hasChildren || !node)) {
            yield put(treeItemExpandSuccess(nodeId, treeName));
        }
    } catch (e) {
        yield put(treePartFetchFailure());

        if (nodeId) yield put(treeItemCollapseSuccess([nodeId], treeName));

        throw e;
    }
}

export function* treeExpandHandler({ payload: { treeName, nodeId } }: TTreeItemExpandAction) {
    const { id, serverId, repositoryId } = nodeId;

    // костыль для разворачивания server id
    if (!id || id === serverId || !repositoryId || repositoryId === serverId) {
        yield put(treeItemExpandSuccess(nodeId, treeName));

        return;
    }
    yield put(treeItemExpanding(nodeId, treeName));

    const parentNode: TTreeEntityState = yield select(TreeSelectors.itemById(nodeId));

    try {
        if (parentNode?.type === TreeItemType.Repository) {
            // если репозиторий то грузим нотации
            const { presetId } = parentNode as RepositoryNode;
            yield put(presetMetaDataRequest([presetId]));

            const presetMetaDataRequestResult = yield take([
                presetMetaDataRequestSuccess().type,
                presetMetaDataRequestFail().type,
            ]);

            if (!(presetMetaDataRequestResult.type as string).includes(PRESET_META_DATA_REQUEST_SUCCESS)) {
                yield put(treeItemCollapseSuccess([nodeId], treeName));

                return;
            }
        }

        const isAdminTypeNode = (Object.values(AdminToolTreeType) as string[]).includes(id);

        if (!isAdminTypeNode) {
            yield treePartFetch(treePartFetchRequest(nodeId, serverId, treeName));
        } else {
            yield put(treeItemExpandSuccess(nodeId, treeName));
        }
    } catch (e) {
        yield put(treeItemExpandSuccess(nodeId, treeName));

        throw e;
    }
}

function* contextMenuActionsHandler({ payload }: TTreeItemContextMenuAction) {
    const {
        nodeId,
        nodeId: { id, serverId, repositoryId },
        name,
        type,
        action,
    } = payload;
    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const selectedTreeNodes: TreeNode[] = yield select(SelectedNodesSelector.getNodes());
    const nodesIdsList: NodeId[] = selectedTreeNodes.map((node) => node.nodeId);

    if (action === TreeItemContextMenuAction.DELETE) {
        if (nodesIdsList.length > 1) {
            yield put(deleteSeveralNodesFromServerRequest(nodesIdsList));
        } else {
            yield put(treeItemDeleteNodeFromServerRequest(nodeId));
        }
    }

    if (action === TreeItemContextMenuAction.ERASE) {
        yield put(treeItemEraseRequest(nodeId));
    }

    if (action === TreeItemContextMenuAction.RESTORE) {
        yield put(openDialog(DialogType.RESTORE_DELETED_NODE_DIALOG, { nodeId }));
    }

    if (action === TreeItemContextMenuAction.ADD_TO_FAVORITES) {
        yield put(favoritesAdd(nodeId));
    }

    if (action === TreeItemContextMenuAction.REMOVE_FROM_FAVORITES) {
        yield put(favoritesRemove(nodeId));
    }

    if (type === TreeItemType.Server) {
        if (action === TreeItemContextMenuAction.CONNECT) {
            yield put(openAuthServerDialog(server.id));
        }
        if (action === TreeItemContextMenuAction.REFRESH) {
            yield call(refreshServerNode, serverId);
        }
        if (action === TreeItemContextMenuAction.SERVER_DELETE) {
            yield put(treeItemDeleteServer(serverId));
        }
    }

    if (action === TreeItemContextMenuAction.OPEN || action === TreeItemContextMenuAction.EDIT) {
        yield openNodeByTreeItemNodeId(nodeId, type);
    }

    if (action === TreeItemContextMenuAction.RENAME) {
        yield put(treeItemRenameRequest(nodeId));
    }
    if (action === TreeItemContextMenuAction.SUBSCRIBE) {
        yield TreeSubscriptionService.subscribeToDatabaseUpdates(repositoryId, serverId);
    } else if (action === TreeItemContextMenuAction.UNSUBSCRIBE) {
        yield TreeSubscriptionService.unsubscribeFromDatabaseUpdates(repositoryId);
    }

    if (action === TreeItemContextMenuAction.EXECUTE_SCRIPT) {
        yield put(
            scriptSelectDialogInit({
                serverId,
                nodeId,
                nodesIdsList,
                modelId: type === TreeItemType.Model ? nodeId : undefined,
                rootType: TreeItemType.ScriptFolder,
                scriptType: TreeItemType.Script,
            }),
        );
    }

    if (action === TreeItemContextMenuAction.SCHEDULE_SCRIPT) {
        yield put(
            openDialog(DialogType.SCHEDULE_SCRIPT_DIALOG, {
                serverId,
                nodeId,
                nodesIdsList,
                modelId: type === TreeItemType.Model ? nodeId : undefined,
            }),
        );
    }

    if (action === TreeItemContextMenuAction.IMPORT) {
        const propsData: TFileUploadImportDialogOwnProps = {
            parentNodeId: nodeId,
            type,
            filters: TREE_IMPORT_EXTENSIONS,
        };
        yield put(openDialog(DialogType.IMPORT_UPLOAD_DIALOG, propsData));
    }

    if (
        type === TreeItemType.Repository ||
        type === TreeItemType.Folder ||
        type === TreeItemType.FileFolder ||
        type === TreeItemType.Model ||
        type === TreeItemType.ObjectDefinition ||
        type === TreeItemType.Wiki ||
        type === TreeItemType.Matrix ||
        type === TreeItemType.File ||
        type === TreeItemType.Script ||
        type === TreeItemType.ScriptFolder ||
        type === TreeItemType.Dashboard ||
        type === TreeItemType.Spreadsheet ||
        type === TreeItemType.SimulationModeling ||
        type === TreeItemType.Report
    ) {
        if (action === TreeItemContextMenuAction.EXPORT) {
            if (type === TreeItemType.Folder || type === TreeItemType.Model || type === TreeItemType.Matrix) {
                yield put(openDialog(DialogType.EXPORT_DIALOG, { nodeId, nodeType: type, name, intl }));
            } else if (type === TreeItemType.Repository) {
                yield put(
                    treeExportNode({
                        nodeId,
                        name,
                        exportRequest: {
                            decompositionsSetting: 'ALL_DECOMPOSITIONS',
                            exportUsersPermissions: true,
                            exportGroupsPermissions: true,
                        },
                    }),
                );
            } else {
                yield put(
                    treeExportNode({ nodeId, name, exportRequest: { decompositionsSetting: 'ALL_DECOMPOSITIONS' } }),
                );
            }
        }

        if (action === TreeItemContextMenuAction.REFRESH) {
            yield call(refreshNode, nodeId);
        }
        
        if (action === TreeItemContextMenuAction.EXPORT_DOCX) {
            const { origin } = window.location;
            const exportUrl = `${origin}/api/wiki/${repositoryId}/${id}/docx`;

            window.open(exportUrl);
        }
    }

    if (type === TreeItemType.Model && action === TreeItemContextMenuAction.EXPORT_BPMN) {
        const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processExport)} "${name}"`;
        const process: TExecutingProcess = { id: uuid(), name: processName, type: ProcessType.EXPORT };
        yield put(setProcessIndicator(true, process));
        try {
            const response: BpmnExportResponse = yield server.api.transferBpmn.doExport({
                repositoryId,
                id,
            });
            if (response.status !== 'SUCCESS') {
                showExportBpmnErrorNotification(response.status, intl);

                return;
            }
            const exportDialogOptions: SaveDialogOptions = {
                title: intl.formatMessage(messages.exportDialogTitle),
                defaultPath: `${name}.ste`,
                filters: [
                    { name: 'Sila Tree Export file', extensions: ['ste'] },
                    { name: 'ZIP file', extensions: ['zip'] },
                    { name: 'XML file', extensions: ['xml'] },
                    { name: 'All files', extensions: ['*'] },
                ],
            };

            BrowserDaoService.downloadFile(
                `${server.url}/${TMP_DOWNLOAD_PATH}/${response.resultFile}`,
                exportDialogOptions,
            );
        } finally {
            yield put(setProcessIndicator(false, process));
        }
    }

    if (action === TreeItemContextMenuAction.COPY_ITEM_LINK) {
        yield put(copyLinkAction(nodeId, type));
    }

    if (action === TreeItemContextMenuAction.HISTORY_LOG) {
        yield put(initHistoryDialogAction(nodeId));
    }

    if (action === TreeItemContextMenuAction.COMPARE_MODELS) {
        const [comparedModelNodeId1, comparedModelNodeId2] = nodesIdsList || [];
        if (comparedModelNodeId1 && comparedModelNodeId2) {
            yield put(openCompareModelsTab({ comparedModelNodeId1, comparedModelNodeId2 }));
        }
    }

    if (action === TreeItemContextMenuAction.COLLAPSE) {
        const childrenNodeIdList: NodeId[] = yield select((state) =>
            TreeSelectors.walkAllTree(nodeId, state, NAVIGATOR_STRUCTURE),
        );
        yield put(treeItemCollapseSuccess([nodeId, ...childrenNodeIdList], NAVIGATOR_STRUCTURE));
    }
}

export function* openNodeByTreeItemNodeId(nodeId: NodeId, type: TObjectTypesForLink, elementIds?: string[]) {
    switch (type) {
        case TreeItemType.Server:
            {
                const server: TServerEntity = yield select(ServerSelectors.server(nodeId.id));
                yield put(
                    openDialog(DialogType.SERVER_EDIT, {
                        entityId: server.id,
                    }),
                );
            }
            break;
        case TreeItemType.Wiki:
            yield put(wikiOpenById(nodeId));
            break;
        case TreeItemType.Spreadsheet:
            yield put(spreadsheetOpenById(nodeId));
            break;
        case TreeItemType.Model:
            yield put(loadModelById(nodeId, elementIds));
            break;
        case TreeItemType.Kanban:
            yield put(kanbanOpenById(nodeId));
            break;
        case TreeItemType.Dashboard:
            yield put(loadDashboardById(nodeId));
            break;
        case TreeItemType.Folder:
            yield put(navigateToFolderNode(nodeId));
            break;
        case TreeItemType.Script:
            yield put(scriptOpenByNodeId(nodeId));
            break;
        case TreeItemType.ObjectDefinition:
            yield put(treeItemOpenPropertyAction(nodeId, type));
            break;
        case TreeItemType.EdgeDefinition:
            yield put(treeItemOpenPropertyAction(nodeId, type));
            break;
        case TreeItemType.ScriptFolder:
            yield put(scriptsDashboardOpen(nodeId));
            break;
        case TreeItemType.Matrix:
            yield put(matrixOpenByNodeId(nodeId));
            break;
        case TreeItemType.Report:
            yield put(reportOpenByNodeId(nodeId));
            break;
        case TreeItemType.SimulationModeling:
            yield put(openSimulationModeling(nodeId));
            break;
        case TreeItemType.File:
            yield put(fileDownload(nodeId));
            break;
        case TreeItemType.Repository:
            yield put(
                treeItemContextMenuAction({
                    nodeId,
                    name: '',
                    type,
                    action: TreeItemContextMenuAction.PROPERTIES,
                }),
            );
            break;
        case TreeItemType.AdminTool:
            {
                const treeNode: TreeNode = yield select(TreeSelectors.itemById(nodeId));

                if (treeNode && treeNode.nodeId.id !== AdminToolTreeType.ADMIN_TOOL_ROOT) {
                    yield put(loadAdminToolByTreeNode(treeNode));
                }
            }
            break;
        case ObjectTypesForLink.Approval:
            yield put(treeItemOpenPropertyAction(nodeId, type, elementIds));
            break;
        default:
            break;
    }
}

function* handleImportNode({ payload }: TTreeImportNodeAction) {
    const {
        parentNodeId: { id, serverId, repositoryId },
        type,
        data,
        process,
    } = payload;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const importState: TImportDialogState = yield select(ImportDialogSelectors.getImportDialogState);
    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);

    const dataName: string = data ? data.name : '';
    const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processImport)} "${dataName}"`;
    const proc: TExecutingProcess = process || { id: uuid(), name: processName, type: ProcessType.IMPORT };

    if (!process) {
        yield put(setProcessIndicator(true, proc));
    }

    if (importState.importCanceledByUser) {
        yield put(importRequestDone(proc));
    } else {
        try {
            const fileUUID: string | undefined = data && (yield FileDaoService.uploadTmpFile(serverId, data)).result;
            let response: ImportResponse;
            const body: ImportRequest = {
                name: importState.name,
                resolveStrategyById: importState.resolveStrategyById,
                importFileName: fileUUID || importState.fileUUID,
            };
            if (type === TreeItemType.Server) {
                response = yield server.api.transfer.doRepositoryImport({ body });
            } else {
                response = yield server.api.transfer.doImport({
                    body,
                    repositoryId,
                    parentId: id,
                });
            }

            if (response.errorType && (response.errorType as ImportErrorType) !== ImportErrorType.NONE) {
                showImportErrorNotification(response.errorType as ImportErrorType, intl);
                yield put(importRequestDone(proc));

                return;
            }

            if (response.hasUnresolvedConflicts) {
                yield put(importDialogSetState(response, type, { id, serverId, repositoryId }, body.importFileName!));
                yield put(importDialogInit(proc));
            } else {
                yield put(importRequestDone(proc));
                yield put(
                    showNotification({
                        id: uuid(),
                        type: NotificationType.IMPORT_FILE_SUCCESS,
                    }),
                );
                if (type === TreeItemType.Server) {
                    yield call(refreshServerNode, serverId);
                } else {
                    yield call(refreshNode, {
                        id: repositoryId,
                        serverId,
                        repositoryId,
                    });
                }
            }
        } catch (e) {
            yield put(importRequestDone(proc));
            showImportErrorNotification(ImportErrorType.UNKNOWN, intl);
        }
    }
}

function showImportErrorNotification(error: ImportErrorType, intl: any) {
    switch (error) {
        case ImportErrorType.ROOT_CANT_BE_DB:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.importFileFailNodeCantBeDB)),
                ),
            );
            break;
        case ImportErrorType.ROOT_MUST_BE_DB:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.importFileFailNodeMustBeDB)),
                ),
            );
            break;
        case ImportErrorType.WRONG_TRANSFER_DATA:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.importFileFailWrongFormat)),
                ),
            );
            break;
        case ImportErrorType.UNKNOWN:
            getStore().dispatch(showNotification(getImportErrorNotification()));
            break;
        case ImportErrorType.ACCESS_DENIED:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.importFileFailAccessDenied)),
                ),
            );
            break;
        case ImportErrorType.CONVERTER_ERROR:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(
                        intl.formatMessage(AppNotificationsMessages.importFileFailConverterError),
                    ),
                ),
            );
            break;
        case ImportErrorType.DENIED_IMPORT_TARGET:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.deniedImportTarget)),
                ),
            );
            break;
        case ImportErrorType.DELETED_IMPORT_TARGET:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.deletedImportTarget)),
                ),
            );
            break;
        case ImportErrorType.OUTSIDE_OF_SCRIPT_FOLDER:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(
                        intl.formatMessage(AppNotificationsMessages.importOutsideOfScriptFolder),
                    ),
                ),
            );
            break;
        case ImportErrorType.NOT_ALLOWED_FOR_SCRIPT_FOLDER:
            getStore().dispatch(
                showNotification(
                    getImportErrorNotification(intl.formatMessage(AppNotificationsMessages.notAllowedForScriptFolder)),
                ),
            );
            break;
        default:
            break;
    }
}

function showExportBpmnErrorNotification(status: BpmnExportResponseStatusEnum | undefined, intl: IntlShape) {
    const show = (messageDescriptor: MessageDescriptor) =>
        getStore().dispatch(
            showNotification({
                id: uuid(),
                type: NotificationType.EXPORT_FAIL,
                data: { message: intl.formatMessage(messageDescriptor) },
            } as TNotificationEntity),
        );
    if (status === 'ERROR_MODEL_NOT_FOUND') {
        show(AppNotificationsMessages.exportBpmnErrorModelNotFound);
    } else if (status === 'ERROR_MODEL_NOT_BPMN') {
        show(AppNotificationsMessages.exportBpmnErrorModelNotBpmn);
    } else if (status === 'ERROR_NO_ACCESS') {
        show(AppNotificationsMessages.exportBpmnErrorNoAccess);
    } else if (status !== 'SUCCESS') {
        show(AppNotificationsMessages.exportError);
    }
}

function getImportErrorNotification(message?: string): TNotificationEntity {
    return {
        id: uuid(),
        type: NotificationType.IMPORT_FILE_FAIL,
        data: message && { message },
    } as TNotificationEntity;
}

function* secondaryActionHandler({ payload }: TTreeItemSecondaryAction) {
    const { nodeId, type } = payload;
    yield openNodeByTreeItemNodeId(nodeId, type);
}

const RECENT_NODE_TYPES = [TreeItemType.Model, TreeItemType.Matrix, TreeItemType.Script, TreeItemType.Wiki];

function* handleTreeItemTitleChangeRequest({
    payload: {
        nodeId,
        nodeId: { id, repositoryId, serverId },
        title,
    },
}: TTreeItemTitleChangeRequestAction) {
    const currentLocale: Locale = LocalesService.getLocale();
    if (!title || title.trim() === '') {
        return;
    }
    const treeNode: TTreeEntityState | undefined = yield select(TreeSelectors.itemById(nodeId));
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    yield call(() => server.api.tree.rename({ repositoryId, nodeId: id, body: title }));

    if (!treeNode) return;

    const updatedParams = nodePropertiesUpdate(nodeId, treeNode.type, {
        name: title,
        nodeId,
        type: treeNode.type as NodeTypeEnum,
        multilingualName: { ...(treeNode.multilingualName || {}), [currentLocale]: title },
    });

    yield updateProperties(updatedParams);
}

export function* saveNodeLockSettings(
    nodeId: NodeId,
    lockType: NodeLockRequestLockTypeEnum,
    value: undefined | boolean | string,
    oldValue: undefined | boolean,
) {
    if (value !== undefined && value.toString() !== oldValue?.toString()) {
        yield NodeLockDAOService.saveNodeLockSettings(
            nodeId,
            lockType,
            value?.toString() === 'true' ? 'CREATE' : 'DELETE',
        );
    }
}

// Проверяет изменились ли атрибуты и имя узла. Возможно применять только для некоторых типов элементов,
// у которых в окне свойств изменяется только имя, атрибуты и флаг конфеденциально
function isNodeProperiesChange(newNode: Node, oldNode: Node): Boolean {
    // что бы перед релизом меньше тестировать и ничего не сломать применяем только для моделей
    if (newNode.type !== TreeItemType.Model) {
        return true;
    }

    const changedNodeNew = {
        multilingualName: newNode.multilingualName,
        attributes: newNode.attributes,
        confidential: newNode.confidential,
        tradeSecret: newNode.tradeSecret,
        personalData: newNode.personalData,
    };
    const changedNodeOld = {
        multilingualName: oldNode.multilingualName,
        attributes: oldNode.attributes,
        confidential: oldNode.confidential,
        tradeSecret: oldNode.tradeSecret,
        personalData: oldNode.personalData,
    };

    return JSON.stringify(changedNodeNew) != JSON.stringify(changedNodeOld);
}

function* handleTreeItemPropertyChangeRequest({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payload: { node, oldNode, changedProperties },
}: TTreeItemPropertiesChangeRequest) {
    const {
        nodeId,
        attributes,
        confidential,
        multilingualName,
        name,
        userEditDisabled,
        scriptEngineEditDisabled,
        nodeId: { serverId },
        deleted,
        deletedAt,
        deletedBy,
        tradeSecret,
        personalData,
    } = node;
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));

    const requestNodeAttr: NodeAttributes | Node = {
        multilingualName,
        name,
        attributes,
        confidential,
        deleted,
        deletedAt,
        deletedBy,
        userEditDisabled: userEditDisabled?.toString() === 'true',
        scriptEngineEditDisabled: scriptEngineEditDisabled?.toString() === 'true',
        tradeSecret,
        personalData,
    };

    const { userEditDisabled: oldUserEditDisabled, scriptEngineEditDisabled: oldScriptEngineEditDisabled } = oldNode;

    // сначала снимаем блокировку с Блокировка на ручные изменения, а потом выполняем сохранения
    if (userEditDisabled?.toString() === 'false')
        yield call(saveNodeLockSettings, nodeId, 'USER', userEditDisabled, oldUserEditDisabled);

    // Объекты и связи сохраняются через другую сагу, поэтому в данном методе невозможно гарантировать
    // что запрос на блокировку будуте выполнен строго после сохранения, при установке блокировки и сохрании свойств объекта/связи будет ошибка
    // дефект  https://jira.silaunion.ru/browse/BPM-8759
    if (
        userEditDisabled?.toString() === 'true' &&
        (node.type === TreeItemType.ObjectDefinition || node.type === TreeItemType.EdgeDefinition)
    )
        yield call(saveNodeLockSettings, nodeId, 'USER', userEditDisabled, oldUserEditDisabled);

    yield call(saveNodeLockSettings, nodeId, 'SCRIPT', scriptEngineEditDisabled, oldScriptEngineEditDisabled);

    if (node.type === TreeItemType.ObjectDefinition) {
        // у объекта есть свойство - декомпозиции, поэтому сохраняем его полностью
        yield put(saveObjectDefinition(serverId, node as ObjectDefinitionImpl));
    } else if (node.type === TreeItemType.Folder) {
        yield call(() => server.api.tree.save({ body: node }));
        yield put(treeItemUpdate({ nodeId, data: node }));
    } else if (node.type === TreeItemType.EdgeDefinition) {
        yield put(edgeDefinitionsUpdate(node));
    } else if (node.type === TreeItemType.Repository) {
        yield put(updateRepository(node as RepositoryNode));
    } else if (node.type === TreeItemType.Script) {
        const allowedScriptContext: AllowedScriptContext = yield select(ScriptSelectors.context(nodeId));
        const scriptAttributes: ScriptNodeAttributes = {
            ...requestNodeAttr,
            nodeType: TreeItemType.Script,
            allowedScriptContext,
        };

        yield TreeDaoService.saveAttributes(nodeId, scriptAttributes);
    } else {
        if (isNodeProperiesChange(node, oldNode)) {
            yield TreeDaoService.saveAttributes(nodeId, requestNodeAttr);
        }
    }

    const updatedParams = nodePropertiesUpdate(nodeId, parseTreeItemType(node.type), requestNodeAttr as Node);
    yield updateProperties(updatedParams);

    if (node.type === TreeItemType.Model) {
        yield put(updateAllCellsOverlays(nodeId));
    }

    // сначала выполняем сохранения, потом выставляем блокировку на Блокировка на ручные изменения
    // проверка типов для которых приходится блокровку делать перед сохранением
    // дефект https://jira.silaunion.ru/browse/BPM-8759
    if (
        userEditDisabled?.toString() === 'true' &&
        node.type !== TreeItemType.ObjectDefinition &&
        node.type !== TreeItemType.EdgeDefinition
    )
        yield call(saveNodeLockSettings, nodeId, 'USER', userEditDisabled, oldUserEditDisabled);

    yield put(closeDialog(DialogType.PROPERTIES_DIALOG));
}

export function* updateGraph(updatedNode: Node | undefined, existingNode: Node | undefined) {
    if (!isUndefined(updatedNode) && existingNode?.name !== updatedNode.name) {
        const cellIdsMap = nodeService().findCellIdsByNodeId(updatedNode.nodeId);

        for (const [graphId, cellIds] of Array.from(cellIdsMap.entries())) {
            const graph = instancesBPMMxGraphMap.get(graphId);

            if (!graph) return;

            graph.getModel().beginUpdate();

            try {
                Object.values<MxCell>(graph.model.cells)
                    .filter((cell) => cellIds.includes(cell.getId()))
                    .forEach((cell) => graph.refresh(cell));
            } finally {
                graph.getModel().endUpdate();
            }
        }
    }

    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) return;

    activeGraph.complexSymbolManager.refreshCells();
}

function* updateProperties({ payload: { nodeId, type, node } }: TNodePropertiesUpdateAction) {
    const {
        name,
        countChildren,
        multilingualName,
        deleted,
        deletedAt,
        deletedBy,
        userEditDisabled,
        scriptEngineEditDisabled,
    } = node;
    const existingNode: Node | undefined = yield select(TreeSelectors.itemById(nodeId));

    if (countChildren !== undefined) {
        // TODO: После решения задачи BPM-4406 приводить countChildren к числу не потребуется
        // сейчас он приходит в виде строки countChildren: "4"
        yield put(
            treeItemUpdate({
                nodeId,
                data: {
                    hasChildren: !!Number(countChildren),
                    deleted,
                    deletedAt,
                    deletedBy,
                },
            }),
        );
    }

    if (getNodeName(node)) {
        yield put(
            treeItemUpdate({
                nodeId,
                data: {
                    name,
                    multilingualName,
                    deleted,
                    deletedAt,
                    deletedBy,
                },
            }),
        ); // обновление объекта в дереве

        if (RECENT_NODE_TYPES.includes(type)) {
            const model = yield select(getRecentModel(nodeId));

            if (model) {
                model.title = getNodeName(node);
                yield put(recentAddModel(model));
            }
        }

        const tab: TWorkspaceTab = yield select(TabsSelectors.byId(nodeId));
        // не меняем название вкладки, если это вкладка удаленных элементов
        // иначе она поменяет свое название на имя репозитория по которому идет поиск удаленных элементов
        if (tab && tab.type !== WorkSpaceTabTypes.DB_SEARCH_DELETED_ELEMENTS) {
            yield put(workspaceChangeTabTitle(tab, name));
        }
    }

    if (
        existingNode &&
        (existingNode.deleted !== deleted ||
            existingNode.deletedAt !== deletedAt ||
            existingNode.deletedBy !== deletedBy ||
            existingNode.userEditDisabled !== userEditDisabled ||
            existingNode.scriptEngineEditDisabled !== scriptEngineEditDisabled)
    ) {
        yield put(
            treeItemUpdate({
                nodeId,
                data: { deleted, deletedAt, deletedBy, userEditDisabled, scriptEngineEditDisabled },
            }),
        );
    }

    switch (type) {
        case TreeItemType.Model: {
            delete (node as ModelNode).version;
            delete (node as ModelNode).elements;
            delete (node as ModelNode).graph;
            yield put(changeModelData(nodeId, node));
            break;
        }
        case TreeItemType.Wiki: {
            delete (node as IWikiNode).version;
            delete (node as IWikiNode).source;
            yield put(changeWikiData(nodeId, node as IWikiNode));
            break;
        }
        case TreeItemType.Matrix: {
            delete (node as MatrixNode).version;
            delete (node as MatrixNode).content;
            (node as MatrixNode).nodeId = nodeId;
            yield put(changeMatrixNodeProperties(node as MatrixNode));
            break;
        }
        case TreeItemType.Spreadsheet: {
            yield put(spreadsheetUpdateAfterEditInPropertyTab({ ...node, nodeId } as ISpreadsheetNode));
            break;
        }
        case TreeItemType.Script: {
            yield put(changeScriptData(nodeId, node as IScriptNode));
            break;
        }
        case TreeItemType.Kanban: {
            yield put(kanbanSetNode({ ...node, nodeId }));
            break;
        }
        case TreeItemType.Repository:
        case TreeItemType.Folder:
        case TreeItemType.SimulationModeling:
        case TreeItemType.File: {
            yield put(treeItemUpdate({ nodeId, data: node }));
            break;
        }
        case TreeItemType.ObjectDefinition: {
            const objectDefinition: ObjectDefinitionImpl | undefined = yield select(
                ObjectDefinitionSelectors.byId(nodeId),
            );
            const updatedObjectDefinition: ObjectDefinitionNode = objectDefinition
                ? { ...(objectDefinition || {}), ...node }
                : node;

            yield put(objectDefinitionsAdd([updatedObjectDefinition as ObjectDefinitionImpl]));
            yield call(updateGraph, updatedObjectDefinition, objectDefinition);

            break;
        }
        case TreeItemType.EdgeDefinition: {
            const edgeDefinition: EdgeDefinitionNode | undefined = yield select(EdgeDefinitionSelectors.byId(nodeId));

            if (edgeDefinition) {
                const updatedEdgeDefinition: EdgeDefinitionNode = { ...edgeDefinition, ...node };

                yield put(edgeDefinitionsAdd([updatedEdgeDefinition]));
                yield call(updateGraph, updatedEdgeDefinition, edgeDefinition);
            }

            break;
        }
        default:
            break;
    }
}

function* refreshNode(nodeId: NodeId) {
    try {
        const updatedNode: Node | undefined = yield nodeService().loadNodeFromServer(nodeId);

        if (updatedNode) {
            yield put(nodePropertiesUpdate(nodeId, updatedNode.type as TreeItemType, updatedNode));
        }
    } catch (e) {
        if (
            e.status === ServerErrorType.FORBIDDEN ||
            e.status === ServerErrorType.ACCESS_DENIED_BY_PROFILE ||
            e.status === ServerErrorType.NOT_FOUND
        ) {
            yield put(treeItemDelete(nodeId));
        }
        throw e;
    }

    const childrenNodeIdList: NodeId[] = yield select(
        TreeSelectors.openChildrenRefreshNodeList(nodeId, NAVIGATOR_STRUCTURE),
    );

    for (const childrenNodeId of childrenNodeIdList) {
        yield treePartFetch(treePartFetchRequest(childrenNodeId, childrenNodeId.serverId, NAVIGATOR_STRUCTURE));
    }
}

function* refreshNodeHandler({ payload: { nodeId, type } }: TTreeItemRefreshAction) {
    if (!nodeId || isEmpty(nodeId)) {
        return;
    }

    if (type === TreeItemType.Server) {
        yield refreshServerNode(nodeId.serverId);
    } else {
        yield refreshNode(nodeId);
    }
}

function* refreshServerNode(id: string) {
    const connectedServers = yield select(ServerSelectors.connected);

    if (connectedServers.includes(id)) {
        const nodes: Node[] = yield call(() => RepositoryDAOService.getRepositories(id));
        const repositories: TreeNode[] = yield select(getRepositories(id));
        const deletedRepositories: NodeId[] = TreeBLLService.getDeletedRepositories(nodes, repositories);

        for (const repositoryNodeId of deletedRepositories) {
            yield put(treeItemDelete(repositoryNodeId));
        }

        const addedRepositories: Node[] = TreeBLLService.getAddedRepositories(nodes, repositories);

        for (const addedRepository of addedRepositories) {
            yield put(treeItemAdd({ ...addedRepository } as TreeNode));
        }

        for (const repository of repositories) {
            const { nodeId } = repository;
            const expandStatus = yield select(ExpandedStatusSelector.expandStatus(NAVIGATOR_STRUCTURE, nodeId));

            if (expandStatus === ExpandStatus.OPEN) {
                yield call(refreshNode, nodeId);
            } else {
                const repositoryNode = TreeBLLService.findNodeByNodeId(nodes, nodeId);

                if (repositoryNode) {
                    yield put(
                        treeItemUpdate({
                            nodeId,
                            data: {
                                ...repositoryNode,
                                deleted: repositoryNode.deleted,
                                deletedAt: repositoryNode.deletedAt,
                                deletedBy: repositoryNode.deletedBy,
                            },
                        }),
                    );
                }
            }
        }
    } else {
        yield put(openAuthServerDialog(id));
    }
}

function* handleStartDragTreeItem(action: TTreeItemStartDragAction | TTreeItemSelectAction) {
    const treeNode: TreeNode | undefined = action.payload.selectedNode;
    const activeScheme: TWorkspaceTab | undefined = yield select(getActiveTab);
    const isActiveModel: boolean = activeScheme?.content?.type === TreeItemType.Model;
    const isActiveMatrix: boolean = activeScheme?.content?.type === TreeItemType.Matrix;

    if (treeNode?.type === TreeItemType.ObjectDefinition && activeScheme?.content) {
        if (isActiveMatrix) {
            yield objectDefinitionService().loadObject(treeNode.nodeId);
        } else if (isActiveModel) {
            const enabledObjectTypes: string[] =
                (activeScheme.params as IWorkspaceTabItemModelParams)?.symbols.map((item: Symbol) => item.objectType) ||
                [];

            if (treeNode.objectTypeId && enabledObjectTypes.includes(treeNode.objectTypeId)) {
                const modelContext: IModelContext | null = yield getActiveModelContext();
                if (modelContext) {
                    yield objectDefinitionService().loadObject(treeNode.nodeId);
                }
            }
        }
    }
}

function* handleExportNode({ payload }: TTreeExportNodeAction) {
    const {
        nodeId: { id, repositoryId, serverId },
        name,
        exportRequest,
    } = payload;

    const locale = yield select(getCurrentLocale);
    const intl = LocalesService.useIntl(locale);
    const server: TServerEntity = yield select(ServerSelectors.server(serverId));
    const processName: string = `${intl.formatMessage(ProcessIndicatorMessages.processExport)} "${name}"`;
    const process: TExecutingProcess = { id: uuid(), name: processName, type: ProcessType.EXPORT };

    yield put(setProcessIndicator(true, process));

    try {
        const response: StubResponse = yield server.api.transfer.doExport({
            repositoryId,
            id,
            body: exportRequest,
        });
        const exportDialogOptions: SaveDialogOptions = {
            title: intl.formatMessage(messages.exportDialogTitle),
            defaultPath: `${name}.ste`,
            filters: [
                { name: 'Sila Tree Export file', extensions: ['ste'] },
                { name: 'ZIP file', extensions: ['zip'] },
                { name: 'XML file', extensions: ['xml'] },
                { name: 'All files', extensions: ['*'] },
            ],
        };

        BrowserDaoService.downloadFile(`${server.url}/${TMP_DOWNLOAD_PATH}/${response.result}`, exportDialogOptions);
    } finally {
        yield put(setProcessIndicator(false, process));
    }
}

function* handleOpenChoseTreeNode(action: TOpenChooseTreeNodeAction) {
    const { nodeId } = action.payload;
    const node: TTreeEntityState = yield select(TreeSelectors.itemById(nodeId));

    yield put(treeItemSelect(node));
}

function* handleTreeItemUmlClassObjectSave(action: TTreeItemUmlClassObjectSaveAction) {
    const { deletedObjectIds, objectDefinitions, newMethods, serverId, classObject } = action.payload;

    if (deletedObjectIds.length > 0) {
        yield TreeDaoService.deleteTreeNodes(deletedObjectIds);
    }

    if (newMethods.length > 0) {
        yield ObjectDefinitionsDAOService.createBulkObjectDefinitions(serverId, newMethods);
    }

    yield ObjectDefinitionsDAOService.createBulkObjectDefinitions(serverId, objectDefinitions);
    const loadedObjectDefinitions: ObjectDefinitionNode[] = yield objectDefinitionService().loadObjectsFromServer(
        serverId,
        objectDefinitions.map(({ nodeId }) => nodeId),
    );

    yield put(
        treeItemUmlObjectsUpdate({
            objectDefinitions: loadedObjectDefinitions,
        }),
    );
    yield put(objectDefinitionUmlUpdate({ objectDefinitions: loadedObjectDefinitions as ObjectDefinitionImpl[] }));
    // обновяем графф для перерисовки символов класса
    yield call(updateGraph, classObject, undefined);
}

function* treePartFetchAllScripts({ payload: { nodeId } }: TTreePartFetchAllScriptsAction) {
    try {
        const nodes: Node[] = yield call(() => TreeDaoService.getAllChild(nodeId));
        yield put(setScriptsNodesAction(nodes));
    } catch (e) {
        console.error(e);
    }
}

function* handleSetShowDeletedObjectsFilterRequest({ payload }: TSetShowDeletedObjectsFilterRequestAction) {
    const serverId: string = yield select(ServerSelectors.serverId);

    yield call(() => TreeDaoService.saveShowDeletedObjectProperty(serverId, payload));
    yield put(setShowDeletedObjectsFilterSuccess(payload));
    yield call(refreshServerNode, serverId);
}

function* handleTreeNodeSubscribe({ payload: { nodeId } }: TTreeNodeSubscribeAction) {
    yield call(() => TreeDaoService.subscribe(nodeId));
    yield put(treeNodeAddSubscriptionToStore(nodeId));

    const treeNode: TreeNode | undefined = yield select(TreeSelectors.unfilteredItemById(nodeId));

    yield put(
        showNotification({
            id: uuid(),
            data: { message: treeNode?.name || '' },
            type: NotificationType.NODE_SUBSCRIBED,
        }),
    );
}

function* handleTreeNodeUnsubscribe({ payload: { nodeId } }: TTreeNodeUnsubscribeAction) {
    yield call(() => TreeDaoService.unsubscribe(nodeId));
    yield put(treeNodeDeleteSubscribtionFromStore(nodeId));

    const treeNode: TreeNode | undefined = yield select(TreeSelectors.unfilteredItemById(nodeId));

    yield put(
        showNotification({
            id: uuid(),
            data: { message: treeNode?.name || '' },
            type: NotificationType.NODE_UNSUBSCRIBED,
        }),
    );
}

function* handleServerFolderExpansion(serverNode: NodeId, treeName: string = NAVIGATOR_STRUCTURE) {
    const expandStatus: ExpandStatus = yield select(ExpandedStatusSelector.expandStatus(treeName, serverNode));

    if (expandStatus === ExpandStatus.CLOSED) {
        yield treeExpandHandler(treeItemExpand(serverNode, treeName));
    }
}

function* handleParentExpansion(parentList: NodeId[], treeName: string = NAVIGATOR_STRUCTURE) {
    for (const parent of parentList.reverse()) {
        yield treeExpandHandler(treeItemExpand(parent, treeName));
    }
}

export function* handleMoveToDirect({ payload: { treeNode, treeName } }: TEditorMoveToDirectAction) {
    const { nodeId } = treeNode;

    const serverNode: NodeId = { id: nodeId.serverId, repositoryId: nodeId.serverId, serverId: nodeId.serverId };
    const isRepositoryNode: boolean = treeNode.type === TreeItemType.Repository || nodeId.id === nodeId.repositoryId;
    const isTreeNodeOnOpenTree: boolean | undefined = yield select(
        TreeSelectors.isItemOnOpenTree(nodeId, treeName || ''),
    );

    yield* handleServerFolderExpansion(serverNode, treeName);

    if (isTreeNodeOnOpenTree && !isRepositoryNode) {
        const treeItems: { [id: string]: TTreeEntityState } = yield select(
            getTreeItems(nodeId.serverId, nodeId.repositoryId),
        );
        const parentNodeIds: NodeId[] = getAllParentNodeIds(treeItems, treeNode.nodeId.id);

        yield put(applyTreeFiltersByParentTypes(parentNodeIds, treeNode.type));
        yield put(treeItemSelect(treeNode));
        yield put(treeItemScroll(treeNode.nodeId));
        return;
    }

    const parentElements: { [key: string]: NodeId[] } = yield TreeDaoService.findAllParentElementId([nodeId]);

    if (!parentElements[nodeId.id]?.length && !isRepositoryNode) {
        yield put(showNotificationByType(NotificationType.GOTO_ERROR));

        return;
    }

    if (isRepositoryNode) {
        const upToDateNode: TreeNode | undefined = yield select(TreeSelectors.unfilteredItemById(nodeId));
        if (!upToDateNode) {
            yield put(showNotificationByType(NotificationType.GOTO_ERROR));

            return;
        }
    }

    const parentNodeIds: NodeId[] = isRepositoryNode ? [] : parentElements[nodeId.id];

    yield* handleParentExpansion(parentNodeIds, treeName);
    yield put(applyTreeFiltersByParentTypes(parentNodeIds, treeNode.type));

    yield put(treeItemSelect(treeNode));
    yield put(treeItemScroll(treeNode.nodeId));
}

function* handleTreeCollapse() {
    const serverId: string = yield select(ServerSelectors.serverId);
    const repositoryNodes: TreeNode[] = yield select(getRepositories(serverId));
    const nodeIdsList: NodeId[] = [];
    const adminToolsRootNodeId: NodeId = {
        id: AdminToolTreeType.ADMIN_TOOL_ROOT,
        repositoryId: AdminToolTreeType.ADMIN_TOOL_ROOT,
        serverId,
    };
    const fileFolderRootNodeId: NodeId = {
        id: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        repositoryId: RootNodeId.ROOT_SCRIPT_FOLDER_ID,
        serverId,
    };
    const scriptFolderRootNodeId: NodeId = {
        id: RootNodeId.FILE_FOLDER_ROOT_ID,
        repositoryId: RootNodeId.FILE_FOLDER_ROOT_ID,
        serverId,
    };

    for (const nodeId of [
        ...repositoryNodes.map((node) => node.nodeId),
        fileFolderRootNodeId,
        scriptFolderRootNodeId,
    ]) {
        const childrenNodeIdList: NodeId[] = yield select((state) =>
            TreeSelectors.walkAllTree(nodeId, state, NAVIGATOR_STRUCTURE),
        );
        nodeIdsList.push(...childrenNodeIdList);
    }

    yield put(
        treeItemCollapseSuccess(
            [...nodeIdsList, adminToolsRootNodeId, fileFolderRootNodeId, scriptFolderRootNodeId],
            NAVIGATOR_STRUCTURE,
        ),
    );
}

export function* treeSaga() {
    yield takeEvery(TREE_PART_FETCH_REQUEST, treePartFetch);
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, contextMenuActionsHandler);
    yield takeEvery(TREE_ITEM_SECONDARY_ACTION, secondaryActionHandler);
    yield takeEvery(TREE_IMPORT_NODE, handleImportNode);
    yield takeEvery(TREE_ITEM_TITLE_CHANGE_REQUEST, handleTreeItemTitleChangeRequest);
    yield takeEvery(TREE_ITEM_PROPERTIES_CHANGE_REQUEST, handleTreeItemPropertyChangeRequest);
    yield takeEvery(TREE_ITEM_START_DRAG, handleStartDragTreeItem);
    yield takeEvery(TREE_ITEM_CLICK_DRAG_ICON, handleStartDragTreeItem);
    yield takeEvery(TREE_ITEM_EXPAND, treeExpandHandler);
    yield takeEvery(NODE_PROPERTIES_UPDATE, updateProperties);
    yield takeEvery(TREE_EXPORT_NODE, handleExportNode);
    yield takeEvery(OPEN_CHOOSE_TREE_NODE, handleOpenChoseTreeNode);
    yield takeEvery(TREE_ITEM_REFRESH, refreshNodeHandler);
    yield takeEvery(TREE_ITEM_UML_CLASS_OBJECT_SAVE, handleTreeItemUmlClassObjectSave);
    yield takeEvery(TREE_PART_FETCH_ALL_SCRIPTS_REQUEST, treePartFetchAllScripts);
    yield takeEvery(SET_SHOW_DELETED_OBJECTS_FILTER_REQUEST, handleSetShowDeletedObjectsFilterRequest);
    yield takeEvery(TREE_NODE_SUBSCRIBE, handleTreeNodeSubscribe);
    yield takeEvery(TREE_NODE_UNSUBSCRIBE, handleTreeNodeUnsubscribe);
    yield takeEvery(EDITOR_MOVE_TO_DIRECT, handleMoveToDirect);
    yield takeEvery(TREE_COLLAPSE, handleTreeCollapse);
}
