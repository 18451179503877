import { createSelector } from 'reselect';
import { compareNodes, compareTreeObjectType } from '../models/tree';
import { TreeItemType } from '../modules/Tree/models/tree';
import { TRootState } from '../reducers/root.reducer.types';
import { TSearchDataListItem, TSearchReducerState, TSearcParams } from '../reducers/search.reducer.types';
import { NodeId, SearchRequestNodeTypesEnum, SearchRequestSearchVisibilityEnum } from '../serverapi/api';
import { ISearchRuleWithValueId } from '@/modules/Search/AttributeFilter/AttributeFilter.types';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import { TDialogState } from '@/reducers/dialogs.reducer.types';
import { compareNodeIds } from '@/utils/nodeId.utils';

const getDialogsState = (state: TRootState) => state.dialogs;

const getActiveSearch = ({ search, schemes, dialogs }: TRootState) => {
    const dbSearcDialog: TDialogState | undefined = dialogs.open.find(
        (dialog) => dialog.type === DialogType.DB_SEARCH_DIALOG,
    );

    if (dbSearcDialog) return search[dbSearcDialog.props.nodeId.id];

    const split: string[] | undefined = schemes.activeId?.id?.split('SearchTab ');

    return split && split.length > 1 ? search[split[1]] : undefined;
};

const getUniqueTypes = (res: TSearchDataListItem[]): TreeItemType[] => Array.from(new Set(res.map((el) => el.type)));

const searchSelector = (state: TRootState): TSearchReducerState => state.search;
export namespace SearchSelectors {
    export const getId = (state: TRootState): string => {
        const dbSearcDialog: TDialogState | undefined = state.dialogs.open.find(
            (dialog) => dialog.type === DialogType.DB_SEARCH_DIALOG,
        );

        if (dbSearcDialog) {
            return dbSearcDialog.props.nodeId.id;
        }

        return state.schemes.activeId?.id.split('SearchTab ')[1] || '';
    };
    export const getPath = (state: TRootState): string => getActiveSearch(state)?.path || '';
    export const getSearchResult = (state: TRootState): TSearchDataListItem[] =>
        getActiveSearch(state)?.searchResult || [];
    export const getServerId = (state: TRootState): string => getActiveSearch(state)?.nodeId?.serverId || '';
    export const getNodeId = (state: TRootState): NodeId =>
        getActiveSearch(state)?.nodeId || { id: '', repositoryId: '', serverId: '' };
    export const getLoadingStatus = (state: TRootState): boolean => !!getActiveSearch(state)?.isLoading;
    export const getFilter = (state: TRootState): string[] => getActiveSearch(state)?.filter || [];
    export const getFilterSearchResult = () =>
        createSelector(getSearchResult, getFilter, (search, filter) => {
            const uniqueTypes: TreeItemType[] = getUniqueTypes(search);
            const uniqueFilter: TreeItemType[] = uniqueTypes?.filter((el: TreeItemType) => filter.includes(el));
            const filterSearchResult: TSearchDataListItem[] = search
                .filter((el) => !uniqueFilter.length || uniqueFilter.includes(el.type))
                .sort(compareNodes);

            return filterSearchResult;
        });

    export const isDialogSearch = (nodeId: NodeId) =>
        createSelector(getDialogsState, (dialogs) => {
            const dbSearcDialog: TDialogState | undefined = dialogs.open.find(
                (dialog) => dialog.type === DialogType.DB_SEARCH_DIALOG,
            );

            if (dbSearcDialog) {
                return compareNodeIds(dbSearcDialog.props.nodeId, nodeId);
            }

            return false;
        });

    export const getFilterUniqueType = () =>
        createSelector(getSearchResult, (searchResult) => {
            const uniqueTypes: TreeItemType[] = getUniqueTypes(searchResult).sort(compareTreeObjectType);

            return uniqueTypes;
        });

    export const getSearchRules = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, ISearchRuleWithValueId[]>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams?.searchRules || [],
        );

    export const getSearchNodeTypes = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, SearchRequestNodeTypesEnum[]>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams?.nodeTypes || [],
        );

    export const getSearchText = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, string>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams?.searchText || '',
        );

    export const getSearchVisibility = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, SearchRequestSearchVisibilityEnum>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams?.searchVisibility || 'NOT_DELETED',
        );

    export const getRootSearchNodeId = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, NodeId>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams?.rootSearchNodeId || { id: '', repositoryId: '', serverId: '' },
        );

    export const getSearchParams = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, TSearcParams | undefined>(
            searchSelector,
            (state) => state[nodeId.id]?.searchParams,
        );

    export const getSearchResultByNodeId = (nodeId: NodeId) =>
        createSelector<TRootState, TSearchReducerState, TSearchDataListItem[]>(
            searchSelector,
            (state) => state[nodeId.id]?.searchResult,
        );
}
