import type { TSubmitEdgeManagementDialogAction } from '@/actions/edgeManagement.actions.types';
import type { TGeneralMenuLabelStyleChangeAction } from '../actions/generalMenu.actions.types';
import type { TAction } from '../actions/index.actions.types';
import type { TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import type { IDiagramLockError } from '../models/notification/diagramLockError.types';
import type { TDialogOpenAction } from '../actions/dialogs.actions.types';
import type { TWorkspaceTab } from '../models/tab.types';
import type { IModelContext } from './utils.types';
import type { TServerEntity } from '../models/entities.types';
import type { TCurrentUserProfile } from '../reducers/userProfile.reducer.types';
import type { TObjectTypeState } from '../reducers/objectType.reducer.types';
import type { IMatrixNode, IModelNode, IWikiNode } from '../models/bpm/bpm-model-impl.types';
import type { WhiteboardGraph } from '@/mxgraph/WhiteboardGraph';
import type {
    TChangeSymbolForCellAction,
    TDropFileNodeAction,
    TEditorAddBpmnTableRowAction,
    TEditorAddTableColumnAction,
    TEditorAddTableRowAction,
    TEditorAlignAction,
    TEditorCellAdded,
    TEditorCellColorPicked,
    TEditorChangeEdgeColorAction,
    TEditorChangeEdgeMultilingualName,
    TEditorChangeEdgeStyleAction,
    TEditorChangeEdgeType,
    TEditorChangeFontColorAction,
    TEditorDeleteAction,
    TEditorDestroyAction,
    TEditorDistributeAction,
    TEditorDragNodeAction,
    TEditorDragShapeAction,
    TEditorDropPastePreviewAction,
    TEditorGropingAction,
    TEditorInitAction,
    TEditorLabelChanged,
    TEditorModeChangedAction,
    TEditorMoveAction,
    TEditorMoveObjectAction,
    TEditorMoveToAction,
    TEditorPickOutAction,
    TEditorRemoveBpmnTableRowAction,
    TEditorRemoveTableColumnAction,
    TEditorRemoveTableRowAction,
    TEditorSetCellDefaultStyle,
    TEditorSetFocusAndStartEditLabel,
    TEditorSetObjectToCell,
    TEditorProcessSpaceAction,
    TCreateNewObjectName,
    TZoomToAction,
} from '../actions/editor.actions.types';
import type {
    TModelMoveAction,
    TDragSymbolAction,
    TObjectDefinitionMoveAction,
    TObjectDefinitionMoveActionPayload,
    TModelMoveActionPayload,
} from '../actions/entities/objectDefinition.actions.types';
import type {
    TObjectDecompositionChooseObjectDialogSubmit,
    TPayloadObjectDecompositionIconClicked,
} from '../actions/entities/objectDecomposition.actions.types';
import type { TNotificationShowAction } from '../actions/notification.actions.types';
import type { TDividingEdges } from '../utils/edgesForChangeType.utils.types';
import { all, call, cancelled, fork, put, putResolve, race, select, take, takeEvery } from 'redux-saga/effects';
import { buffers, eventChannel, Task } from 'redux-saga';
import { saveModel } from '../actions/save.actions';
import { v4 as uuid } from 'uuid';
import {
    CHANGE_SYMBOL_FOR_CELL,
    DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH,
    EDITOR_ADD_BPMN_TABLE_ROW,
    EDITOR_ADD_TABLE_COLUMN,
    EDITOR_ADD_TABLE_ROW,
    EDITOR_ALIGN,
    EDITOR_CELL_ADDED,
    EDITOR_CELL_COLOR_PICKED,
    EDITOR_CHANGE_EDGE_COLOR,
    EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME,
    EDITOR_CHANGE_EDGE_STYLE,
    EDITOR_CHANGE_EDGE_TYPE,
    EDITOR_CHANGE_FONT_COLOR,
    EDITOR_COPY,
    EDITOR_CUT,
    EDITOR_DELETE,
    EDITOR_DESTROY,
    EDITOR_DISTRIBUTE,
    EDITOR_DRAG_NODE,
    EDITOR_DRAG_SHAPE,
    EDITOR_DRAG_SYMBOL,
    EDITOR_DROP_PASTE_PREVIEW,
    EDITOR_DROP_FILE_NODE,
    EDITOR_ESCAPE,
    EDITOR_FORMAT_BY_EXAMPLE,
    EDITOR_GROUPING,
    EDITOR_INIT,
    EDITOR_LABEL_CHANGED,
    EDITOR_MODE_CHANGED,
    EDITOR_MODE_CHANGED_PREPARE,
    EDITOR_MOVE_LAYER,
    EDITOR_MOVE_OBJECT,
    EDITOR_MOVE_TO,
    EDITOR_SINGLE_CELL_SELECTED,
    EDITOR_PICK_OUT,
    EDITOR_PROCESS_SPACE_ACTION,
    EDITOR_REMOVE_BPMN_TABLE_ROW,
    EDITOR_REMOVE_TABLE_COLUMN,
    EDITOR_REMOVE_TABLE_ROW,
    EDITOR_SELECT_ALL,
    EDITOR_SET_CELL_DEFAULT_STYLE,
    EDITOR_SET_FOCUS_AND_START_EDIT_LABEL,
    EDITOR_SET_OBJECT_TO_CELL,
    EDITOR_UPDATE,
    EDITOR_ZOOM_IN,
    EDITOR_ZOOM_OUT,
    EDITOR_ZOOM_TO,
    CREATE_NEW__OBJECT_NAME,
    OPEN_SEARCH_ACTION,
    OPEN_ELEMENT_PROPERTIES,
} from '../actionsTypes/editor.actionTypes';
import {
    createEditorDNDHandler,
    deleteAction,
    editorCellAddedAction,
    editorModeChangedAction,
    editorSingleCellSelected,
    editorSelectionModelChange,
    editorLabelChangedAction,
    escapeAction,
    moveToDirectAction,
    setFocusAndStartEditAction,
    setRepositoryIdWhereCopiedFrom,
    setCopiedElementsAction,
    copyAction,
    handleDeleteSelectedCellsFromActiveGraphAction,
    copyWhiteboardAction,
    changeEdgeType,
} from '../actions/editor.actions';
import {
    MODEL_MOVE,
    OBJECT_DEFINITION_MOVE,
    SAVE_OBJECT_DEFINITION_FAIL,
    SAVE_OBJECT_DEFINITION_SUCCESS,
} from '../actionsTypes/entities/objectDefinition.actionTypes';
import { showNotification, showNotificationByType } from '../actions/notification.actions';
import { GENERAL_MENU_LABEL_STYLE_CHANGE } from '../actionsTypes/generalMenu.actionTypes';
import {
    generalMenuToggleStyleButtonsAction,
    generalMenuUpdateAvailableEdgeTypesAction,
} from '../actions/generalMenu.actions';
import { notification } from 'antd';
import {
    MxCell,
    MxClient,
    MxConstants,
    MxEvent,
    MxEventObject,
    MxGeometry,
    MxGraph,
    MxGraphSelectionModel,
    MxMouseEvent,
    MxPoint,
    MxUtils,
} from '../mxgraph/mxgraph';
import { WORKSPACE_TABS_ACTIVATE, WORKSPACE_TABS_REMOVE } from '../actionsTypes/tabs.actionTypes';
import { workspaceTabSetAction, workspaceTabSetParams } from '../actions/tabs.actions';
import {
    CommentMarker,
    EdgeInstanceImpl,
    LabelSymbol,
    ObjectDefinitionImpl,
    ObjectInstanceImpl,
} from '../models/bpm/bpm-model-impl';
import { ButtonEditLabelState } from '../models/buttonEditLabelState';
import { EditorMode } from '../models/editorMode';
import { Grouping } from '../models/grouping';
import { LabelStyle } from '../models/labelStyle';
import { ModelTypes } from '../models/ModelTypes';
import { LONG_NOTIFICATION_DURATION, NotificationType } from '../models/notificationType';
import AppNotificationsMessages from '../modules/App/messages/AppNotifications.messages';
import { instancesBPMMxGraphMap } from '../mxgraph/bpm-mxgraph-instance-map';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import * as SpaceTool from '../modules/Editor/classes/SpaceTool';
import { modelService } from '../services/ModelService';
import {
    applyFontStyle,
    CellTypes,
    checkIfElementsAreRestrictedForCut,
    getAvailableEdgeTypesForEvent,
    getLastSelectionObjectDefinitionForEvent,
    handleSelectedEvent,
    isCommentCell,
} from '../utils/bpm.mxgraph.utils';
import { isUndefined } from 'is-what';
import { getGeneralMenuButtonsState } from '../selectors/generalMenu.selectors';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { ModelSelectors } from '../selectors/model.selectors';
import { ModelTypeSelectors } from '../selectors/modelType.selectors';
import {
    AttributeType,
    DefaultId,
    DiagramElementTypeEnum,
    EdgeInstance,
    FileNodeDTO,
    EdgeType,
    FolderType,
    LockInfoDTO,
    ModelNode,
    ModelType,
    NodeId,
    ObjectDefinitionNode,
    ObjectInstance,
    ObjectType,
    ShapeInstance,
    Symbol,
    DiagramElement,
    EdgeDefinitionNode,
    AttributeValue,
    Comment,
    ModelAssignment,
    ReportNode,
} from '../serverapi/api';
import { LocalesService } from '../services/LocalesService';
import { NotationHelper } from '../services/utils/NotationHelper';
import { TTreeEntityState, TreeNode } from '../models/tree.types';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import {
    treeItemAdd,
    treeItemDeleteNodeFromServer,
    treeItemEndDrag,
    treeItemOpenPropertyAction,
} from '../actions/tree.actions';
import ConnectionMouseListener from '../mxgraph/listeners/ConnectionMouse.listener';
import { TreeSelectors } from '../selectors/tree.selectors';
import {
    modelMove,
    objectDefinitionMove,
    objectDefinitionsAdd,
    saveObjectDefinition,
    saveObjectDefinitionFail,
    saveObjectDefinitionSuccess,
    updateObjectDefinitionName,
} from '../actions/entities/objectDefinition.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { closeDialog, openDialog } from '../actions/dialogs.actions';
import { handleMoveToDirect, updateGraph } from './tree.saga';
import { getActiveTabs } from '../selectors/navigator.selectors';
import { objectDecompositionIconClicked } from '../actions/entities/objectDecomposition.actions';
import { CLICK_DECOMPOSITION_ICON_EVENT } from '../mxgraph/overlays/CellOverlayManager.constants';
import { symbolService } from '../services/SymbolsService';
import { BPMMxDragSource } from '../mxgraph/util/BPMMxDragSource';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { SymbolSelectors } from '../selectors/symbol.selectors';
import { PictureSymbolConstants } from '../models/pictureSymbolConstants';
import { DefaultGraph } from '../mxgraph/DefaultGraph';
import { BPMMxPopupMenuHandler } from '../mxgraph/BPMGraphClasses';
import { PickOut } from '../models/pick-out';
import { TreeItemType } from '../modules/Tree/models/tree';
import { MoveTo } from '../models/move-to';
import { SymbolType } from '../models/Symbols.constants';
import { unlock } from '../actions/lock.actions';
import { BpmNodeFilterBase } from '../models/bpmNodeFilterBase';
import { ObjectDefinitionSelectors } from '../selectors/objectDefinition.selectors';
import { getStore } from '../store';
import { KeyCodes } from '../utils/keys';
import { BPMMxGraphModel } from '../mxgraph/BPMMxGraphModel.class';
import { CustomMap } from '../utils/map';
import { COMMENTS_PANEL_FOCUS } from '../actionsTypes/comments.actionTypes';
import { updateAllCellsOverlays, updateCellsOverlays } from '../actions/overlay.actions';
import { getActiveModelContext, modelContextByGraphId } from './utils';
import { bindHotKeysOnMxGraph } from '../utils/hotKeys.utils';
import { getActiveGraph, getSelectedCellIds } from '../selectors/editor.selectors';
import {
    navigatorPropertiesChangeObjectAction,
    navigatorClearProperties,
} from '../actions/navigatorProperties.actions';
import { reloadModel } from '../actions/loadModel.actions';
import { objectDefinitionService } from '../services/ObjectDefinitionService';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import editorMessages from '../modules/AdminTools/messages/editor.messages';
import { UserProfileSelectors } from '../selectors/userProfile.selectors';
import { TSelectedElement } from '../models/navigatorPropertiesSelectorState.types';
import { compareSize } from '../mxgraph/util/BPMMxDragSource.utils';
import { svgService } from '../services/SvgServices';
import { availableAlignments, CustomMxEvent } from './editor.saga.constants';
import { ComplexSymbolManager } from '../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { BPMMxKeyHandler } from '../mxgraph/handler/BPMMxKeyHandler';
import { ProfileBllService } from '../services/bll/ProfileBllService';
import { getNewEdgeStyle, getShapeId, getShapeType } from '../utils/css.utils';
import isMouseEvent = MxEvent.isMouseEvent;
import isLeftMouseButton = MxEvent.isLeftMouseButton;
import isPopupTrigger = MxEvent.isPopupTrigger;
import { ObjectTypeSelectors, objectTypeStateSelector } from '../selectors/objectType.selectors';
import { getObjectTypeBySymbol } from './createDiagramElement.saga';
import { ObjectDefinitionsDAOService } from '../services/dao/ObjectDefinitionsDAOService';
import { OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT } from '../actionsTypes/entities/objectDecomposition.actionTypes';
import { DragModelBll } from '../services/bll/DragModelBll';
import { viewCellsActive } from '../services/bll/SearchByModelBllService';
import { FolderTypeSelectors } from '../selectors/folderType.selectors';
import { AvailableConnectionForSymbolsBLLService } from '../services/bll/AvailableConnectionForSymbolsBLLService';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { getIsDefaultFolder, getIsObjectTypeByIdAllowedInFolder } from '../services/bll/FolderTypeBLLService';
import { isImageFileNode } from '../utils/files.utils';
import { MoveElementBLL } from '../services/bll/MoveElementBLL';
import { StatisticsSelectors } from '../selectors/statistics.selectors';
import { batchActions } from '../actions/rootReducer.actions';
import { NAVIGATOR_STRUCTURE } from '../utils/consts';
import { getCellsWithChildren, getCopyableCells } from '../services/bll/BpmMxEditorBLLService';
import { EdgeDefinitionSelectors } from '../selectors/edgeDefinition.selector';
import {
    edgeDefinitionsAdd,
    initEdgeDefinitionCreation,
    updateEdgeDefinitionName,
} from '../actions/entities/edgeDefinition.actions';
import { TNavigatorTab } from '../reducers/navigator.reducer.types';
import { navigatorTabSelect } from '../actions/navigator.actions';
import { getSymbolsFromModelType } from '../utils/symbol.utils';
import { EdgeDefinitionDAOService } from '../services/dao/EdgeDefinitionDAOService';
import { EdgeTypeSelectors } from '../selectors/edgeType.selectors';
import { DialogsSelectors } from '../selectors/dialogs.selectors';
import { createCells, getExits } from '@/mxgraph/util/BpmMxEditorUtils';
import { EDGE_MANAGEMENT_DIALOG_SUBMIT } from '@/actionsTypes/edgeManagement.actionTypes';
import {
    drawObject,
    drawEdges,
    createEdges,
    deleteAllCellEdgesWithDefinition,
    getPositionedElements,
    getElementsWithCopiedData,
    getEdgeTerminals,
} from './editor.saga.utils';
import { getDefaultAttributeValue } from '../modules/AdminTools/Methodology/components/Presets/AttributeTypesTab/util/attributeTypeEditorDialog.utils';
import { SelectedStrategy } from '../models/selectObjectDialog.types';
import { WorkSpaceTabTypes } from '@/modules/Workspace/WorkSpaceTabTypesEnum';
import { deleteCommentMarker, saveComment } from '../actions/comments.actions';
import { LifelineSymbolMainClass } from '@/mxgraph/ComplexSymbols/symbols/LifeLine/LifelineSymbolMain.class';
import { scrollIntoView } from '../utils/scrollIntoView';
import { IComplexSymbol } from '../mxgraph/ComplexSymbols/symbols/ComplexSymbol.class.types';
import SequenceUtils from '../mxgraph/ComplexSymbols/symbols/Sequence/sequence.utils';
import { getCellsForSelect, getSelectedAndUnSelectedCellsByPickOut } from '../services/bll/selectCellsBLLService';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { clearContextMenuParentId } from '@/actions/contextMenu.actions';
import { EdgesForChangeSelectors } from '../selectors/edgesForChangeType.selectors';
import { getShowNotificationActions } from '../utils/edgesForChangeType.utils';
import { uniqBy } from 'lodash-es';
import { ToolbarButtonsBllService } from '@/services/bll/ToolbarButtonsBllService';
import { objectPropertyView } from '@/actions/objectProperty.actions';
import { ObjectPropertiesDialogActiveTab } from '@/models/objectPropertiesDialog';
import { viewModelProperties } from '@/actions/modelProperty.actions';
import { TSelectedCellElements } from '@/services/bll/ToolbarButtonsBllService.types';
import { matrixSaveFromStoreRequest, refreshMatrix } from '@/modules/Matrix/actions/matrix.actions';
import { MatrixSelectors } from '@/modules/Matrix/selectors/matrix.selectors';
import { MatrixDaoService } from '@/modules/Matrix/dao/MatrixDaoService';
import { matrixClearSelectedCells } from '@/modules/Matrix/actions/matrixEditor.actions';
import { ReportDaoService } from '@/modules/Report/dao/ReportDaoService';
import { ReportSelectors } from '@/modules/Report/selectors/report.selectors';
import { reportClearSelectColumn, reportSaveRequest } from '@/modules/Report/actions/report.actions';

const editorEventChannel: CustomMap<NodeId, Task> = new CustomMap();
const editorState: { [id: string]: TEditorState } = {};
const MOVE_CELL_STEP = 1;
const DEFAULT_IMAGE_DIMENSION = 300;

type TEditorState = {
    type: EditorMode;
    reset: () => void;
};

type TDrawObjectForModelMove = {
    draggedModelNode: TreeNode;
    symbol: Symbol;
    shouldCreateObjectDefinition: boolean;
    serverUrl?: string;
    instance?: ObjectDefinitionImpl;
};

type TWorkspaceTabContent = IMatrixNode | IModelNode | IWikiNode | undefined;

type TActionObject = {
    fn: (...args: any[]) => TAction;
    args: any[];
};

function getModelMoveDropHandler(
    shouldCreateObjectDefinition: boolean,
    hasDraggedModelDecomposition: boolean | undefined,
    objectDefinition: ObjectDefinitionImpl,
    content: TWorkspaceTabContent,
    symbol: Symbol,
) {
    const dropHandler = (graph: BPMMxGraph, event: PointerEvent, target: MxCell, point: MxPoint) => {
        if (isMouseEvent(event) && isLeftMouseButton(event)) {
            if (shouldCreateObjectDefinition) {
                objectDefinition = objectDefinitionService().createObjectDefinition(graph.bpmMxGraphContext.serverId, {
                    ...objectDefinition,
                    parentNodeId: content?.parentNodeId,
                    idSymbol: symbol?.id || '',
                } as ObjectDefinitionImpl);
            }

            if (shouldCreateObjectDefinition || (!shouldCreateObjectDefinition && !hasDraggedModelDecomposition)) {
                getStore().dispatch(saveObjectDefinition(graph.bpmMxGraphContext.serverId, objectDefinition));
            }

            const cell = drawObject({
                graph,
                target,
                point,
                symbol,
                objectDefinitions: [objectDefinition],
            });

            if (cell) {
                getStore().dispatch(updateCellsOverlays({ graphId: graph.id, cells: [cell] }));
            }
        }
    };

    return dropHandler;
}

function* checkModelType(modelId: NodeId) {
    const modelNode: ModelNode = yield select(ModelSelectors.byId(modelId));

    if (!modelNode || !modelNode.modelTypeId) {
        return false;
    }

    const presetId: string = yield select(TreeSelectors.presetById(modelId));
    const modelType: ModelType = yield select(
        ModelTypeSelectors.byId(
            {
                modelTypeId: modelNode.modelTypeId!,
                serverId: modelId.serverId,
            },
            presetId,
        ),
    );

    return !!modelType;
}

function* handleModeChanged({ payload: { mode } }: TEditorModeChangedAction) {
    const activeModelContext: IModelContext = yield getActiveModelContext();
    const tabs: TWorkspaceTab[] = yield select(TabsSelectors.getTabList);
    const presetId: string = yield select(TreeSelectors.presetById(activeModelContext?.graph?.id));

    for (const tab of tabs) {
        if (tab.nodeId.id === presetId && tab.nodeId.serverId === activeModelContext?.graph?.id.serverId) {
            const intl = LocalesService.useIntl(yield select(getCurrentLocale));

            notification.warning({
                message: intl.formatMessage(AppNotificationsMessages.concurrentEditOfNotationAndModelWarn),
                description: intl.formatMessage(AppNotificationsMessages.concurrentEditOfModelWarnDescription),
                duration: LONG_NOTIFICATION_DURATION,
            });

            return;
        }
    }

    if (!activeModelContext?.schema) {
        return;
    }

    switch (activeModelContext.schema.type) {
        case WorkSpaceTabTypes.EDITOR:
            if (activeModelContext.graph && activeModelContext.graph instanceof DefaultGraph) {
                // Завершаем редактирование имени у текстового блока и символа
                activeModelContext.graph.stopEditing(false);

                const graphId: NodeId = activeModelContext.graph.id;
                const modelTypeIsFinded = yield checkModelType(graphId);

                if (!modelTypeIsFinded) {
                    yield put(showNotificationByType(NotificationType.DIAGRAM_LOCK_EMPTY_MODEL_TYPE));

                    return;
                }

                if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
                    const lock: LockInfoDTO = yield modelService().lockModel(graphId);

                    viewCellsActive(activeModelContext.graph);

                    if (lock.locked) {
                        yield put(
                            showNotification({
                                id: uuid(),
                                type: NotificationType.DIAGRAM_LOCK_ERROR,
                                data: {
                                    lockOwner: lock.ownerName,
                                } as IDiagramLockError,
                            }),
                        );

                        return;
                    }
                } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
                    yield put(
                        saveModel({
                            graphId,
                            forceSave: true,
                            forceSaveHistory: true,
                            showNotification: true,
                            attempts: 0,
                        }),
                    );
                    yield put(unlock(graphId, 'MODEL'));
                }

                activeModelContext.graph.setMode(mode);

                yield put(
                    workspaceTabSetParams(graphId, {
                        nodeFilterBase: BpmNodeFilterBase.Off,
                        nodeFilterInput: false,
                        nodeFilterOutput: false,
                    }),
                );
                yield put(editorModeChangedAction(mode));
            }
            break;

        case WorkSpaceTabTypes.MARTIX_EDITOR:
            const graphId = activeModelContext.schema.nodeId;
            const isMatrixAutofill = yield select(MatrixSelectors.isMatrixAutofilled(graphId));
            if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
                // todo: 1647
                const lock: LockInfoDTO = yield MatrixDaoService.lockMatrix(graphId);
                if (lock.locked) {
                    yield put(
                        showNotification({
                            id: uuid(),
                            type: NotificationType.DIAGRAM_LOCK_ERROR,
                            data: {
                                lockOwner: lock.ownerName,
                            } as IDiagramLockError,
                        }),
                    );

                    return;
                }

                if (isMatrixAutofill) {
                    yield put(refreshMatrix(graphId));
                }
            } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
                if (isMatrixAutofill) {
                    yield put(refreshMatrix(graphId));
                } else {
                    yield put(matrixSaveFromStoreRequest(graphId));
                }
                yield put(unlock(graphId, 'MATRIX'));
                yield put(matrixClearSelectedCells(graphId));
            }
            yield put(editorModeChangedAction(mode));
            break;

        case WorkSpaceTabTypes.REPORT_EDITOR: {
            const graphId = activeModelContext.schema.nodeId;
            if (mode === EditorMode.Edit && activeModelContext.schema.mode !== EditorMode.Edit) {
                const lock: LockInfoDTO = yield ReportDaoService.lockReport(graphId);
                if (lock.locked) {
                    yield put(
                        showNotification({
                            id: uuid(),
                            type: NotificationType.DIAGRAM_LOCK_ERROR,
                            data: {
                                lockOwner: lock.ownerName,
                            } as IDiagramLockError,
                        }),
                    );

                    return;
                }
            } else if (mode !== EditorMode.Edit && activeModelContext.schema.mode === EditorMode.Edit) {
                const report: ReportNode | undefined = yield select(ReportSelectors.byId(graphId));
                if (report) {
                    yield put(reportSaveRequest(report));
                    yield put(reportClearSelectColumn(graphId));
                }
                yield put(unlock(graphId, 'REPORT'));
            }
            yield put(editorModeChangedAction(mode));
            break;
        }

        case WorkSpaceTabTypes.DASHBOARD:
            yield put(editorModeChangedAction(mode));
            break;
    }
}

function* handleCellColorPicked({ payload: { color } }: TEditorCellColorPicked) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;

    graph.getModel().beginUpdate();

    try {
        const cells = graph
            .getSelectionCells()
            .filter(
                (cell) =>
                    ComplexSymbolManager.isCellStyleEditable(cell) && cell.getValue()?.type !== SymbolType.COMMENT,
            )
            .map((cell: MxCell) => ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell);

        graph.setCellStyles(MxConstants.STYLE_FILLCOLOR, color, cells);
    } finally {
        graph.getModel().endUpdate();
    }
}

const getAttributes = (graphId: NodeId, value?: ObjectInstanceImpl | EdgeInstanceImpl): AttributeType[] => {
    const state = getStore().getState();
    const presetId: string = TreeSelectors.presetById(graphId)(state);

    if (value && value instanceof EdgeInstanceImpl) {
        const edgeType: EdgeType | undefined = EdgeTypeSelectors.byId({
            edgeTypeId: value.edgeTypeId,
            presetId,
            serverId: graphId.serverId,
        })(state);

        return edgeType?.diagramElementAttributes || [];
    }

    if (value?.type === 'object') {
        const objectDefinitionImpl: ObjectDefinitionImpl | undefined =
            objectDefinitionService().getObjectDefinitionByInstance(value, graphId);
        const objectType: ObjectType | undefined = ObjectTypeSelectors.byId({
            objectTypeId: objectDefinitionImpl?.objectTypeId || '',
            presetId,
            serverId: graphId.serverId,
        })(state);

        return objectType?.diagramElementAttributes || [];
    }

    return [];
};

function* handleCellAdded({ payload: { cellId } }: TEditorCellAdded) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;
    const cell: MxCell = graph.getModel().getCell(cellId);

    const value = cell.getValue();

    if (value?.type === 'object') {
        graph.handleCellIntersection(cell);
    }

    if (value?.type === 'object' || value?.type === 'edge') {
        const attributeTypes = getAttributes(graph.id, value);
        // добавляем атрибуты у которых есть флаг Создавать автоматически
        const createDefaultAttrs: AttributeValue[] = attributeTypes
            .filter((attrType) => !value.attributes?.some((a) => a.typeId === attrType.id) && attrType.createWithNode)
            .map((attrType: AttributeType) => getDefaultAttributeValue(attrType));
        value.attributes = value.attributes || [];
        value.attributes.push(...createDefaultAttrs);
    }

    if (value instanceof EdgeInstanceImpl) {
        const presetId: string = yield select(TreeSelectors.presetById(graph.id));
        const edgeType: EdgeType | undefined = yield select(
            EdgeTypeSelectors.byId({ edgeTypeId: value.edgeTypeId, presetId, serverId: graph.id.serverId }),
        );

        const loadedModel: ModelNode = yield select(ModelSelectors.byId(graph.id));
        const isEdgeCreatedBeforeLastLoading: boolean = !!loadedModel.elements?.find(
            (element) => element.id === cell.id,
        );

        if (edgeType?.alwaysCreateDefinition && !value.edgeDefinitionId && !isEdgeCreatedBeforeLastLoading) {
            yield put(initEdgeDefinitionCreation({ cell, graphId: graph.id }));
        }

        const selectedSource = graph.getSelectionModel().isSelected(cell.source);
        const selectedTarget = graph.getSelectionModel().isSelected(cell.target);

        if (selectedSource || selectedTarget) {
            const selectedCell = (selectedSource && cell.source) || (selectedTarget && cell.target);

            const navState: TSelectedElement = getLastSelectionObjectDefinitionForEvent([selectedCell], graph);
            yield put(navigatorPropertiesChangeObjectAction(navState));
        }
        yield put(updateCellsOverlays({ cells: [cell], graphId: graph.id }));
    }
}

function* watchGraphEvents(graph: BPMMxGraph, id: NodeId) {
    const keyHandler = new BPMMxKeyHandler(graph, document.querySelector('body'));

    graph.setKeyHandler(keyHandler);

    keyHandler.getFunction = function (evt: KeyboardEvent) {
        if (evt != null) {
            const isControlDown: boolean = MxEvent.isControlDown(evt) || (MxClient.IS_MAC && evt.metaKey);
            const isAltDown: boolean = MxEvent.isAltDown(evt);
            // eslint-disable-next-line react/no-this-in-sfc
            if (isControlDown && isAltDown) {
                return this.altControlKeys[evt.keyCode];
            }
            // eslint-disable-next-line react/no-this-in-sfc
            if (isControlDown) {
                return this.controlKeys[evt.keyCode];
            }

            // eslint-disable-next-line react/no-this-in-sfc
            return this.normalKeys[evt.keyCode];
        }

        return null;
    };

    const channel = eventChannel<TAction>((emitter) => {
        graph.getSelectionModel().addListener(MxEvent.CHANGE, (sender: MxGraphSelectionModel) => {
            const actions: TActionObject[] = [];

            /**
             * если при написании комментария, изменении имени элемента в дереве и т.п. (когда курсор находится в текстовом поле)
             * кликнуть на какой либо объект на холсте, то в фокусе должен находиться только этот объект на холсте.
             * Этот код снимает фокус с текстового поля (BPM-7523)
             */
            if (
                document.activeElement instanceof HTMLElement &&
                !document.activeElement.classList.contains('mxCellEditor')
            ) {
                document.activeElement.blur();
            }

            const mainCells: MxCell[] = uniqBy(
                sender.cells.map((cell) => ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell),
                'id',
            );
            const styleEditableCells: MxCell[] = mainCells.map(ComplexSymbolManager.getCellForEdit);

            if (mainCells.length > 1) {
                actions.push({
                    fn: navigatorClearProperties,
                    args: [],
                });
            } else if (mainCells.length === 1) {
                const selectedElement: TSelectedElement = getLastSelectionObjectDefinitionForEvent(
                    mainCells,
                    <BPMMxGraph>sender.graph,
                );

                emitter(navigatorPropertiesChangeObjectAction(selectedElement));
                emitter(editorSingleCellSelected());
            } else {
                viewCellsActive(graph);
                emitter(navigatorPropertiesChangeObjectAction({}));
            }

            emitter(editorSelectionModelChange({ nodeId: id, cellsIds: mainCells.map((cell) => cell.id) }));

            actions.push({
                fn: generalMenuToggleStyleButtonsAction,
                args: [handleSelectedEvent(styleEditableCells, graph)],
            });

            actions.push({
                fn: generalMenuUpdateAvailableEdgeTypesAction,
                args: [getAvailableEdgeTypesForEvent(mainCells, <BPMMxGraph>sender.graph)],
            });

            graph.setAllowDanglingEdges(false);
            graph.setDisconnectOnMove(false);

            emitter(batchActions([...actions.map((action) => action.fn(...action.args))]));
        });

        graph.addListener(MxEvent.CLICK, (sender: BPMMxGraph, event: MxEventObject) => {
            emitter(clearContextMenuParentId());
            const cell: MxCell | undefined =
                ComplexSymbolManager.getComplexSymbolRootCell(event.getProperty('cell')) || event.getProperty('cell');

            if (sender.selectionModel.cells.length === 0) {
                viewCellsActive(graph);
                emitter(navigatorPropertiesChangeObjectAction({}));
            }

            if (cell) {
                graph.nodeFilter.filtrateByCell(cell);

                const comment: Comment | null = cell.value?.comment;

                scrollIntoView(comment?.commentId.id);
                event.consume();
            } else {
                graph.nodeFilter.resetFiltration();
            }
        });

        let prevAddedCellsIds: string[] = [];

        graph.addListener(MxEvent.ADD_CELLS, (sender, event: MxEventObject) => {
            if (graph.isRefreshing()) {
                return;
            }
            const cells: MxCell[] =
                (event.getProperty('cells') as MxCell[]).map(
                    (cell: MxCell) => ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell,
                ) || [];

            cells.forEach((cell: MxCell) => {
                if (cell && !prevAddedCellsIds.includes(cell.id)) {
                    prevAddedCellsIds.push(cell.id);

                    emitter(editorCellAddedAction(graph.id, cell.id));
                }
            });
        });

        graph.getModel().addListener(CustomMxEvent.GRAPH_SYMBOL_ADDED, (sender, event: MxEventObject) => {
            const cellsIds = event.getProperty('cellsIds') || [];

            if (cellsIds.length) {
                const cell: MxCell = graph.getModel().getCell(cellsIds[0]);
                const complexSymbol = ComplexSymbolManager.getComplexSymbolInstance(cell);

                if (complexSymbol && ComplexSymbolManager.isCellEditable(cell)) {
                    emitter(setFocusAndStartEditAction(complexSymbol.getRootCell()?.getId()));
                }
            }

            prevAddedCellsIds = [];
        });

        graph.addListener(MxEvent.LABEL_CHANGED, (sender: MxGraph, event: MxEventObject) => {
            const cell: MxCell =
                ComplexSymbolManager.getComplexSymbolRootCell(event.getProperty('cell')) || event.getProperty('cell');

            emitter(editorLabelChangedAction(graph.id, cell.id, event.getProperty('value')));
        });

        graph.addListener(CLICK_DECOMPOSITION_ICON_EVENT, (sender: MxGraph, event: MxEventObject) => {
            let cellId: string = event.getProperty('cellId');
            const cell = graph.getModel().getCell(cellId);

            if (!cell) {
                // todo если элемент получен через вырезать вставить то у него слушатель не с тем id
                // todo все еще не исправлен баг(скопировать обьект с декомпозицией, удалить исходный обьект,
                //      и попробовать кликнуть на иконку декомпозиции у клона)
                return;
            }

            cellId = (ComplexSymbolManager.getComplexSymbolRootCell(cell) || cell).id;

            const params: TPayloadObjectDecompositionIconClicked = {
                objectDefinitionId: event.getProperty('objectDefinitionId'),
                edgeDefinitionId: event.getProperty('edgeDefinitionId'),
                graphId: event.getProperty('graphId'),
            };

            emitter(objectDecompositionIconClicked(params));
        });

        graph.addListener(MxEvent.ESCAPE, () => {
            emitter(escapeAction());
        });

        graph.addListener(MxEvent.DOUBLE_CLICK, (sender, event: MxEventObject) => {
            const cell: MxCell | undefined =
                ComplexSymbolManager.getCellForRename(event.getProperty('cell')) || event.getProperty('cell');

            if (!cell) {
                return;
            }

            const complexSymbol: IComplexSymbol | null = ComplexSymbolManager.getComplexSymbolInstance(cell);
            const { offsetX, offsetY } = event.getProperty('event') as MouseEvent;
            const isSequenceStartEdgeRunning: boolean = SequenceUtils.isStartEdgeRunning(
                cell,
                (complexSymbol as LifelineSymbolMainClass)?.headerSize,
                offsetX,
                offsetY,
            );
            const isStartEdgeRunning: boolean = isSequenceStartEdgeRunning;

            if (graph.mode === EditorMode.Edit) {
                if (isStartEdgeRunning) {
                    const state = getStore().getState();
                    const availableTypes = graph?.modelType?.edgeTypes || [];
                    const lastEdgeType = StatisticsSelectors.getLastUsedEdgeType(availableTypes)(state);

                    (complexSymbol as LifelineSymbolMainClass)?.startConnection(cell, lastEdgeType, availableTypes);
                    event.consume();
                } else if (complexSymbol?.isUseRenameDialog) {
                    emitter(openDialog(DialogType.RENAME_OBJECT_DIALOG, { graph, cell }));
                    event.consume();
                }
            }
        });

        graph.getModel().addListener(CustomMxEvent.CHANGE_COMMENTS_POSITION, (sender, event: MxEventObject) => {
            const comments: Comment[] = event.getProperty('comments');

            comments.forEach((comment) => emitter(saveComment(comment)));
        });

        graph.getModel().addListener(CustomMxEvent.UPDATE_CELLS_OVERLAYS, (sender, event: MxEventObject) => {
            const cells: MxCell[] = event.getProperty('cells');
            emitter(updateCellsOverlays({ graphId: graph.id, cells }));
        });

        graph.getModel().addListener(MxEvent.END_UPDATE, (sender, event: MxEventObject) => {
            const edit = event.getProperty('edit');

            if (edit && edit.changes && edit.changes.length) {
                graph.setDirty(true);
            }
        });

        graph.getModel().addListener(CustomMxEvent.CHANGE_EDGE_TYPE, (sender, event: MxEventObject) => {
            const edgeTypeId: string = event.getProperty('edgeTypeId');
            emitter(changeEdgeType(edgeTypeId));
        });

        // function for binding hot keys
        bindHotKeysOnMxGraph(keyHandler, emitter);

        return () => {
            keyHandler.destroy();
        };
    }, buffers.expanding(5));

    try {
        while (true) {
            const action = yield take(channel);

            yield put(action);
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* handleEditorInitRequest({ payload: { nodeId } }: TEditorInitAction) {
    const modelContext: IModelContext = yield modelContextByGraphId(nodeId);

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;

    MxEvent.addListener(graph.container, 'mousedown', () => {
        graph.container.focus();
    });
    graph.setTooltips(false);
    window.setTimeout(() => graph && graph.container && graph.container.focus());

    const keyHandlerWatcher = yield fork(watchGraphEvents, graph, nodeId);
    editorEventChannel.set(nodeId, keyHandlerWatcher);

    graph.addMouseListener(new ConnectionMouseListener(graph));
    graph.addMouseListener({
        mouseDown: MxUtils.bind(window, () => undefined),
        mouseMove: MxUtils.bind(window, (sender: BPMMxGraph, event: MxMouseEvent) => {
            graph.setMouseMovePoint(event.getEvent());
            // TODO: find other way, it's unfocused label text
            // graph.container.focus();
        }),
        mouseUp: MxUtils.bind(window, () => undefined),
    });

    graph.sizeDidChange();

    if (graph.psdDiagramHandler) {
        graph.psdDiagramHandler.init();
    }
}

function handleEditorDestroy({ payload: { nodeId } }: TEditorDestroyAction) {
    try {
        const channel: Task<any> | undefined = editorEventChannel.get(nodeId);
        if (channel) {
            channel.cancel();
            editorEventChannel.delete(nodeId);
        } else {
            console.warn(`editorEventChannel doesn't exists: ${nodeId.toString()}`);
        }
    } catch (e) {
        console.warn('editor destory failure: ', e);
    }
}

function* handleAlign({ payload: { alignment } }: TEditorAlignAction) {
    const availableAlignment: string | undefined = availableAlignments[alignment];

    if (availableAlignment) {
        const activeGraphId = yield select(getActiveGraph);
        const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

        if (!graph) {
            return;
        }

        const selectionCells = graph.getSelectionCells();
        const selectionCellsWithoutLabels = selectionCells.filter((cell) => cell.value.type !== 'label');

        graph.alignCells(availableAlignment, selectionCellsWithoutLabels, null);
    }
}

function* handleDistribute({ payload: { isHorizontal } }: TEditorDistributeAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    graph.distributeCells(isHorizontal);
}

function* handlePickOut({ payload: { pickOut } }: TEditorPickOutAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    const selectedCells: MxCell[] = graph.getSelectionCells();
    const cellsForSelect: MxCell[] = getCellsForSelect(graph);
    let allObDef: ObjectDefinitionImpl[] = [];

    if (pickOut === PickOut.PickOutByObjectType) {
        allObDef = objectDefinitionService().findAllObjectsInGraph(graph.id);
    }

    const { cellsToSelect, cellsToUnSelect } = getSelectedAndUnSelectedCellsByPickOut(
        cellsForSelect,
        selectedCells,
        allObDef,
        pickOut,
    );

    if (cellsToSelect?.length) {
        graph.addSelectionCells(cellsToSelect);
    }
    if (cellsToUnSelect?.length) {
        graph.removeSelectionCells(cellsToUnSelect);
    }
}

function* handleMoveTo({ payload: { moveTo } }: TEditorMoveToAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);
    const name = NAVIGATOR_STRUCTURE;

    if (!graph) {
        return;
    }

    const activeTabs: TNavigatorTab[] = yield select(getActiveTabs);
    const isTreeTabActive = activeTabs.includes(TNavigatorTab.Tree);

    if (!isTreeTabActive) {
        yield put(navigatorTabSelect(TNavigatorTab.Tree));
    }

    const selectedCells = graph.getSelectionCells();
    const modelTreeNode: TreeNode = {
        hasChildren: false,
        nodeId: graph.id,
        name,
        type: TreeItemType.Model,
        countChildren: 0,
    };

    if (selectedCells.length === 0) {
        yield handleMoveToDirect(moveToDirectAction(modelTreeNode, NAVIGATOR_STRUCTURE)); // не выбран ни один елемент на холсте -> переходим к модели

        return;
    }
    const cellValue: DiagramElement | LabelSymbol | undefined = selectedCells[0]?.getValue();
    const cellType: DiagramElementTypeEnum | string | undefined = cellValue?.type;
    const isObjectLabel: boolean = cellType === 'label' && !!(cellValue as LabelSymbol).objectDefinitionId;

    if (cellType !== 'object' && cellType !== 'edge' && cellType !== 'shape' && !isObjectLabel) {
        yield handleMoveToDirect(moveToDirectAction(modelTreeNode, NAVIGATOR_STRUCTURE)); // выбран не объект, не связь, не картинка, ни название объекта -> переходим к модели

        return;
    }

    let edgeDefinitionId: string | undefined;
    let objectDefinitionId: string | undefined;
    let imageId: string | undefined;

    if (cellType === 'object') {
        objectDefinitionId = (cellValue as ObjectInstance).objectDefinitionId;
    } else if (cellType === 'edge') {
        edgeDefinitionId = (cellValue as EdgeInstance).edgeDefinitionId;
    } else if (cellType === 'shape') {
        imageId = (cellValue as ShapeInstance).imageId;
    } else if (cellType === 'label') {
        objectDefinitionId = (cellValue as LabelSymbol).objectDefinitionId;
    }

    const id: string | undefined = objectDefinitionId || imageId || edgeDefinitionId;

    if (moveTo === MoveTo.Decomposition) {
        const param: TPayloadObjectDecompositionIconClicked = {
            objectDefinitionId,
            edgeDefinitionId,
            graphId: graph.id,
        };
        yield put(objectDecompositionIconClicked(param));
    }
    if (moveTo === MoveTo.ObjectInTree && id) {
        const treeNode: TreeNode = {
            hasChildren: false,
            nodeId: { ...graph.id, id },
            name,
            type: edgeDefinitionId ? TreeItemType.EdgeDefinition : TreeItemType.ObjectDefinition,
            countChildren: 0,
        };
        yield handleMoveToDirect(moveToDirectAction(treeNode, NAVIGATOR_STRUCTURE));
    }
}

function* handleEditorUpdate() {
    const activeGraphId: NodeId = yield select(getActiveGraph);

    if (!activeGraphId) {
        return;
    }

    yield put(reloadModel(activeGraphId));
}

function* handleMoveLayer({ payload: { movelayer } }: TEditorMoveAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph || graph.isSelectionEmpty()) {
        return;
    }

    graph.complexSymbolManager.moveCellsLayer(movelayer);
}

function* handleCut() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);

    if (!activeGraphId) {
        return;
    }

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    if (checkIfElementsAreRestrictedForCut(graph)) return;

    yield put(copyAction());
    yield put(handleDeleteSelectedCellsFromActiveGraphAction());
}

function* handleCopy() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);

    if (!activeGraphId) {
        return;
    }

    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    if (graph instanceof BPMMxGraph) {
        graph.bpmMxGraphContext.objectDefinitionCopyPasteContext = {}; // clean up copy from tree
    }

    if (graph.modelType?.id === ModelTypes.MIND_MAP) {
        yield put(copyWhiteboardAction(graph as WhiteboardGraph));

        return;
    }

    const cellsForCopy = getCellsWithChildren(graph.getSelectionCells());
    const copyableCells = getCopyableCells(graph, cellsForCopy);

    if (!copyableCells.length) {
        return;
    }

    const diagramElements = modelService().getDiagramElements(copyableCells, graph);

    yield put(setRepositoryIdWhereCopiedFrom(activeGraphId.repositoryId, activeGraphId.serverId, TreeItemType.Model));
    yield put(setCopiedElementsAction(diagramElements));

    const model: ModelNode | undefined = yield select(ModelSelectors.byId(activeGraphId));

    if (!model?.printable) {
        return;
    }

    const server: TServerEntity = yield select(ServerSelectors.server(activeGraphId.serverId));
    const svg = yield svgService().getSvg(activeGraphId, model, server, true);

    svgService().svgToClipboard(svg);
}

function* copyDefinitions(elements: DiagramElement[], graph: BPMMxGraph) {
    const { content } = yield select(TabsSelectors.byId(graph.id));
    const { parentNodeId } = content;
    const copyedElements: DiagramElement[] = [];
    const sortedElements = [...elements].sort((elem1, elem2) => {
        return elem1.type !== CellTypes.Object && elem2.type === CellTypes.Object ? 1 : 0;
    });

    for (const elem of sortedElements) {
        if (elem.type === CellTypes.Object) {
            const instance = elem as ObjectInstance;
            const objectDef: ObjectDefinitionImpl | undefined = objectDefinitionService().getObjectDefinitionByInstance(
                instance,
                graph.id,
            );

            if (!objectDef) {
                continue;
            }

            const objectDefinition = objectDefinitionService().createObjectDefinition(
                graph.id.serverId,
                new ObjectDefinitionImpl({
                    ...objectDef,
                    modelAssignments: [...objectDef.modelAssignments] || [],
                    version: 0,
                    nodeId: { ...objectDef.nodeId, id: uuid() },
                    parentNodeId,
                }),
            );

            yield put(saveObjectDefinition(graph.bpmMxGraphContext.serverId, objectDefinition));
            yield race({
                success: take(SAVE_OBJECT_DEFINITION_SUCCESS),
                fail: take(SAVE_OBJECT_DEFINITION_FAIL),
            });

            copyedElements.push({ ...elem, objectDefinitionId: objectDefinition.nodeId.id } as DiagramElement);
        } else if (elem.type === CellTypes.Edge) {
            const instance = elem as EdgeInstance;
            const source: DiagramElement | undefined = copyedElements.find((elem) => elem.id === instance.source);
            const target: DiagramElement | undefined = copyedElements.find((elem) => elem.id === instance.target);

            if (!(source as ObjectInstance)?.objectDefinitionId || !(target as ObjectInstance)?.objectDefinitionId) {
                continue;
            }

            if (!instance.edgeDefinitionId) {
                copyedElements.push({ ...elem });

                continue;
            }

            const protoEdgeDefinition: EdgeDefinitionNode = yield select(
                EdgeDefinitionSelectors.byId({ ...graph.id, id: instance.edgeDefinitionId }),
            );
            const parentNodeId: NodeId | undefined = protoEdgeDefinition.parentNodeId
                ? { ...protoEdgeDefinition.parentNodeId, id: (source as ObjectInstance).objectDefinitionId || '' }
                : undefined;
            const modelAssignments: ModelAssignment[] = protoEdgeDefinition.modelAssignments
                ? [...protoEdgeDefinition.modelAssignments]
                : [];
            const attributes: AttributeValue[] = protoEdgeDefinition.attributes
                ? protoEdgeDefinition.attributes.map((attr) => {
                      return { ...attr, id: uuid() };
                  })
                : [];
            const edgeDefinition: EdgeDefinitionNode = yield EdgeDefinitionDAOService.createEdgeDefinition(
                graph.id.serverId,
                {
                    type: TreeItemType.EdgeDefinition,
                    nodeId: {
                        ...graph.id,
                        id: uuid(),
                    },
                    parentNodeId,
                    modelAssignments,
                    attributes,
                    name: instance.name || '',
                    multilingualName: instance.multilingualName,
                    edgeTypeId: instance.edgeTypeId,
                    sourceObjectDefinitionId: (source as ObjectInstance).objectDefinitionId,
                    targetObjectDefinitionId: (target as ObjectInstance).objectDefinitionId,
                },
            );

            yield put(edgeDefinitionsAdd([edgeDefinition]));
            yield put(treeItemAdd(<TreeNode>edgeDefinition));

            copyedElements.push({ ...elem, edgeDefinitionId: edgeDefinition.nodeId.id } as DiagramElement);
        } else {
            copyedElements.push({ ...elem });
        }
    }

    return copyedElements;
}

function* handleDropPastePreview({
    payload: { elements, dropPoint, pasteAsNewObjectDefinition, target },
}: TEditorDropPastePreviewAction) {
    const activeGraphId: NodeId = yield select(getActiveGraph);
    const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    if (!graph) return;

    const server: TServerEntity = yield select(ServerSelectors.server(activeGraphId.serverId));
    const model: BPMMxGraphModel = graph.getModel();

    model.beginUpdate();

    try {
        const positionedElements = getPositionedElements(elements, dropPoint, graph);
        const copiedElements = getElementsWithCopiedData(positionedElements, graph);
        const mapToTarget = (elements) => {
            const parentId = graph.getDefaultParent().getId();

            return elements.map((el) => ({
                ...el,
                parent: el.parent === parentId ? target : el.parent,
            }));
        };

        const preparedDiagramElements: DiagramElement[] = mapToTarget(
            pasteAsNewObjectDefinition ? yield copyDefinitions(copiedElements, graph) : copiedElements,
        );

        const newCells = createCells(graph, preparedDiagramElements, server.url);

        for (const cell of newCells) {
            if (cell.value?.type === 'object') {
                const addedEdges: MxCell[] = yield drawEdges(graph, cell);

                yield put(updateCellsOverlays({ graphId: graph.id, cells: [cell, ...addedEdges] }));
            }
        }
    } finally {
        model.endUpdate();
    }
}

function* handleSelectAll() {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    graph.getSelectionModel().setSingleSelection(false);

    /*  Workaround...
    https://github.com/redux-saga/redux-saga/issues/413
    https://github.com/redux-saga/redux-saga/issues/1130
    Doesn't dispatch action after getting it in eventChannel on change selection model
    */
    yield Promise.resolve();
    const cellsForSelect: MxCell[] = getCellsForSelect(graph);
    graph.addSelectionCells(cellsForSelect);
}

function* handleDelete(action: TEditorDeleteAction) {
    const { cellsForDelete, graphId, checkProfile, checkCellIsEdited } = action.payload;

    if (!graphId || !cellsForDelete) {
        return;
    }

    const graph = instancesBPMMxGraphMap.get(graphId);
    const profile: TCurrentUserProfile | undefined = yield select(
        UserProfileSelectors.selectUserProfileByNodeId(graphId),
    );

    // при редактировании имени ячейки можно нажать delete тогда должно очистится имя, но ячейка не удалится
    // но при редактировании имени ячейки можно удалить определение из дерева, тогда ячейка - экземпляр должна удалится тоже, несмотря на, редактирование
    const cellIsEdited = checkCellIsEdited && graph?.cellEditor?.isContentEditing();

    if (graph && !cellIsEdited) {
        const deletableCells = checkProfile
            ? ProfileBllService.filterCellsDelete(cellsForDelete, profile)
            : cellsForDelete;

        if (deletableCells.length < cellsForDelete.length) {
            yield put(showNotificationByType(NotificationType.ACCESS_DENIED_BY_PROFILE));

            return;
        }

        if (deletableCells.length) {
            const selectedCells = graph
                .getSelectionCells()
                ?.filter((cell) => !deletableCells?.some((deletableCell) => deletableCell.id === cell.id));

            const commentCells: MxCell[] = [];
            deletableCells.forEach((deletableCell) => {
                if (deletableCell.children) {
                    (deletableCell.children as MxCell[]).forEach((child) => {
                        if (isCommentCell(child)) commentCells.push(child);
                    });
                }
            });
            for (const commentCell of commentCells) {
                yield put(deleteCommentMarker((commentCell.value as CommentMarker).comment.commentId));
            }

            graph.getModel().beginUpdate();
            graph.setCellsDeletable(true);
            graph.removeCells(deletableCells, true);
            graph.getModel().endUpdate();

            const selectedElement: TSelectedElement = getLastSelectionObjectDefinitionForEvent(selectedCells, graph);

            yield put(navigatorPropertiesChangeObjectAction(selectedElement));
        }
    }
}

function* handleDeleteSelectedCellsFromActiveGraph() {
    const activeGraphId = yield select(getActiveGraph);
    const isVisibleDialog = yield select(DialogsSelectors.isVisibleDialog);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    /* BPM-6909
      isVisibleDialog - проверка нужна, чтобы при открытом окне свойств объекта,
      при нажатии клавиши 'DELETE', выделенный объект на холсте не удалялся
      P.s. вызов обработчика нажатия клавиши 'DELETE' происходит для каждого графа отдельно,
      и каждый обработчик вызывает эту сагу, которая удаляет выделенные элементы АКТИВНОГО графа,
      а не того, чей обработчик был вызван
    */
    if (graph && !graph.isSelectionEmpty() && !isVisibleDialog) {
        yield handleDelete(deleteAction(graph.getSelectionCells(), activeGraphId, true, true));
    }
}

function* handleLabelStyleChange({ payload: { action, isActive, value } }: TGeneralMenuLabelStyleChangeAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph || graph.isSelectionEmpty()) {
        return;
    }

    const cells = graph
        .getSelectionCells()
        .filter(
            (cell) => ComplexSymbolManager.isCellStyleEditable(cell) && cell.getValue()?.type !== SymbolType.COMMENT,
        )
        .map((cell: MxCell) => ComplexSymbolManager.getCellForEdit(cell));

    switch (action) {
        case LabelStyle.decorationStyle:
            if (isUndefined(isActive)) {
                const buttonEditLabelState = yield select(getGeneralMenuButtonsState);

                switch (value) {
                    case MxConstants.FONT_BOLD:
                        isActive = buttonEditLabelState.isFontBoldSelected;
                        break;
                    case MxConstants.FONT_ITALIC:
                        isActive = buttonEditLabelState.isFontItalicSelected;
                        break;
                    case MxConstants.FONT_UNDERLINE:
                        isActive = buttonEditLabelState.isFontUnderlineSelected;
                        break;
                    default:
                        isActive = false;
                }
            }

            applyFontStyle(cells, graph, value as number, !isActive);
            break;
        case LabelStyle.alignmentStyle:
            if (graph.cellEditor.isContentEditing()) {
                document.execCommand(`justify${value}`, false, undefined);
            }

            graph.setCellStyles(MxConstants.STYLE_ALIGN, value as string, cells);
            break;
        case LabelStyle.fontSize:
            graph.setCellStyles(MxConstants.STYLE_FONTSIZE, value as string, cells);

            if (graph.cellEditor.isContentEditing()) {
                document.execCommand('fontSize', false, '3');
                const elts = graph.cellEditor.textarea.getElementsByTagName('font');
                for (let i = 0; i < elts.length; i++) {
                    if (elts[i].getAttribute('size') === '3') {
                        elts[i].removeAttribute('size');
                        elts[i].style.fontSize = `${value}px`;
                        break;
                    }
                }
            }
            break;
        case LabelStyle.fontFamily:
            graph.setCellStyles(MxConstants.STYLE_FONTFAMILY, `${value}`, cells);

            if (graph.cellEditor.isContentEditing()) {
                document.execCommand('fontname', false, undefined);
            }
            break;
        case LabelStyle.textDirection:
            if (isActive) {
                graph.setCellStyles(MxConstants.STYLE_HORIZONTAL, null, cells);
            } else {
                graph.setCellStyles(MxConstants.STYLE_HORIZONTAL, 'false', cells);
            }
            break;
        default:
            return;
    }

    yield put(generalMenuToggleStyleButtonsAction(handleSelectedEvent(cells, graph)));
}

function* handleGrouping({ payload: { grouping } }: TEditorGropingAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    if (!graph.isSelectionEmpty()) {
        const arrayCells = graph.getSelectionCells();

        if (grouping === Grouping.Group && arrayCells.length > 1) {
            graph.groupCells(null, 0, arrayCells);
        } else if (grouping === Grouping.Ungroup && arrayCells.length > 1) {
            graph.ungroupCells(arrayCells);
        }
    }
}

function* handleEscape() {
    const activeGraphId = yield select(getActiveGraph);
    const state = editorState[activeGraphId];

    if (state && state.type === EditorMode.Edit) {
        state.reset();
    }
}

function* handleEdgeTypeChange(action: TEditorChangeEdgeType) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    // TODO: allow changing type for multiple edges at once
    const edges: MxCell[] = graph.getSelectionModel().cells.filter((c: MxCell) => c.edge === true);
    const { type: edgeTypeId } = action.payload;
    const edgeType: EdgeType | null = NotationHelper.getEdgeTypeByName(graph.modelType, edgeTypeId);

    if (edges.length && edgeType !== null) {
        const model: BPMMxGraphModel = graph.getModel();
        const { allowedEdges, deniedEdgesByProfile, edgesWithDefinition, edgesWithOnlyOneType }: TDividingEdges =
            yield select(EdgesForChangeSelectors.getEdgesForChangeType(edges.map((edge) => edge.getValue())));
        const allowedEdgeCells: MxCell[] = edges.filter((edge) =>
            allowedEdges.find((allowEdge) => allowEdge.id === edge.getValue().id),
        );

        model.beginUpdate();

        try {
            allowedEdgeCells.forEach((edge) => {
                const edgeValue: EdgeInstanceImpl = edge.getValue();

                // если текущиий тип связи такой же, то не меняем тип
                if (edgeValue.edgeTypeId !== edgeTypeId) {
                    edgeValue.edgeTypeId = edgeType.id;
                    const exits = getExits(graph, edge);
                    const style = graph.createEdgeStyle(edgeType, exits);

                    graph.setCellStyle(style, [edge]);
                }
            });
        } finally {
            model.endUpdate();

            const actions: TNotificationShowAction[] = getShowNotificationActions(
                deniedEdgesByProfile,
                edgesWithDefinition,
                edgesWithOnlyOneType,
                graph.modelType,
            );

            yield all(actions.map((action) => put(action)));
        }
    }
}

// eslint-disable-next-line require-yield
function* handleChangeEdgeMultilangName({ payload: { graphId, edgeId, name } }: TEditorChangeEdgeMultilingualName) {
    const graph = instancesBPMMxGraphMap.get(graphId);

    if (!graph) {
        return;
    }

    // TODO: allow changing type for multiple edges at once
    const edge = graph.getModel().getCell(edgeId);

    if (edge && name) {
        const model: BPMMxGraphModel = graph.getModel();

        model.beginUpdate();

        try {
            const edgeValue = edge.getValue();

            if (edgeValue instanceof EdgeInstanceImpl) {
                edgeValue.multilingualName = name;
            }
        } finally {
            model.endUpdate();
        }
    }
}

function* handleChangeEdgeStyle({ payload: { keys, values, cellId } }: TEditorChangeEdgeStyleAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    // TODO: allow changing type for multiple edges at once
    const edges = Object.values(graph.getModel().cells).filter((c: MxCell) => c.id === cellId);

    if (edges.length === 1) {
        const model = graph.getModel();

        model.beginUpdate();

        try {
            keys.forEach((key, index) => {
                graph.setCellStyles(key, values[index], edges);
            });
        } finally {
            model.endUpdate();
            // autosave
            // yield put(saveModel(activeGraphId));
        }
    }
}

function* handleChangeEdgeColor({ payload: { color } }: TEditorChangeEdgeColorAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    const edges = graph.getSelectionModel().cells.filter((c: MxCell) => c.edge === true);
    const edgeColor = 'strokeColor='.concat(color).concat(';');

    if (edges.length > 0) {
        const model = graph.getModel();
        const state = getStore().getState();
        const presetId: string = TreeSelectors.presetById(graph.id)(state);

        model.beginUpdate();

        try {
            edges.forEach((edge: MxCell) => {
                const edgeValue = edge.getValue();
                const edgeType: EdgeType | undefined = EdgeTypeSelectors.byId({
                    edgeTypeId: edgeValue.edgeTypeId,
                    presetId,
                    serverId: activeGraphId.serverId,
                })(state);

                graph.setCellStyle(
                    (edge.style || edgeType?.edgeStyle || '')
                        .replace(/strokeColor=#[a-zA-Z0-9_-]+;/g, '')
                        .concat(edgeColor),
                    [edge],
                );
            });
        } finally {
            model.endUpdate();
        }
    }
}

function* handleChangeFontColor({ payload: { color } }: TEditorChangeFontColorAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph || graph.isSelectionEmpty()) {
        return;
    }

    const cells = graph
        .getSelectionCells()
        .filter(
            (cell) => ComplexSymbolManager.isCellStyleEditable(cell) && cell.getValue()?.type !== SymbolType.COMMENT,
        )
        .map((cell: MxCell) => ComplexSymbolManager.getCellForEdit(cell));

    graph.setCellStyles(MxConstants.STYLE_FONTCOLOR, color as string, cells);
}

function* handleWorkspaceChanged() {
    try {
        const activeGraphId = yield select(getActiveGraph);
        const graph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
        if (graph) {
            const { cells } = graph.getSelectionModel();

            yield put(generalMenuUpdateAvailableEdgeTypesAction(getAvailableEdgeTypesForEvent(cells, graph)));
            yield put(generalMenuToggleStyleButtonsAction(handleSelectedEvent(cells, graph)));

            // обновление сложных символов, например, UML Класс
            // в updateGraph вызывается complexSymbolManager.refreshCells() не зависимо от переданного параметра
            yield call(updateGraph, undefined, undefined);
            yield put(updateAllCellsOverlays(graph.id));
        } else {
            yield put(generalMenuUpdateAvailableEdgeTypesAction({ availableTypes: [] }));
            yield put(generalMenuToggleStyleButtonsAction(new ButtonEditLabelState()));
        }
    } finally {
        yield all([]);
    }
}

function isDoLookupObject(objectType: ObjectType | undefined): boolean {
    return !objectType || objectType.alwaysCreateNew === undefined || objectType.alwaysCreateNew === false;
}

function* showObjectSelectDialog(
    objectDef: ObjectDefinitionImpl,
    cell: MxCell,
    modelContext: IModelContext,
    objectName: string,
    modelType?: ModelType,
    force?: boolean,
) {
    const objectType = modelType && modelType.objectTypes.filter((oType) => oType.id === objectDef.objectTypeId)[0];
    const exists = isDoLookupObject(objectType)
        ? yield objectDefinitionService().findByNameAndType(
              {
                  ...modelContext.nodeId,
                  id: objectDef.nodeId.id,
              },
              objectName,
              force,
          )
        : [];

    if (modelContext && modelContext.schema.content) {
        const currentSchemaRepository: TTreeEntityState = yield select(
            TreeSelectors.itemById({
                serverId: modelContext.schema.nodeId.serverId,
                id: modelContext.schema.nodeId.repositoryId,
                repositoryId: modelContext.schema.nodeId.repositoryId,
            }),
        );

        for (const object of exists) {
            const repository: TTreeEntityState = yield select(
                TreeSelectors.itemById({
                    serverId: modelContext.nodeId.serverId,
                    id: object.nodeId.repositoryId,
                    repositoryId: object.nodeId.repositoryId,
                }),
            );

            if (repository && currentSchemaRepository.nodeId.id === repository.nodeId.id) {
                yield put(
                    openDialog(DialogType.SELECT_OBJECT_DIALOG, { cellId: cell.id, instances: exists, objectName }),
                );

                return;
            }
        }
    }

    yield put(updateObjectDefinitionName(objectDef, objectName));
}

function* handleLabelChanged({ payload: { cellId, newLabel } }: TEditorLabelChanged) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const { nodeId, graph } = modelContext;
    const cell: MxCell = graph.getModel().getCell(cellId);

    if (cell) {
        const { value } = cell;

        if (value?.type === 'object') {
            const objectDef = yield select(
                ObjectDefinitionSelectors.byId({ ...nodeId, id: value.objectDefinitionId! }),
            );

            if (objectDef && newLabel) {
                yield call(updateGraph, objectDef, { ...objectDef, name: newLabel });
                yield call(showObjectSelectDialog, objectDef, cell, modelContext, newLabel, graph.modelType, true);
            }
        }

        if (value?.type === 'edge') {
            const edgeInstance = value as EdgeInstance;
            const edgeDefinition = yield select(
                EdgeDefinitionSelectors.byId({ ...nodeId, id: edgeInstance.edgeDefinitionId! }),
            );

            if (edgeDefinition && newLabel !== undefined) {
                yield putResolve(updateEdgeDefinitionName(nodeId.serverId, edgeDefinition, newLabel));
            }
        }
    }
}

function* handleSetFocusAndStartEdit({ payload: { cellId } }: TEditorSetFocusAndStartEditLabel) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!graph) {
        return;
    }

    const cell: MxCell = graph.getModel().getCell(cellId);
    const cellForEditing: MxCell = ComplexSymbolManager.getCellForEdit(cell);

    graph.selectCellForEvent(cellForEditing, null, true);

    if (ComplexSymbolManager.isCellEditable(cell) && graph.isCellEditable(cellForEditing)) {
        graph.escape(null);
        graph.startEditing(null);

        const focusedElement = graph.cellEditor.textarea;

        BPMMxGraph.postRender(() => {
            if (focusedElement) {
                focusedElement.focus();
            }

            if (document.activeElement?.getAttribute('contenteditable')) {
                // выделить всё если курсор внутри textarea. При двойном клике фокус с textarea спадает и выделяется весь интерфейс
                document.execCommand('selectAll', false, undefined);
            }
        });
    }
}

function* handleSetObjectToCell({ payload: { cellId, object, isDeletable } }: TEditorSetObjectToCell) {
    const modelContext: IModelContext | undefined = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const {
        nodeId: { serverId },
        graph,
    } = modelContext;

    const cell: MxCell | undefined = graph.getModel().getCell(cellId);

    if (!cell) {
        return;
    }

    const oldNodeId: NodeId = {
        ...object.nodeId,
        id: cell.getValue().objectDefinitionId || '',
    };

    graph.complexSymbolManager.setObjectToCell(cell, object);

    try {
        if (isDeletable) {
            yield put(
                treeItemDeleteNodeFromServer({
                    nodeId: oldNodeId,
                    nodeType: TreeItemType.ObjectDefinition,
                    nodeName: '',
                    countChildren: 0,
                    closeTabsNames: [],
                    closeTabsIds: [],
                    objectsMap: {},
                    edgesMap: {},
                }),
            );
        }
        // objectNodeId.id - текущий привязанный объект надо ли его запрашивать? но пусть будет если он есть то не запросится повторно
        // при переименовании и привязки к новому объекту может быть объект не загружен, загружаем его object.nodeId.id
        yield objectDefinitionService().loadObjects(serverId, oldNodeId.repositoryId, [oldNodeId.id, object.nodeId.id]);
        yield put(updateCellsOverlays({ graphId: graph.id, cells: [cell] }));
        // удаление всех старых связей объекта при смене его определения
        // но если у связи нет определения не удаляем ее,
        // это важно в случае когда пользователь работает без использование определений связи, если пользователь хочет переименовать объект,
        // то вероятно он не хочет потрять связи без определения, а вот связи с определением должны быть удалены т.к. для них первично определение в котором есть объект источник и назначения
        // https://jira.silaunion.ru/browse/BPM-7118
        yield deleteAllCellEdgesWithDefinition(graph, cell);

        const edgeCells: MxCell[] = yield drawEdges(graph, cell);

        if (edgeCells?.length) {
            yield put(updateCellsOverlays({ graphId: graph.id, cells: edgeCells }));
        }
    } finally {
        graph.refresh();
        graph.stopEditing(true);
    }
}

function* handleCreateNewObjectName({ payload: { cellId, object, newObjectName } }: TCreateNewObjectName) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;
    const newCell: MxCell = graph.getModel().getCell(cellId);
    const newObjectNodeId: NodeId = { ...object.nodeId, id: newCell.value.objectDefinitionId };
    const newObject: ObjectDefinitionImpl = yield select(ObjectDefinitionSelectors.byId(newObjectNodeId));

    yield put(updateObjectDefinitionName(newObject, newObjectName));
}

function* handleAddTableRow({ payload: { cellId } }: TEditorAddTableRowAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);

    if (!isUndefined(modelContext.graph.psdDiagramHandler)) {
        const index = modelContext.graph.psdDiagramHandler.findIndexForCell(cell, true);
        modelContext.graph.psdDiagramHandler.addRow(index + 1, false);
    }
}

function* handleRemoveTableRow({ payload: { cellId } }: TEditorRemoveTableRowAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);

    if (!isUndefined(modelContext.graph.psdDiagramHandler)) {
        const index = modelContext.graph.psdDiagramHandler.findIndexForCell(cell, true);
        modelContext.graph.psdDiagramHandler.removeRow(index);
    }

    yield put(closeDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG));
}

function* handleAddTableColumn({ payload: { cellId } }: TEditorAddTableColumnAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);

    if (!isUndefined(modelContext.graph.psdDiagramHandler)) {
        const index = modelContext.graph.psdDiagramHandler.findIndexForCell(cell, false);
        modelContext.graph.psdDiagramHandler.addColumn(index + 1, false);
    }
}

function* handleRemoveTableColumn({ payload: { cellId } }: TEditorRemoveTableColumnAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);

    if (!isUndefined(modelContext.graph.psdDiagramHandler)) {
        const index = modelContext.graph.psdDiagramHandler.findIndexForCell(cell, false);
        modelContext.graph.psdDiagramHandler.removeColumn(index);
    }

    yield put(closeDialog(DialogType.PSD_TABLE_DELETE_CONFIRMATION_DIALOG));
}

function* handleAddBpmnTableRow({ payload: { cellId } }: TEditorAddBpmnTableRowAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);
    const geo: MxGeometry = cell.getGeometry();
    modelContext.graph.bpmn2DiagramHandler.insertLaneSymbol(cell, uuid(), geo.x, geo.y + geo.height);
}

function* handleRemoveBpmnTableRow({ payload: { cellId } }: TEditorRemoveBpmnTableRowAction) {
    const modelContext: IModelContext = yield getActiveModelContext();

    if (!modelContext) {
        return;
    }

    const cell: MxCell = modelContext.graph.getModel().getCell(cellId);

    modelContext.graph.removeCells([cell], true);
    modelContext.graph.removeCells([cell], true);
}

function* handleEditorSingleCellSelected() {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!(activeGraph instanceof BPMMxGraph) || !activeGraph.bpmMxGraphContext.applyCellStyle) {
        return;
    }

    activeGraph.complexSymbolManager.formatSelectedCellByExample();
}

function* handleFormatByExample() {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!(activeGraph instanceof BPMMxGraph)) {
        return;
    }

    const cells: MxCell[] = activeGraph
        .getSelectionCells()
        .map((cell) => ComplexSymbolManager.getFormatExampleCell(cell));
    const selectedCellsWithoutImages: MxCell[] = ToolbarButtonsBllService.getSelectedCellsWithoutImages(cells);

    activeGraph.bpmMxGraphContext.applyCellStyle = true;
    activeGraph.bpmMxGraphContext.selectedCell = selectedCellsWithoutImages[0];
}

function* handleSetCellDefaultStyle(action: TEditorSetCellDefaultStyle) {
    const { cell } = action.payload;
    const cellValue = cell.getValue();
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) {
        return;
    }

    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) {
        return;
    }

    const model = activeGraph.getModel();
    const cellType: DiagramElementTypeEnum = cellValue?.type;

    if (cellType === 'edge') {
        model.beginUpdate();

        try {
            const edgeStyleInline: string = cell.style;
            const edgeInstance = <EdgeInstance>cellValue;
            const presetId: string = yield select(TreeSelectors.presetById(activeGraphId));
            const edgeType: EdgeType | undefined = yield select(
                EdgeTypeSelectors.byId({
                    edgeTypeId: edgeInstance.edgeTypeId,
                    presetId,
                    serverId: activeGraphId.serverId,
                }),
            );
            const edgeTypeStyle: string | undefined = edgeType?.edgeStyle;

            const newEdgeStyle: string | undefined = getNewEdgeStyle(edgeTypeStyle, edgeStyleInline);

            if (newEdgeStyle) {
                activeGraph.setCellStyle(newEdgeStyle, [cell]);
            }

            const edgeLabelGeometry = model.getGeometry(cell).clone();
            const resetedEdgeLabelGeometry = edgeLabelGeometry.clone();

            resetedEdgeLabelGeometry.x = 0;
            resetedEdgeLabelGeometry.y = 10;

            model.setGeometry(cell, resetedEdgeLabelGeometry);
        } finally {
            model.endUpdate();
        }
    }

    if (cellType === 'object') {
        const { objectDefinitionId } = <ObjectInstance>cellValue;

        if (!objectDefinitionId) {
            return;
        }

        model.beginUpdate();

        try {
            ComplexSymbolManager.getComplexSymbolInstance(cell)?.setDefaultStyle();
        } finally {
            model.endUpdate();
        }
    }

    if (cellType === 'shape') {
        const { id } = <ShapeInstance>cellValue;

        if (!id) {
            return;
        }

        const style = cell.getStyle();
        const shapeType = getShapeType(style);
        const shapelId = getShapeId(style);

        model.beginUpdate();

        try {
            const cellGeo = model.getGeometry(cell).clone();
            const width = shapeType === 'text' ? 70 : 75;
            const height = shapeType === 'text' ? 35 : 80;
            const defaultStyles = `${shapelId};rounded=1;`;

            if (width && height) {
                cellGeo.width = width;
                cellGeo.height = height;
            }

            if (shapeType === 'text') {
                activeGraph.setCellStyle(`${defaultStyles}strokeColor=none`, [cell]);
            } else {
                activeGraph.setCellStyle(`${defaultStyles}`, [cell]);
            }

            model.setGeometry(cell, cellGeo);
        } finally {
            model.endUpdate();
        }
    }
}

function* handleShapePaste({ payload: { symbol } }: TEditorDragShapeAction) {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);
    const intl = LocalesService.useIntl(yield select(getCurrentLocale));

    if (!activeGraph) {
        return;
    }

    const id = uuid();
    const dragSource = new BPMMxDragSource(
        symbol,
        (graph: BPMMxGraph, evt: PointerEvent, target: MxCell, point: MxPoint) => {
            if (isMouseEvent(evt) && isLeftMouseButton(evt)) {
                const shape: ShapeInstance = {
                    id,
                    type: SymbolType.SHAPE,
                    metaInfo: symbol.id === SymbolType.SHAPE ? intl.formatMessage(editorMessages.text) : '',
                };

                // TODO переместить в общий метод/сервис создания Shape
                const cell: MxCell = graph.insertVertex(
                    target,
                    id,
                    shape,
                    point.x,
                    point.y,
                    symbol.width!,
                    symbol.height!,
                    symbol.style!,
                );
                cell.edge = false;

                if (graph.modelType && graph.modelType.id !== ModelTypes.MIND_MAP) {
                    cell.setConnectable(false);
                }
            }
        },
        activeGraph,
    );

    dragSource.emulatePointerEvent();
}

function* handleDnDFileNode(action: TDropFileNodeAction) {
    const { nodeId } = action.payload;
    const activeGraphId = yield select(getActiveGraph);
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    if (!activeGraph) return;
    const symbol: Symbol = {
        id: PictureSymbolConstants.PICTURE_SYMBOL_ID,
        presetId: DefaultId.DEFAULT_PRESET_ID,
        graphical: `${nodeId?.repositoryId}/${nodeId?.id}`,
        name: PictureSymbolConstants.PICTURE_SYMBOL_NAME,
        description: '',
        icon: '',
        objectType: '',
        showLabel: true,
        color: PictureSymbolConstants.PICTURE_SYMBOL_COLOR,
    };

    const element: ShapeInstance = {
        id: uuid(),
        type: SymbolType.SHAPE,
        metaInfo: '',
        imageId: nodeId.id,
    };

    const dragSource = new BPMMxDragSource(
        symbol,
        (graph: BPMMxGraph, evt: PointerEvent, target: MxCell, point: MxPoint) => {
            if (isMouseEvent(evt) && isLeftMouseButton(evt)) {
                // TODO переместить в общий метод/сервис создания Shape
                const cell: MxCell = graph.insertVertex(
                    target,
                    symbol.id,
                    element,
                    point.x,
                    point.y,
                    compareSize(dragSource.getWidth(), dragSource.getHeight(), DEFAULT_IMAGE_DIMENSION, 'width'),
                    compareSize(dragSource.getWidth(), dragSource.getHeight(), DEFAULT_IMAGE_DIMENSION, 'height'),
                    `${symbol.id};${PictureSymbolConstants.SHAPE_IMAGE};image=${symbolService().prepareImageLinkToShow(
                        symbol.graphical,
                        server.url,
                    )};editable=0;`,
                );
                cell.edge = false;

                if (graph.modelType && graph.modelType.id !== ModelTypes.MIND_MAP) {
                    cell.setConnectable(false);
                }
            }
        },
        activeGraph,
        server.url,
    );
    dragSource.emulatePointerEvent();
    yield put(treeItemEndDrag());
}

function* drawObjectForModelMove(params: TDrawObjectForModelMove) {
    const { draggedModelNode, symbol, serverUrl, shouldCreateObjectDefinition, instance } = params;
    const modelContext: IModelContext = yield getActiveModelContext();
    const objectTypes: TObjectTypeState = yield select(objectTypeStateSelector);
    const objectType = getObjectTypeBySymbol(objectTypes, modelContext.nodeId.serverId, symbol);
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    const workspaceTab: TWorkspaceTab | undefined = yield select(TabsSelectors.byId(activeGraphId));
    const content = workspaceTab?.content;
    const hasDraggedModelDecomposition: boolean | undefined = instance?.modelAssignments.some(
        (assignment) => assignment.modelId === draggedModelNode.nodeId.id,
    );
    if (!activeGraph) return;

    let objectDefinition: ObjectDefinitionImpl = DragModelBll.createObjectDefinitionWithDecomposition(
        symbol,
        objectType,
        modelContext,
        draggedModelNode,
    );

    if (!shouldCreateObjectDefinition && instance) {
        objectDefinition = hasDraggedModelDecomposition
            ? instance
            : (DragModelBll.addDecompositionToObjectDefinition(instance, draggedModelNode) as ObjectDefinitionImpl);
    }

    const dropHandler = getModelMoveDropHandler(
        shouldCreateObjectDefinition,
        hasDraggedModelDecomposition,
        objectDefinition,
        content,
        symbol,
    );
    const dragSource = new BPMMxDragSource(symbol, dropHandler, activeGraph, serverUrl);
    dragSource.emulatePointerEvent();
}

function* handleDecompositionChooseObjectDialogSubmit(action: TObjectDecompositionChooseObjectDialogSubmit) {
    if (!action.payload.symbol || !action.payload.draggedModelNode) return;

    const { draggedModelNode, symbol, selectedStrategy, instance, serverUrl } = action.payload;
    const shouldCreateObjectDefinition = selectedStrategy !== SelectedStrategy.useExisting;

    yield drawObjectForModelMove({
        symbol,
        draggedModelNode,
        shouldCreateObjectDefinition,
        serverUrl,
        instance,
    });

    yield put(closeDialog(DialogType.SELECT_OBJECT_FOR_MODEL_DECOMPOSITION));
}

function* handleModelDrag(action: TModelMoveAction) {
    const { symbol, serverUrl, nodeId } = action.payload;
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);
    if (!activeGraph || activeGraphId.repositoryId !== nodeId.repositoryId) {
        return;
    }

    const workspaceTab: TWorkspaceTab | undefined = yield select(TabsSelectors.byId(activeGraphId));
    const content = workspaceTab?.content;

    if (!content) {
        return;
    }

    const selectedNodes: TreeNode[] = yield select(SelectedNodesSelector.getNodes());

    if (selectedNodes.length > 1) {
        yield put(showNotificationByType(NotificationType.MOVE_NODES_FORBIDDEN));

        return;
    }

    const draggedModelNode: TreeNode = yield select(TreeSelectors.itemById(nodeId));
    const modelContext: IModelContext = yield getActiveModelContext();
    const objectTypes: TObjectTypeState = yield select(objectTypeStateSelector);
    const objectType = getObjectTypeBySymbol(objectTypes, modelContext.nodeId.serverId, symbol);
    const existingObjectDefinitions: ObjectDefinitionNode[] = yield ObjectDefinitionsDAOService.getObjectDefinitions(
        modelContext.nodeId.serverId,
        {
            repositoryId: modelContext.nodeId.repositoryId,
            objectDefinitionName: draggedModelNode.name,
            objectTypeId: objectType?.id || symbol.objectType,
        },
    );

    if (existingObjectDefinitions.length) {
        yield put(
            openDialog(DialogType.SELECT_OBJECT_FOR_MODEL_DECOMPOSITION, {
                instances: existingObjectDefinitions,
                draggedModelNode,
                symbol,
                serverUrl,
            }),
        );

        return;
    }

    const drawObjectParams = {
        symbol,
        draggedModelNode,
        shouldCreateObjectDefinition: true,
        serverUrl,
    };

    yield drawObjectForModelMove(drawObjectParams);
}

function* handleObjectDefinitionDrag(action: TObjectDefinitionMoveAction) {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);
    const { symbol, serverUrl, nodeId } = action.payload;
    const { content }: TWorkspaceTab = yield select(TabsSelectors.byId(activeGraphId));

    if (!content || !activeGraph) {
        return;
    }

    const selectedNodes: TreeNode[] = yield select(SelectedNodesSelector.getNodes());

    if (selectedNodes.length > 1) {
        yield put(showNotificationByType(NotificationType.MOVE_NODES_FORBIDDEN));

        return;
    }

    const [objectDefinition]: ObjectDefinitionImpl[] = yield objectDefinitionService().loadObjects(
        content.nodeId.serverId,
        nodeId.repositoryId,
        [nodeId.id],
    );

    if (!objectDefinition) {
        return;
    }

    let actualObjectDefinition = { ...objectDefinition, idSymbol: symbol?.id } as ObjectDefinitionImpl;

    const dropHandler = (graph: BPMMxGraph, event: PointerEvent, target: MxCell, point: MxPoint) => {
        const doInsert = async (pasteAsNewObjectDefinition: boolean) => {
            if (pasteAsNewObjectDefinition) {
                // TODO 1647 refactoring, need check shadowed name
                actualObjectDefinition = objectDefinitionService().createObjectDefinition(
                    activeGraph.bpmMxGraphContext.serverId,
                    new ObjectDefinitionImpl({
                        ...objectDefinition,
                        version: 0,
                        nodeId: {
                            ...objectDefinition.nodeId,
                            id: uuid(),
                        },
                    }),
                );

                getStore().dispatch(
                    saveObjectDefinition(activeGraph.bpmMxGraphContext.serverId, actualObjectDefinition),
                );
            }

            const addedCell = drawObject({
                target,
                point,
                symbol,
                graph,
                objectDefinitions: [actualObjectDefinition],
                serverUrl,
            });

            const addedEdges: MxCell[] = await drawEdges(graph, addedCell);

            if (addedCell) {
                getStore().dispatch(
                    updateCellsOverlays({
                        graphId: graph.id,
                        cells: [addedCell, ...addedEdges],
                    }),
                );
            }
        };

        if (isPopupTrigger(event)) {
            // mouse right button was clicked in editor on tree node drag
            graph.bpmMxGraphContext.objectDefinitionCopyPasteContext = {
                selectedObjectDefinition: objectDefinition,
                doInsert,
            };
            graph.mouseListeners.forEach((listener: any) => {
                // tslint:disable-line:no-any
                if (listener instanceof BPMMxPopupMenuHandler) {
                    (listener as any).inTolerance = true; // tslint:disable-line:no-any
                    (listener as any).popupTrigger = true; // tslint:disable-line:no-any
                    listener.triggerX = event.tiltX; //  event.layerX ;
                    listener.triggerY = event.tiltY; // event.layerY;
                    listener.mouseUp.apply(listener, [graph, new MxMouseEvent(event)]);
                }
            });
        } else if (isMouseEvent(event) && isLeftMouseButton(event)) {
            doInsert(false);
        }
    };

    const dragSource = new BPMMxDragSource(symbol, dropHandler, activeGraph, serverUrl);

    dragSource.emulatePointerEvent();
    // в случае зафейленого перетаскивания в store добавится ненужный объект
    yield take(EDITOR_CELL_ADDED);
    yield put(objectDefinitionsAdd([actualObjectDefinition]));
}

function* handleDragSymbol(action: TDragSymbolAction) {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    const activeGraph = activeGraphId && instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) return;

    const { objectDefinitions, symbol } = action.payload;

    const { content } = yield select(TabsSelectors.byId(activeGraphId));
    const profile: TCurrentUserProfile | undefined = yield select(
        UserProfileSelectors.selectUserProfileByNodeId(activeGraph.id),
    );
    const {
        parentNodeId: parentFolderTypeNodeId,
        nodeId: { repositoryId },
    } = content;

    const { serverId } = activeGraphId;
    const presetId: string = yield select(TreeSelectors.presetById(parentFolderTypeNodeId));
    const parentTreeNode: TreeNode = yield select(TreeSelectors.itemById(parentFolderTypeNodeId));
    const symbols: Symbol[] = yield select(SymbolSelectors.byServerIdPresetId(serverId, presetId));

    let { parentNodeId } = content;

    if (parentTreeNode?.folderType) {
        const isDefaultFolder: boolean = getIsDefaultFolder(parentTreeNode.folderType);

        if (!isDefaultFolder) {
            const folderType: FolderType = yield select(
                FolderTypeSelectors.byId({
                    folderTypeId: parentTreeNode.folderType,
                    serverId,
                    presetId,
                }),
            );

            for (const objectDefinition of objectDefinitions) {
                const isCreatable: boolean = getIsObjectTypeByIdAllowedInFolder(
                    objectDefinition.objectTypeId,
                    folderType,
                );

                if (!isCreatable) {
                    const newParentNodeId: NodeId = yield TreeDaoService.calculateNewParentId(serverId, {
                        repositoryId,
                        parentId: parentFolderTypeNodeId.id,
                        typeId: objectDefinition.objectTypeId,
                    });
                    parentNodeId = { ...parentNodeId, ...newParentNodeId };
                }
            }
        }
    }

    const serviceTrackedObjectDefinitions: ObjectDefinitionImpl[] = objectDefinitions.map((objectDefinition) =>
        objectDefinitionService().createObjectDefinition(serverId, {
            ...objectDefinition,
            parentNodeId,
            idSymbol: symbol?.id || '',
        } as ObjectDefinitionImpl),
    );

    const dropHandler = async (graph: BPMMxGraph, event: PointerEvent, target: MxCell, point: MxPoint) => {
        if (isMouseEvent(event) && isLeftMouseButton(event)) {
            if (!symbol.objectType) {
                const cell = drawObject({ graph, target, point, symbol });

                if (cell) {
                    getStore().dispatch(
                        updateCellsOverlays({
                            graphId: graph.id,
                            cells: [cell],
                        }),
                    );
                }

                return;
            }

            const newCell = drawObject({
                graph,
                target,
                point,
                symbol,
                objectDefinitions: serviceTrackedObjectDefinitions,
            });

            for (const objectDefinition of serviceTrackedObjectDefinitions) {
                try {
                    const updatedObject = await objectDefinitionService().saveObjectDefinition(objectDefinition);

                    getStore().dispatch(saveObjectDefinitionSuccess(serverId, updatedObject));
                } catch (e) {
                    getStore().dispatch(saveObjectDefinitionFail(serverId, objectDefinition));

                    throw e;
                }
            }

            if (newCell) {
                getStore().dispatch(
                    updateCellsOverlays({
                        graphId: graph.id,
                        cells: [newCell],
                    }),
                );
            }

            const targetCell = newCell || graph.getDefaultParent();

            if (target?.value && ComplexSymbolManager.isHiddenEdgeConnectableObject(target?.value, symbols)) {
                graph.handleCellIntersection(targetCell);
            }

            if (action.payload.sourceCell && graph.modelType) {
                const { sourceCell } = action.payload;
                const sourceDef = objectDefinitionService().getObjectDefinitionByInstance(sourceCell.value, graph.id);
                const targetDef = objectDefinitionService().getObjectDefinitionByInstance(targetCell.value, graph.id);

                const edgeTypes = AvailableConnectionForSymbolsBLLService.getEdgeTypes(
                    graph.modelType,
                    sourceCell?.value?.symbolId,
                    targetCell?.value?.symbolId,
                    sourceDef?.objectTypeId,
                    targetDef?.objectTypeId,
                    profile?.edgeTypeAcls,
                );
                let edgeType: EdgeType | undefined;

                if (edgeTypes) {
                    edgeType = StatisticsSelectors.getLastUsedEdgeType(edgeTypes)(getStore().getState());
                }

                if (edgeType) {
                    const edgeInstance = new EdgeInstanceImpl({
                        id: uuid(),
                        edgeTypeId: edgeType.id,
                        style: edgeType.edgeStyle,
                        source: sourceCell,
                        target: targetCell,
                        name: '',
                        invisible: false,
                    });
                    const edge: MxCell = new MxCell(edgeInstance, new MxGeometry(), edgeType?.edgeStyle || '');
                    edge.setEdge(true);
                    edge.setConnectable(false);
                    const parent = graph.getModel().getParent(sourceCell);
                    graph.addEdge(edge, parent, sourceCell, targetCell);
                }
            }
        }
    };

    const dragSource = new BPMMxDragSource(symbol, dropHandler, activeGraph, '' /* serverUrl */);
    dragSource.emulatePointerEvent();
}

function* handleMoveObject({ payload: { key } }: TEditorMoveObjectAction) {
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) {
        return;
    }

    if (!activeGraph.cellEditor.isContentEditing()) {
        activeGraph.getModel().beginUpdate();

        try {
            const selectedCells: MxCell[] = activeGraph.getSelectionCells();
            const cellsAreMovable: boolean = selectedCells.every((c: MxCell) => ComplexSymbolManager.isCellMovable(c));

            if (!cellsAreMovable) {
                yield put(showNotificationByType(NotificationType.SYMBOL_MOVE_DISABLED));

                return;
            }

            let dx = 0;
            let dy = 0;

            if (key === KeyCodes.LEFT) dx = -MOVE_CELL_STEP;
            if (key === KeyCodes.RIGHT) dx = MOVE_CELL_STEP;
            if (key === KeyCodes.UP) dy = -MOVE_CELL_STEP;
            if (key === KeyCodes.DOWN) dy = MOVE_CELL_STEP;

            activeGraph.moveCells(selectedCells, dx, dy, false, undefined, undefined);
        } finally {
            activeGraph.getModel().endUpdate();
        }
    }
}

function* clearSelection(action: TDialogOpenAction | TTreeItemContextMenuAction) {
    // если открыт диалог то элементы все равно могут обрабатывать нажатия клавиш, например delete или клавиши управления курсором
    // если открыть диалог свойства объекта и нажать delete во время редактирования атрибутов то элемент будет удален с диаграммы
    // снимаем фокус с элемента при открытии диалога
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph = instancesBPMMxGraphMap.get(activeGraphId);

    // для диалога печати делаем исключение, для возможности печатать выделенные объекты
    if ((action as TDialogOpenAction)?.payload?.type === DialogType.PRINT_DIALOG) {
        return;
    }

    if (activeGraph && activeGraph.clearSelection) {
        activeGraph.clearSelection();
    }
}

function* changeSymbolForCell({ payload: { cellId, graphId, symbol } }: TChangeSymbolForCellAction) {
    const modelContext: IModelContext = yield modelContextByGraphId(graphId);

    if (!modelContext) {
        return;
    }

    const { graph } = modelContext;
    const cell = graph && graph.getModel().getCell(cellId);

    if (!cell) {
        return;
    }

    graph.getModel().beginUpdate();

    try {
        ComplexSymbolManager.getComplexSymbolInstance(cell)?.setSymbolForCell(symbol);
    } finally {
        graph.getModel().endUpdate();
    }
}

export function* editorClearSelection() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, clearSelection);
    yield takeEvery(COMMENTS_PANEL_FOCUS, clearSelection);
}

export function* editorSagaInit() {
    yield takeEvery(EDITOR_INIT, handleEditorInitRequest);
    yield takeEvery(EDITOR_DESTROY, handleEditorDestroy);
}

function* getActiveGraphInstance() {
    const modelContext: IModelContext = yield getActiveModelContext();

    return modelContext?.graph;
}

export function* editorZoomTo({ payload }: TZoomToAction) {
    const graph = yield getActiveGraphInstance();
    if (!graph) return;
    yield put(
        workspaceTabSetParams(graph.id, {
            zoomLevel: payload,
        }),
    );
}

export function* editorZoomIn() {
    const graph = yield getActiveGraphInstance();
    if (!graph) return;
    const scale = Math.round(graph.view.scale * graph.zoomFactor * 100);
    yield put(
        workspaceTabSetParams(graph.id, {
            zoomLevel: scale < BPMMxGraph.MAX_ZOOM_LEVEL ? scale : BPMMxGraph.MAX_ZOOM_LEVEL,
        }),
    );
}

export function* editorZoomOut() {
    const graph = yield getActiveGraphInstance();
    if (!graph) return;
    const scale = Math.round(graph.view.scale * (1 / graph.zoomFactor) * 100);
    yield put(
        workspaceTabSetParams(graph.id, {
            zoomLevel: scale > BPMMxGraph.MIN_ZOOM_LEVEL ? scale : BPMMxGraph.MIN_ZOOM_LEVEL,
        }),
    );
}

export function* editorModeChanged() {
    const activeGraph = yield getActiveGraphInstance();

    activeGraph?.setSelectionCells([]);
    activeGraph?.getSelectionModel().setSingleSelection(false);
}

export function* onDragError(notificationType: NotificationType, notificationData: any = null) {
    yield put(treeItemEndDrag());
    yield put(showNotification({ id: uuid(), type: notificationType, data: notificationData }));
}

export function* getGrantedSymbolsByUserProfile(creatableSymbols: Symbol[], nodeId: NodeId) {
    const userProfile: TCurrentUserProfile | undefined = yield select(
        UserProfileSelectors.selectUserProfileByNodeId(nodeId),
    );

    const grantedSymbols = MoveElementBLL.getGrantedSymbolsByUserProfile(creatableSymbols, userProfile);

    if (!grantedSymbols.length) {
        yield onDragError(NotificationType.ACCESS_DENIED_BY_PROFILE);

        return null;
    }

    return grantedSymbols;
}

export function* handleDraggedNodeSymbolSelection(
    nodeId: NodeId,
    supportedSymbols: Symbol[],
    errorNotificationType: NotificationType,
    moveActionCreator: (
        payload: TObjectDefinitionMoveActionPayload | TModelMoveActionPayload,
    ) => TObjectDefinitionMoveAction | TModelMoveAction,
) {
    switch (supportedSymbols.length) {
        case 0:
            yield put(showNotification({ id: nodeId.id, type: errorNotificationType }));
            break;
        case 1:
            yield put(
                moveActionCreator({
                    nodeId,
                    symbol: supportedSymbols[0],
                    serverUrl: undefined,
                }),
            );
            break;
        default:
            yield put(
                openDialog(DialogType.CHOOSE_SYMBOL_FOR_DRAG_DIALOG, {
                    supportedObjectTypeSymbols: supportedSymbols,
                    nodeId,
                }),
            );
            break;
    }
}

export function* handleEditorDragNode({ payload }: TEditorDragNodeAction) {
    const { draggedNode, creatableSymbols } = payload;

    if (!draggedNode) return;

    const graph: BPMMxGraph = yield getActiveGraphInstance();

    if (!graph.modelType) return;

    if (graph.mode !== EditorMode.Edit) {
        yield onDragError(NotificationType.DND_ERROR_MODEL_IS_LOCKED);

        return;
    }

    if (draggedNode.nodeId.repositoryId !== graph.id.repositoryId) {
        yield onDragError(NotificationType.DND_ERROR_INVALID_REPOSITORY);

        return;
    }

    if (draggedNode.deleted) {
        yield onDragError(NotificationType.DND_ERROR_CANT_MOVE_DELETED_NODE);

        return;
    }

    switch (draggedNode.type) {
        case TreeItemType.Folder:
        case TreeItemType.Spreadsheet:
        case TreeItemType.SimulationModeling:
            yield onDragError(NotificationType.DND_ERROR_WRONG_NODE_TYPE, { type: draggedNode.type });
            break;
        case TreeItemType.File:
            if (isImageFileNode(draggedNode as FileNodeDTO)) {
                yield put(createEditorDNDHandler(draggedNode.nodeId));
            } else {
                yield onDragError(NotificationType.INCORRECT_IMAGE_ERROR);
            }
            break;
        case TreeItemType.ObjectDefinition: {
            yield put(treeItemEndDrag());

            let availableSymbols: Symbol[] = [];
            if (graph.modelType.id === ModelTypes.SEQUENCE_DIAGRAM || graph.modelType.allowAnyObject) {
                availableSymbols = creatableSymbols;
            } else {
                const isAvailableObjectType = graph.modelType.objectTypes.some(
                    (objType) => objType.id === draggedNode.objectTypeId,
                );
                availableSymbols = isAvailableObjectType ? graph.modelType.symbols : [];
            }

            if (!availableSymbols.length) {
                return yield put(
                    showNotification({ id: draggedNode.nodeId.id, type: NotificationType.INCORRECT_OBJECT_TYPE }),
                );
            }

            const grantedObjectDefinitionSymbols = yield getGrantedSymbolsByUserProfile(availableSymbols, graph.id);

            if (!grantedObjectDefinitionSymbols) {
                return;
            }

            let selectedSymbol: Symbol[] = [];
            let supportedObjectDefinitionSymbols: Symbol[] = [];

            if (graph.modelType.allowAnyObject) {
                const symbol =
                    symbolService().getSymbolByNodeId(draggedNode.nodeId, draggedNode.idSymbol || '') ||
                    graph?.modelType?.symbols[0];
                selectedSymbol = symbol ? [symbol] : [];
            } else {
                supportedObjectDefinitionSymbols = MoveElementBLL.getObjectDefinitionSupportedSymbols(
                    draggedNode,
                    grantedObjectDefinitionSymbols,
                    graph,
                );
            }

            yield handleDraggedNodeSymbolSelection(
                draggedNode.nodeId,
                graph.modelType.allowAnyObject ? selectedSymbol : supportedObjectDefinitionSymbols,
                NotificationType.INCORRECT_OBJECT_TYPE,
                objectDefinitionMove,
            );
            break;
        }
        case TreeItemType.Model:
        case TreeItemType.Matrix:
        case TreeItemType.Wiki: {
            yield put(treeItemEndDrag());

            const availableSymbols =
                graph.modelType.id === ModelTypes.SEQUENCE_DIAGRAM
                    ? creatableSymbols
                    : getSymbolsFromModelType(graph.modelType);
            const grantedModelSymbols = yield getGrantedSymbolsByUserProfile(availableSymbols, graph.id);

            if (!grantedModelSymbols) {
                return;
            }

            const objectTypes = yield select(
                ObjectTypeSelectors.listAllByPreset(graph.id.serverId, graph.modelType.presetId),
            );
            const supportedModelSymbols = MoveElementBLL.getModelSupportedSymbols(
                draggedNode,
                objectTypes,
                grantedModelSymbols,
            );

            yield handleDraggedNodeSymbolSelection(
                draggedNode.nodeId,
                supportedModelSymbols,
                NotificationType.DND_ERROR_CANT_CREATE_DECOMPOSITION,
                modelMove,
            );
            break;
        }
        default:
            break;
    }
}

function* handleEdgeManagementDialogSubmit({ payload }: TSubmitEdgeManagementDialogAction) {
    const { checked, unchecked } = payload;
    const activeGraphId = yield select(getActiveGraph);
    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeGraphId);

    if (!activeGraph) {
        yield put(closeDialog(DialogType.EDGE_MANAGEMENT_DIALOG));

        return;
    }

    const addedCells: MxCell[] = checked.reduce<MxCell[]>((acc, el) => {
        const { sourceObjectDefinition, targetObjectDefinition, edgeDefinition } = el;
        const sourceCells = getEdgeTerminals(activeGraph, sourceObjectDefinition.nodeId.id);
        const targetCells = getEdgeTerminals(activeGraph, targetObjectDefinition.nodeId.id);
        const addedEdges = createEdges(activeGraph, sourceCells, targetCells, edgeDefinition);

        acc.push(...addedEdges);

        return acc;
    }, []);
    const edgesToDelete: MxCell[] = unchecked.reduce<MxCell[]>((acc, el) => {
        acc.push(...el.edgeInstances);

        return acc;
    }, []);

    activeGraph.removeCells(edgesToDelete);

    yield put(updateCellsOverlays({ graphId: activeGraph.id, cells: addedCells }));
    yield put(closeDialog(DialogType.EDGE_MANAGEMENT_DIALOG));
}

function* handleProcessSpaceAction({ payload }: TEditorProcessSpaceAction) {
    const activeGraphId = yield select(getActiveGraph);
    const graph = instancesBPMMxGraphMap.get(activeGraphId);
    if (!graph) {
        return;
    }
    const spaceTool = SpaceTool.getTool(payload.action, graph);
    spaceTool.attachEvents();
}

function* handleOpenSearchAction() {
    const activeGraphId: NodeId | undefined = yield select(getActiveGraph);
    if (!activeGraphId) return;

    yield put(workspaceTabSetAction({ nodeId: activeGraphId, name: 'focusSearch', value: true }));
}

function* handleOpenElementProperties() {
    const graph: BPMMxGraph | undefined = yield getActiveGraphInstance();

    if (!graph) return;

    const selectedCellsIds: string[] = yield select(getSelectedCellIds);
    const { selectedCellsWithoutLabels }: TSelectedCellElements =
        ToolbarButtonsBllService.getRawSelectedCellsAndSelectedCellsWithoutLabels(graph, selectedCellsIds);

    if (selectedCellsWithoutLabels?.length > 1) return;

    if (selectedCellsWithoutLabels.length === 0) {
        yield put(viewModelProperties(graph.id));

        return;
    }

    const singleCell: MxCell = selectedCellsWithoutLabels[0];
    const cellValue = singleCell.getValue();
    const imgageId: string | undefined = cellValue?.imageId;

    if (cellValue?.type === SymbolType.SHAPE && imgageId) {
        yield put(treeItemOpenPropertyAction({ ...graph.id, id: imgageId }, TreeItemType.File));
    } else {
        yield put(objectPropertyView(singleCell.id, ObjectPropertiesDialogActiveTab.NameAndAttributes));
    }
}

export function* editorSagaActions() {
    yield takeEvery(EDITOR_COPY, handleCopy);
    yield takeEvery(EDITOR_CUT, handleCut);
    yield takeEvery(EDITOR_DRAG_SHAPE, handleShapePaste);
    yield takeEvery(EDITOR_DROP_PASTE_PREVIEW, handleDropPastePreview);
    yield takeEvery(EDITOR_ALIGN, handleAlign);
    yield takeEvery(EDITOR_DISTRIBUTE, handleDistribute);
    yield takeEvery(EDITOR_PICK_OUT, handlePickOut);
    yield takeEvery(EDITOR_MOVE_TO, handleMoveTo);
    yield takeEvery(EDITOR_UPDATE, handleEditorUpdate);
    yield takeEvery(EDITOR_MOVE_LAYER, handleMoveLayer);
    yield takeEvery(EDITOR_DELETE, handleDelete);
    yield takeEvery(DELETE_SELECTED_CELLS_FROM_ACTIVE_GRAPH, handleDeleteSelectedCellsFromActiveGraph);
    yield takeEvery(EDITOR_SELECT_ALL, handleSelectAll);
    yield takeEvery(EDITOR_SINGLE_CELL_SELECTED, handleEditorSingleCellSelected);
    yield takeEvery(EDITOR_FORMAT_BY_EXAMPLE, handleFormatByExample);
    yield takeEvery(EDITOR_SET_CELL_DEFAULT_STYLE, handleSetCellDefaultStyle);
    yield takeEvery(GENERAL_MENU_LABEL_STYLE_CHANGE, handleLabelStyleChange);
    yield takeEvery(EDITOR_GROUPING, handleGrouping);
    yield takeEvery(EDITOR_ESCAPE, handleEscape);
    yield takeEvery(OBJECT_DEFINITION_MOVE, handleObjectDefinitionDrag);
    yield takeEvery(EDITOR_CHANGE_EDGE_TYPE, handleEdgeTypeChange);
    yield takeEvery(EDITOR_CHANGE_EDGE_STYLE, handleChangeEdgeStyle);
    yield takeEvery(EDITOR_CHANGE_EDGE_COLOR, handleChangeEdgeColor);
    yield takeEvery(EDITOR_CHANGE_FONT_COLOR, handleChangeFontColor);
    yield takeEvery(EDITOR_CHANGE_EDGE_MULTILINGUAL_NAME, handleChangeEdgeMultilangName);
    yield takeEvery(WORKSPACE_TABS_ACTIVATE, handleWorkspaceChanged);
    yield takeEvery(WORKSPACE_TABS_REMOVE, handleWorkspaceChanged);
    yield takeEvery(EDITOR_LABEL_CHANGED, handleLabelChanged);
    // yield takeEvery(EDITOR_STOP_EDITING_CELL, handleStopEditingCell);
    yield takeEvery(EDITOR_SET_FOCUS_AND_START_EDIT_LABEL, handleSetFocusAndStartEdit);
    yield takeEvery(EDITOR_SET_OBJECT_TO_CELL, handleSetObjectToCell);
    yield takeEvery(CREATE_NEW__OBJECT_NAME, handleCreateNewObjectName);
    // yield takeEvery(EDITOR_LOOKUP_EXISTING_OBJECT_DEFINITIONS, handleLookupExistingObjectDefinitions);
    yield takeEvery(EDITOR_CELL_ADDED, handleCellAdded);
    yield takeEvery(EDITOR_CELL_COLOR_PICKED, handleCellColorPicked);
    yield takeEvery(EDITOR_MODE_CHANGED_PREPARE, handleModeChanged);
    yield takeEvery(EDITOR_ADD_TABLE_ROW, handleAddTableRow);
    yield takeEvery(EDITOR_REMOVE_TABLE_ROW, handleRemoveTableRow);
    yield takeEvery(EDITOR_ADD_TABLE_COLUMN, handleAddTableColumn);
    yield takeEvery(EDITOR_REMOVE_TABLE_COLUMN, handleRemoveTableColumn);
    yield takeEvery(EDITOR_ADD_BPMN_TABLE_ROW, handleAddBpmnTableRow);
    yield takeEvery(EDITOR_REMOVE_BPMN_TABLE_ROW, handleRemoveBpmnTableRow);
    yield takeEvery(EDITOR_DRAG_SYMBOL, handleDragSymbol);
    yield takeEvery(EDITOR_MOVE_OBJECT, handleMoveObject);
    yield takeEvery(CHANGE_SYMBOL_FOR_CELL, changeSymbolForCell);
    yield takeEvery(EDITOR_ZOOM_IN, editorZoomIn);
    yield takeEvery(EDITOR_ZOOM_OUT, editorZoomOut);
    yield takeEvery(EDITOR_ZOOM_TO, editorZoomTo);
    yield takeEvery(EDITOR_MODE_CHANGED, editorModeChanged);
    yield takeEvery(EDITOR_DROP_FILE_NODE, handleDnDFileNode);
    yield takeEvery(MODEL_MOVE, handleModelDrag);
    yield takeEvery(OBJECT_DECOMPOSITION_CHOOSE_OBJECT_DIALOG_SUBMIT, handleDecompositionChooseObjectDialogSubmit);
    yield takeEvery(EDITOR_DRAG_NODE, handleEditorDragNode);
    yield takeEvery(EDGE_MANAGEMENT_DIALOG_SUBMIT, handleEdgeManagementDialogSubmit);
    yield takeEvery(EDITOR_PROCESS_SPACE_ACTION, handleProcessSpaceAction);
    yield takeEvery(OPEN_SEARCH_ACTION, handleOpenSearchAction);
    yield takeEvery(OPEN_ELEMENT_PROPERTIES, handleOpenElementProperties);
}
