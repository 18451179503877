import { TNodeState } from '@/reducers/entities/TNodeState';
import { NodeId, ReportColumnData } from '@/serverapi/api';
import { TReducer } from '@/utils/types';
import {
    REPORT_ADD_COLUMN,
    REPORT_ADD_NODES,
    REPORT_CHANGE_FILLING_TYPE,
    REPORT_CLEAR_SEARCH_REQUEST,
    REPORT_CLEAR_SELECT_COLUMN,
    REPORT_DELETE_COLUMN,
    REPORT_DELETE_NODES,
    REPORT_REQUEST_SUCCESS,
    REPORT_SAVE_REQUEST_SUCCESS,
    REPORT_SEARCH_REQUEST,
    REPORT_SEARCH_REQUEST_FAILURE,
    REPORT_SEARCH_REQUEST_SUCCESS,
    REPORT_SELECT_COLUMN,
    REPORT_SET_COLUMN_DATA,
    REPORT_SET_SEARCH_REQUESTS,
} from '../actions/report.actionTypes';
import { TExtendedReportState, TReportState } from './report.reducer.types';
import { unionWith } from 'lodash-es';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { v4 as uuid } from 'uuid';

const INITIAL_REPORT_STATE: TReportState = new TNodeState();

export const reportReducer: TReducer<TReportState> = (state = INITIAL_REPORT_STATE, action) => {
    switch (action.type) {
        case REPORT_SAVE_REQUEST_SUCCESS: {
            const {
                payload: {
                    report,
                    report: { nodeId, version },
                },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(nodeId);
            if (existReport && version) {
                return state.set(nodeId, { ...existReport, version, unsaved: false });
            }
            if (!existReport) {
                return state.set(nodeId, report);
            }

            return state;
        }

        case REPORT_REQUEST_SUCCESS: {
            const {
                payload: {
                    report,
                    report: { nodeId },
                },
            } = action;

            return state.set(nodeId, { ...report, unsaved: false });
        }

        case REPORT_ADD_NODES: {
            const {
                payload: { reportNodeId, addedNodeIds },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const existNodeIds = existReport.reportData?.manuallyFilledNodes || [];
                const manuallyFilledNodes: NodeId[] = unionWith(existNodeIds, addedNodeIds, compareNodeIds);

                const updatedReport: TExtendedReportState = {
                    ...existReport,
                    reportData: { ...existReport.reportData, manuallyFilledNodes },
                    unsaved: true,
                };

                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_DELETE_NODES: {
            const {
                payload: { reportNodeId, nodeIds },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const existNodeIds = existReport.reportData?.manuallyFilledNodes || [];
                const manuallyFilledNodes: NodeId[] = existNodeIds.filter(
                    (existNodeId) => !nodeIds.some((nodeId) => compareNodeIds(nodeId, existNodeId)),
                );

                const updatedReport: TExtendedReportState = {
                    ...existReport,
                    reportData: { ...existReport.reportData, manuallyFilledNodes },
                    unsaved: true,
                };

                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_SET_COLUMN_DATA: {
            const {
                payload: { reportNodeId, columnId, data },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const columns: ReportColumnData[] = [...(existReport.reportData?.columns || [])];
                let columnIndex: number = columns.findIndex((column) => column.columnId === columnId);

                if (columnIndex !== -1) {
                    columns[columnIndex] = { ...columns[columnIndex], ...data };
                }

                const updatedReport: TExtendedReportState = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                    unsaved: true,
                };
                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_DELETE_COLUMN: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const columns: ReportColumnData[] = existReport.reportData?.columns.filter(
                    (column) => column.columnId !== columnId,
                );

                const updatedReport: TExtendedReportState = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                    unsaved: true,
                };
                return state.set(reportNodeId, updatedReport);
            }
            return state;
        }

        case REPORT_SELECT_COLUMN: {
            const {
                payload: { reportNodeId, columnId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                if (existReport.selectedColumnId === columnId) {
                    const columns: ReportColumnData[] = existReport.reportData.columns.filter(
                        (column) => column.columnName,
                    );

                    return state.set(reportNodeId, {
                        ...existReport,
                        reportData: { ...existReport.reportData, columns },
                        selectedColumnId: '',
                    });
                } else {
                    return state.set(reportNodeId, { ...existReport, selectedColumnId: columnId });
                }
            }
            return state;
        }

        case REPORT_ADD_COLUMN: {
            const {
                payload: { reportNodeId, selectedColumnId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const columns: ReportColumnData[] = existReport.reportData?.columns || [];
                let maxOrderNumber: number = 0;
                columns.forEach((column) => (maxOrderNumber = Math.max(maxOrderNumber, column.orderNumber)));
                const newColumn: ReportColumnData = {
                    attributeType: 'SYSTEM',
                    attributeTypeId: '',
                    columnId: uuid(),
                    orderNumber: maxOrderNumber + 1,
                    filterEnabled: true,
                    visibilityEnabled: true,
                };

                if (selectedColumnId) {
                    const currentColumn: ReportColumnData | undefined = columns.find(
                        (column) => column.columnId === selectedColumnId,
                    );
                    if (currentColumn) {
                        const currentOrderNumber: number = currentColumn.orderNumber;
                        newColumn.orderNumber = currentOrderNumber + 1;
                        columns.forEach((column) => {
                            if (column.orderNumber > currentOrderNumber) {
                                column.orderNumber += 1;
                            }
                        });
                    }
                }

                columns.push(newColumn);

                const updatedReport: TExtendedReportState = {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                    selectedColumnId: newColumn.columnId,
                    unsaved: true,
                };

                return state.set(reportNodeId, updatedReport);
            }

            return state;
        }

        case REPORT_CLEAR_SELECT_COLUMN: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                const columns: ReportColumnData[] = existReport.reportData.columns.filter(
                    (column) => column.columnName,
                );
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, columns },
                    selectedColumnId: '',
                });
            }

            return state;
        }
        case REPORT_CHANGE_FILLING_TYPE: {
            const {
                payload: { reportNodeId, fillingType },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                if (fillingType === existReport.reportData.fillingType) return state;

                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, fillingType },
                    unsaved: true,
                });
            }

            return state;
        }

        case REPORT_SET_SEARCH_REQUESTS: {
            const {
                payload: { reportNodeId, searchRequests },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, searchRequests },
                    unsaved: true,
                });
            }

            return state;
        }

        case REPORT_SEARCH_REQUEST_SUCCESS: {
            const {
                payload: { reportNodeId, searchResults },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    unsaved: true,
                    searchResults,
                    loading: false,
                });
            }

            return state;
        }

        case REPORT_CLEAR_SEARCH_REQUEST: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    reportData: { ...existReport.reportData, searchRequests: [] },
                    searchResults: [],
                    unsaved: true,
                });
            }

            return state;
        }

        case REPORT_SEARCH_REQUEST: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    loading: true,
                });
            }

            return state;
        }

        case REPORT_SEARCH_REQUEST_FAILURE: {
            const {
                payload: { reportNodeId },
            } = action;

            const existReport: TExtendedReportState | undefined = state.byNodeId.get(reportNodeId);

            if (existReport && existReport.reportData) {
                return state.set(reportNodeId, {
                    ...existReport,
                    loading: false,
                });
            }

            return state;
        }

        default:
            return state;
    }
};
