import type { TRootState } from '../../../reducers/root.reducer.types';
import type { NodeId } from '../../../serverapi/api';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { ImageUploadDialog } from '@/modules/FileUpload/components/ImageUploadDialog.component';
import { v4 as uuid } from 'uuid';

type TImageDialogContainerOwnProps = {
    id: string;
    workspaceId: NodeId;
    onSubmit: (file: File, alt: string, title: string) => void;
};

const mapStateToProps = (state: TRootState, ownProps: TImageDialogContainerOwnProps) => {
    return {
        id: ownProps.id || uuid(),
    };
};

const mapDispatchToProps = (dispatch, ownProps: TImageDialogContainerOwnProps) => ({
    onClose: () => {
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG_WIKI));
    },
    onSubmit: (file: File, alt: string, title: string) => {
        dispatch(closeDialog(DialogType.IMAGE_UPLOAD_DIALOG_WIKI));

        if (ownProps.onSubmit) {
            ownProps.onSubmit(file, alt, title);
        }
    },
});

export const WikiUploadDialogContainer = connect(mapStateToProps, mapDispatchToProps)(ImageUploadDialog);
