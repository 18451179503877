import { put, takeEvery } from 'redux-saga/effects';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import { GET_AIP_SEARCH_RESULT } from '../actionsTypes/assistantInterface.actionTypes';
import { SearchResponse, SearchResult } from '@/serverapi/api';
import { TSearchDataListItem } from '@/reducers/search.reducer.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { setAipSearchData } from '../actions/assistantInterface.actions';
import { TGetSearchResultAction } from '../actions/assiastantInterface.actions.types';

function* handleGetSearchResult({
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes, nodeId},
}: TGetSearchResultAction) {
        try {
            yield put(setAipSearchData({ id: nodeId.id, isLoading: true }));

            const response: SearchResponse = yield SearchDaoService.getExtendedSearchResponse({
                rootSearchNodeId: nodeId,
                searchText,
                includePath: true,
                includeCount: false,
                searchVisibility,
                searchRules: searchRules.map(({ attributeType, attributeTypeId, queryRule, values }) => ({
                    attributeType,
                    attributeTypeId,
                    queryRule,
                    values: values.map((val) => val.value),
                })),
                nodeTypes: searchNodeTypes,
                limit: 50,
            });

            const resultList: SearchResult[] = response.resultList || [];

            const searchResult: TSearchDataListItem[] = resultList.map((item) => ({
                multilingualName: item.multilingualName,
                path: `${item.path}`,
                type: item.nodeType as TreeItemType,
                elementType: item.elementTypeId || '',
                nodeId: {
                    ...item.nodeId,
                    serverId: nodeId.serverId,
                },
                deleted: item.deleted,
            }));

            yield put(setAipSearchData({ id: nodeId.id, searchResult }));
        } finally {
            yield put(setAipSearchData({ id: nodeId.id, isLoading: false }));
        }
    
}

export function* searchAipSaga() {
    yield takeEvery(GET_AIP_SEARCH_RESULT, handleGetSearchResult);
}
