import {
    SystemPropertiesFormItemNames,
    TColorsPicker,
    TPropsWithFormRef,
} from '@/modules/AdminTools/ServerSettings/SystemProperties.types';
import { getAppColor, getAppColorScheme } from '@/selectors/app.selector';
import { useIntl } from 'react-intl';
import { Form, Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColorResult, SketchPicker } from 'react-color';
import { DEFAULT_MAIN_COLOR, DEFAULT_SECOND_COLOR, DEFAULT_SELECTED_ROW_COLOR } from './ColorSchemeSettings.types';
import theme from './ColorSchemeSettings.scss';
import tabTheme from '../../SystemProperties.scss';
import { TAppColor } from '@/reducers/app.reducer.types';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import messages from '../../SystemProperties.messages';
import { ButtonSettings } from '@/modules/AdminTools/ServerSettings/components/ColorSchemeSettings/components/ButtonSettings/ButtonSettings.component';
import { DEFAULT_BUTTONS_COLORS } from './components/ButtonSettings/components/ButtonRow/components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.constants';
import { clearTmpBtnsColors, setTmpBtnsColors } from '../../../../../actions/app.actions';
import { AppColorScheme } from '../../../../../serverapi/api';
import { getBtnItemFieldName } from './components/ButtonSettings/components/ButtonRow/components/ButtonEventTypeColorPickers/components/ButtonStyleColorPicker/ButtonStyleColorPicker.utils';

export const ColorSchemeSettings = (props: TPropsWithFormRef) => {
    const { formRef } = props;

    const intl = useIntl();
    const dispatch = useDispatch();
    const appColor: TAppColor = useSelector(getAppColor);
    const appColorScheme: AppColorScheme = useSelector(getAppColorScheme);

    const [colorsPicker, setColorsPicker] = useState<TColorsPicker>({
        mainColor: appColor.mainColor || DEFAULT_MAIN_COLOR,
        secondColor: appColor.secondColor || DEFAULT_SECOND_COLOR,
        selectedRowColor: appColor.selectedRowColor || DEFAULT_SELECTED_ROW_COLOR,
    });

    useEffect(() => {
        dispatch(setTmpBtnsColors(appColorScheme.button || {}));
    }, []);

    const { mainColor, secondColor, selectedRowColor } = colorsPicker;

    const handleChangeColorPicker = (colors: TColorsPicker) => {
        const newColors = { ...colorsPicker };
        Object.keys(colors).forEach((colorType) => {
            newColors[colorType] = colors[colorType];
            formRef.current?.setFieldValue(colorType, colors[colorType]);
        });
        setColorsPicker(newColors);
    };

    const handleSetDefaultButtonsColors = () => {
        Object.keys(DEFAULT_BUTTONS_COLORS).forEach((button) => {
            Object.keys(DEFAULT_BUTTONS_COLORS[button]).forEach((eventType) => {
                Object.keys(DEFAULT_BUTTONS_COLORS[button][eventType]).forEach((item) => {
                    formRef.current?.setFieldValue(
                        getBtnItemFieldName(button, eventType, item),
                        DEFAULT_BUTTONS_COLORS[button][eventType][item],
                    );
                });
            });
        });
        dispatch(clearTmpBtnsColors);
    };

    return (
        <div className={tabTheme.formTab}>
            <div className={theme.wrapperColor}>
                <div className={theme.headerContainer}>{intl.formatMessage(messages.general)}</div>
                <div className={theme.wrapperColumn}>
                    <Form.Item
                        initialValue={mainColor}
                        name={SystemPropertiesFormItemNames.mainColor}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.mainColor)}
                    >
                        <Popover
                            content={
                                <SketchPicker
                                    color={mainColor}
                                    onChange={(color: ColorResult) =>
                                        handleChangeColorPicker({ mainColor: color.hex as string })
                                    }
                                    disableAlpha
                                />
                            }
                            trigger="click"
                        >
                            <div className={theme.changeColorBlock} style={{ backgroundColor: `${mainColor}` }} />
                        </Popover>
                    </Form.Item>
                    <Form.Item
                        initialValue={secondColor}
                        name={SystemPropertiesFormItemNames.secondColor}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.secondColor)}
                    >
                        <Popover
                            content={
                                <SketchPicker
                                    color={secondColor}
                                    onChange={(color: ColorResult) =>
                                        handleChangeColorPicker({ secondColor: color.hex as string })
                                    }
                                    disableAlpha
                                />
                            }
                            trigger="click"
                        >
                            <div className={theme.changeColorBlock} style={{ backgroundColor: `${secondColor}` }} />
                        </Popover>
                    </Form.Item>
                    <Form.Item
                        initialValue={selectedRowColor}
                        name={SystemPropertiesFormItemNames.selectedRowColor}
                        className={theme.formItemCheckBox}
                        label={intl.formatMessage(messages.selectedRowColor)}
                    >
                        <Popover
                            content={
                                <SketchPicker
                                    color={selectedRowColor}
                                    onChange={(color: ColorResult) =>
                                        handleChangeColorPicker({ selectedRowColor: color.hex as string })
                                    }
                                    disableAlpha
                                />
                            }
                            trigger="click"
                        >
                            <div
                                className={theme.changeColorBlock}
                                style={{ backgroundColor: `${selectedRowColor}` }}
                            />
                        </Popover>
                    </Form.Item>
                </div>
                <Button
                    onClick={() =>
                        handleChangeColorPicker({
                            mainColor: DEFAULT_MAIN_COLOR,
                            secondColor: DEFAULT_SECOND_COLOR,
                            selectedRowColor: DEFAULT_SELECTED_ROW_COLOR,
                        })
                    }
                >
                    {intl.formatMessage(messages.resetToDefaultColors)}
                </Button>
            </div>
            <ButtonSettings formRef={formRef} />
            <div className={theme.btnContainer}>
                <Button onClick={handleSetDefaultButtonsColors}>
                    {intl.formatMessage(messages.resetToDefaultColors)}
                </Button>
            </div>
        </div>
    );
};
