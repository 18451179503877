import { defineMessages } from 'react-intl';

export default defineMessages({
    emptyTocContentTitle: {
        id: 'RichTextEditor.emptyTocContentTitle',
        defaultMessage: 'В статье отсутствуют заголовки.',
    },
    createdBy: {
        id: 'RichTextEditor.createdBy',
        defaultMessage: 'Создал(а)',
    },
    updatedBy: {
        id: 'RichTextEditor.updatedBy',
        defaultMessage: 'редактировал(а)',
    },
});
