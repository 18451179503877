import { TRootState } from '../../../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { ReportNode, NodeId, ReportDataFillingTypeEnum, SearchRequest, SearchResult } from '../../../serverapi/api';
import { TReportState } from '../reducers/report.reducer.types';

const reportStateSelector = (state: TRootState): TReportState => state.report;

export namespace ReportSelectors {
    export const byId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportNode | undefined>(reportStateSelector, (state) =>
            state.get(reportNodeId),
        );

    export const byIds = (nodeIds: NodeId[]) =>
        createSelector<TRootState, TReportState, ReportNode[]>(reportStateSelector, (state) => {
            const reportArr: ReportNode[] = nodeIds
                .map((reportNodeId) => {
                    const report: ReportNode | undefined = state.get(reportNodeId);
                    return report;
                })
                .filter((element): element is ReportNode => !!element);

            return reportArr;
        });

    export const selectedColumnId = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, string | undefined>(
            reportStateSelector,
            (state) => state.get(reportNodeId)?.selectedColumnId,
        );

    export const isUnsaved = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, boolean>(reportStateSelector, (state) => {
            if (state.get(reportNodeId)?.unsaved === undefined) return true;

            return !!state.get(reportNodeId)?.unsaved;
        });

    export const fillingType = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, ReportDataFillingTypeEnum>(reportStateSelector, (state) => {
            return state.get(reportNodeId)?.reportData?.fillingType || 'MANUAL';
        });

    export const searchRequests = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, SearchRequest[]>(reportStateSelector, (state) => {
            if (state.get(reportNodeId)?.reportData?.fillingType === 'AUTO') {
                return state.get(reportNodeId)?.reportData?.searchRequests || [];
            }

            return [];
        });

    export const searchResults = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, SearchResult[]>(reportStateSelector, (state) => {
            if (state.get(reportNodeId)?.reportData?.fillingType === 'AUTO') {
                return state.get(reportNodeId)?.searchResults || [];
            }

            return [];
        });

    export const isLoading = (reportNodeId: NodeId) =>
        createSelector<TRootState, TReportState, boolean>(
            reportStateSelector,
            (state) => !!state.get(reportNodeId)?.loading,
        );
}
