import { Checkbox, Form, InputNumber, Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import theme from '../../SystemProperties.scss';
import messages from '../../SystemProperties.messages';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { SystemPropertiesSelectors } from '../../../../../selectors/systemProperties.selectors';
import { SystemPropertiesFormItemNames } from '../../SystemProperties.types';
import { FeatureSettingDTO } from '@/serverapi/api';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { LocalesService } from '@/services/LocalesService';

type TAdditionalSettingsProps = {
    featuresSettings: FeatureSettingDTO[] | undefined;
    setFeaturesSettings: (featuresSettings: FeatureSettingDTO[] | undefined) => void;
};

export const AdditionalSettings = (props: TAdditionalSettingsProps) => {
    const intl = useIntl();

    const isActiveHidingUsers = useSelector(SystemPropertiesSelectors.getIsActiveHidingUsers);
    const messageStorageDays = useSelector(SystemPropertiesSelectors.getMessageStorageDays);
    const currentLocale = useSelector(getCurrentLocale);

    return (
        <div className={theme.formTab}>
            <Form.Item
                initialValue={isActiveHidingUsers}
                name={SystemPropertiesFormItemNames.isActiveHidingUsers}
                className={classNames(theme.formItemForActiveHidingUsers, theme.formItem)}
                label={intl.formatMessage(messages.isActiveHidingUsers)}
                valuePropName="checked"
            >
                <Checkbox className={theme.checkbox} />
            </Form.Item>
            <Form.Item
                initialValue={messageStorageDays || 90}
                name={SystemPropertiesFormItemNames.messageStorageDays}
                className={theme.formItem}
                label={intl.formatMessage(messages.messageStorageDays)}
            >
                <InputNumber min={3} max={99999}></InputNumber>
            </Form.Item>
            {props.featuresSettings?.length ? (
                <>
                    {props.featuresSettings.map((feature) => (
                        <Form.Item
                            className={theme.formItem}
                            label={LocalesService.internationalStringToString(feature.name, currentLocale)}
                            valuePropName="checked"
                        >
                            <Tooltip
                                mouseLeaveDelay={0}
                                title={
                                    feature.description
                                        ? LocalesService.internationalStringToString(feature.description, currentLocale)
                                        : ''
                                }
                                placement="top"
                            >
                                <Checkbox
                                    checked={feature.enable}
                                    className={theme.checkbox}
                                    onChange={(e) => {
                                        props.setFeaturesSettings(
                                            props.featuresSettings?.map((featureSetting) => {
                                                if (featureSetting.code === feature.code) {
                                                    return { ...featureSetting, enable: e.target.checked };
                                                }

                                                return featureSetting;
                                            }),
                                        );
                                    }}
                                />
                            </Tooltip>
                        </Form.Item>
                    ))}
                </>
            ) : null}
        </div>
    );
};
