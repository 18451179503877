import { TSearchActions } from '@/actions/search.actions.types';
import {
    RESET_SEARCH_DATA,
    SET_ROOT_SEARCH_NODE_ID,
    SET_SEARCH_DATA,
    SET_SEARCH_NODE_TYPES,
    SET_SEARCH_RULES,
    SET_SEARCH_TEXT,
    SET_SEARCH_VISIBILITY,
} from '../actionsTypes/search.actionTypes';
import { addIfExist } from '../utils/redux.utils';
import { TSearchReducerState, TSearchState, TSearcParams } from './search.reducer.types';
import { REPORT_CLEAR_SEARCH_REQUEST } from '@/modules/Report/actions/report.actionTypes';

const initial: TSearchReducerState = {};

export const initSearchParams: TSearcParams = {
    rootSearchNodeId: { id: '', repositoryId: '', serverId: '' },
    nodeTypes: [],
    searchRules: [],
    searchText: '',
    searchVisibility: 'NOT_DELETED',
};

export const SearchReducer = (state: TSearchReducerState = initial, action: TSearchActions) => {
    switch (action.type) {
        case SET_SEARCH_DATA: {
            const { id, nodeId, name, type, path, searchResult, isLoading, searchParams } = action.payload;

            if (id) {
                const newState = { ...state };

                newState[id] = {
                    ...newState[id],
                    id: addIfExist(id, state[id]?.id || null),
                    nodeId: addIfExist(
                        nodeId,
                        state[id]?.nodeId || {
                            id: '',
                            repositoryId: '',
                            serverId: '',
                        },
                    ),
                    name: addIfExist(name, state[id]?.name || null),
                    type: addIfExist(type, state[id]?.type || null),
                    path: addIfExist(path, state[id]?.path || ''),
                    searchResult: addIfExist(searchResult, state[id]?.searchResult || []),
                    isLoading: addIfExist(isLoading, state[id]?.isLoading || false),
                    filter: [],
                    searchParams: addIfExist(searchParams, state[id]?.searchParams || initSearchParams),
                };

                return newState;
            }

            return state;
        }

        case RESET_SEARCH_DATA: {
            const { nodeId } = action.payload;

            const newState = { ...state };
            delete newState[nodeId?.id];

            return newState;
        }

        case SET_SEARCH_RULES: {
            const { nodeId, searchRules } = action.payload;

            const searchState: TSearchState | undefined = state[nodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchParams = newSearchState.searchParams
                    ? { ...newSearchState.searchParams, searchRules }
                    : { ...initSearchParams, searchRules };

                return { ...state, [nodeId.id]: newSearchState };
            }

            return state;
        }

        case SET_SEARCH_TEXT: {
            const { nodeId, searchText } = action.payload;

            const searchState: TSearchState | undefined = state[nodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchParams = newSearchState.searchParams
                    ? { ...newSearchState.searchParams, searchText }
                    : { ...initSearchParams, searchText };

                return { ...state, [nodeId.id]: newSearchState };
            }

            return state;
        }

        case SET_SEARCH_NODE_TYPES: {
            const { nodeId, searchNodeTypes } = action.payload;

            const searchState: TSearchState | undefined = state[nodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchParams = newSearchState.searchParams
                    ? { ...newSearchState.searchParams, nodeTypes: searchNodeTypes }
                    : { ...initSearchParams, nodeTypes: searchNodeTypes };

                return { ...state, [nodeId.id]: newSearchState };
            }

            return state;
        }

        case SET_SEARCH_VISIBILITY: {
            const { nodeId, visibility } = action.payload;

            const searchState: TSearchState | undefined = state[nodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchParams = newSearchState.searchParams
                    ? { ...newSearchState.searchParams, searchVisibility: visibility }
                    : { ...initSearchParams, searchVisibility: visibility };

                return { ...state, [nodeId.id]: newSearchState };
            }

            return state;
        }

        case SET_ROOT_SEARCH_NODE_ID: {
            const { nodeId, rootSearchNodeId } = action.payload;

            const searchState: TSearchState | undefined = state[nodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchParams = newSearchState.searchParams
                    ? { ...newSearchState.searchParams, rootSearchNodeId }
                    : { ...initSearchParams, rootSearchNodeId };

                return { ...state, [nodeId.id]: newSearchState };
            }

            return state;
        }

        case REPORT_CLEAR_SEARCH_REQUEST: {
            const { reportNodeId } = action.payload;
            const searchState: TSearchState | undefined = state[reportNodeId.id];

            if (searchState) {
                const newSearchState = { ...searchState };
                newSearchState.searchResult = [];

                return { ...state, [reportNodeId.id]: newSearchState };
            }

            return state;
        }

        default: {
            return state;
        }
    }
};
