import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import style from './DbSearch.component.scss';
import { SearchPath } from '../SearchPath/SearchPath.component';
import { DbSearchListItem } from '../DbSearchListItem/DbSearchListItem.component';
import { useDispatch, useSelector } from 'react-redux';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { TSearchDataListItem } from '../../../reducers/search.reducer.types';
import { SearchField } from '../SearchField/SearchField.component';
import { SearchFilter } from '../SearchFilter/SearchFilter.component';
import { resetSearchData, setSearchNodeTypes, setSearchRules } from '../../../actions/search.actions';
import { AttributeType, AttributeTypeValueTypeEnum, NodeId, SearchRequestNodeTypesEnum } from '../../../serverapi/api';
import { presetMetaDataRequestWithPresetId } from '../../../actions/notation.actions';
import { SearchElementTypeSelectors } from '../selectors/searchElementType.selectors';
import icFilterActive from '../../../resources/icons/icFilterActive.svg';
import cx from 'classnames';
import icFilter from '../../../resources/icons/icFilter.svg';
import { AttributeFilter } from '../AttributeFilter/AttributeFilter.component';
import { ISearchRuleWithValueId } from '../AttributeFilter/AttributeFilter.types';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import icDelete from '../../../resources/icons/Deleted.svg';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '@/modules/DialogRoot/DialogRoot.constants';
import messages from './DbSearch.messages';
import { useIntl } from 'react-intl';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { systemAttributeTypes } from '../AttributeFilter/AttributeFilter.utils';
import { AttributeValueType } from '@/modules/FloatingAttributes/components/AttributesEditor/Attribute.types';

export const DbSearch = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLoading: boolean = useSelector(SearchSelectors.getLoadingStatus);
    const nodeId: NodeId = useSelector(SearchSelectors.getNodeId);
    const filterSearchResult: TSearchDataListItem[] = useSelector(SearchElementTypeSelectors.getSearchResult(nodeId));
    const searchRules: ISearchRuleWithValueId[] = useSelector(SearchSelectors.getSearchRules(nodeId));
    const searchNodeTypes: SearchRequestNodeTypesEnum[] = useSelector(SearchSelectors.getSearchNodeTypes(nodeId));
    const isDialogSearch: boolean = useSelector(SearchSelectors.isDialogSearch(nodeId));
    const presetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const attributeTypes: AttributeType[] = useSelector(
        AttributeTypeSelectors.allInPresetSorted(nodeId?.serverId, presetId),
    );
    const profile: TCurrentUserProfile | undefined = useSelector(
        UserProfileSelectors.selectUserProfileByNodeId(nodeId),
    );
    const accessibleAttributeTypes = attributeTypes.filter((attrType) =>
        ProfileBllService.isAttributeViewable(profile, attrType.id),
    );

    const [isAttributeFilterOpen, setIsAttributeFilterOpen] = useState<boolean>(false);

    const setSearchRulesHandler = (searchRules: ISearchRuleWithValueId[]) => {
        dispatch(setSearchRules(nodeId, searchRules));
    };

    const setSearchNodeTypesHandler = (searchNodeTypes: SearchRequestNodeTypesEnum[]) => {
        dispatch(setSearchNodeTypes(nodeId, searchNodeTypes));
    };

    useEffect(() => {
        if (nodeId) {
            dispatch(presetMetaDataRequestWithPresetId(nodeId));
        }

        return () => {
            if (!isDialogSearch) dispatch(resetSearchData(nodeId));
        };
    }, []);

    const handleAttributeFilterToggle = () => setIsAttributeFilterOpen(!isAttributeFilterOpen);

    const onDeleteAllConditions = () => {
        dispatch(
            openDialog(DialogType.CONFIRMATION, {
                onSubmit: () => {
                    setSearchRulesHandler([]);
                },
                title: intl.formatMessage(messages.deleteConfirmTitle),
                question: intl.formatMessage(messages.deleteConfirmQuestion),
                OKButtonText: intl.formatMessage(messages.delete),
            }),
        );
    };

    const getFiltersCount = () => {
        let count: number = searchRules.length;
        if (count === 0) return count;

        searchRules.forEach((rule) => {
            if (rule.values.length > 1) {
                const attributeValueType: AttributeTypeValueTypeEnum | undefined = [
                    ...accessibleAttributeTypes,
                    ...systemAttributeTypes,
                ].find((attrType) => attrType.id === rule.attributeTypeId)?.valueType;
                if (
                    attributeValueType !== AttributeValueType.MULTI_SELECT &&
                    attributeValueType !== AttributeValueType.PRINCIPAL
                ) {
                    count = count + rule.values.length - 1;
                }
            }
        });

        return count;
    };

    return (
        <div className={style.container}>
            <SearchPath />
            <div className={style.flexCenter}>
                <SearchField nodeId={nodeId} searchRules={searchRules} searchNodeTypes={searchNodeTypes} />
                <div className={cx(style.filterBtn, { [style.filterBtnActive]: isAttributeFilterOpen })}>
                    <Button
                        onClick={handleAttributeFilterToggle}
                        icon={isAttributeFilterOpen ? icFilterActive : icFilter}
                    />
                </div>

                {searchRules.length ? (
                    <div className={style.deleteConditionBtn}>
                        <div>{intl.formatMessage(messages.filters, { number: getFiltersCount() })}</div>
                        <Button visualStyle="text" size="small" onClick={onDeleteAllConditions} icon={icDelete} />
                    </div>
                ) : null}

                <SearchFilter searchNodeTypes={searchNodeTypes} setSearchNodeTypes={setSearchNodeTypesHandler} />
            </div>

            <AttributeFilter
                searchRules={searchRules}
                setSearchRules={setSearchRulesHandler}
                attributeTypes={accessibleAttributeTypes}
                nodeId={nodeId}
                isHidden={!isAttributeFilterOpen}
            />

            <div className={style.listContainer}>
                <List
                    itemLayout="vertical"
                    dataSource={filterSearchResult}
                    loading={isLoading}
                    renderItem={(item, index) => (
                        <List.Item>
                            <DbSearchListItem
                                index={index + 1}
                                path={item.path}
                                type={item.type}
                                multilingualName={item.multilingualName}
                                nodeId={item.nodeId}
                                elementType={item.elementType}
                                itemTypeName={item.itemTypeName}
                                deleted={item.deleted}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};
