// TODO использовать другой тип, не относящийся к Вики
import type { TWikiLink } from '@/models/tab.types';
import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash-es';
import messages from '../CommonToolbar.messages';
import icLinkCreate from 'icons/toolbar/controls/ic-link-create.svg';
import icLinkInSystem from 'icons/toolbar/controls/ic-link-in-system.svg';
import icLinkToSite from 'icons/toolbar/controls/ic-link-to-site.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { Dropdown } from '../../../Toolbar/Dropdown.component';
import { getSelectedText } from '../../common/helpers';

type TLinkState = {
    action: string;
    link?: TWikiLink;
};

type TLinkAttributes = {
    href: string;
    target?: string | null | undefined;
    rel?: string | null | undefined;
    class?: string | null | undefined;
};

const insertLink = (editor: Editor, text: string, attributes: TLinkAttributes) =>
    editor
        .chain()
        .focus()
        .deleteSelection()
        .setLink(attributes)
        .command(({ tr }) => {
            tr.insertText(text);
            return true;
        })
        .run();

const getFocusedBlock = (editor: Editor) => ({
    link: editor.getAttributes('link') as TLinkAttributes,
    text: getSelectedText(editor),
});

const getChangedBlock = (editor: Editor, value: TLinkState) => {
    const { action, link } = value;

    if (!link) {
        return false;
    }

    if (action === 'INTERNAL' && link.nodeId) {
        return editor?.commands.insertLink(link.nodeId, link.text);
    }

    if (action === 'EXTERNAL') {
        return insertLink(editor, link.text, { href: link.url });
    }

    return false;
};

type TLinksComponent = {
    openDialog: (
        submitCallback: (link: TWikiLink) => void,
        options: { url: string; text: string; external: boolean },
    ) => void;
};

export const LinksComponent: FC<TLinksComponent> = ({ openDialog }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<{ link: TLinkAttributes; text: string }, TLinkState>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const addLink = (action: string) =>
        openDialog((link: TWikiLink) => setCurrentState({ action, link }), {
            url: value?.link?.href || '',
            text: (action === 'EXTERNAL' && value?.text) || '',
            external: action === 'EXTERNAL',
        });

    const menuItems = [
        {
            value: 'INTERNAL',
            tooltip: intl.formatMessage(messages.internalLink),
            spriteSymbol: icLinkInSystem,
            // dataTest: '',
        },
        {
            value: 'EXTERNAL',
            tooltip: intl.formatMessage(messages.externalLink),
            spriteSymbol: icLinkToSite,
            selected: value && !isEmpty(value?.link?.href),
            // dataTest: '',
        },
    ];

    return (
        <Dropdown<string>
            items={menuItems}
            title={intl.formatMessage(messages.links)}
            compact
            defaultSprite={icLinkCreate}
            onSelect={addLink}
            // dataTest=""
        />
    );
};
