// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DbSearchDialog__dialog___vk8i .ant-modal-content{height:800px}.DbSearchDialog__dialog___vk8i .ant-modal-body{height:calc(100% - 64px)}", "",{"version":3,"sources":["webpack://./src/modules/Search/DbSearchDialog/DbSearchDialog.scss"],"names":[],"mappings":"AAEQ,kDACI,YAAA,CAEJ,+CACI,wBAAA","sourcesContent":[".dialog {\r\n    :global {\r\n        .ant-modal-content {\r\n            height: 800px;\r\n        }\r\n        .ant-modal-body {\r\n            height: calc(100% - 64px);\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "DbSearchDialog__dialog___vk8i"
};
export default ___CSS_LOADER_EXPORT___;
