import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NodeId, UserProperty } from '@/serverapi/api';
import { SAVE_TIMEOUT } from '@/utils/consts';
import { getUserProperty } from '@/selectors/authorization.selectors';

type TUseAutoSaveProps = {
    isEditMode: boolean;
    nodeId: NodeId;
    deps?: any[];
    callback: () => void;
};

export const useAutoSave = ({ isEditMode, nodeId, deps, callback }: TUseAutoSaveProps) => {
    const property: UserProperty | undefined = useSelector(getUserProperty);
    const saveTimeOut: number = property?.autoSaveTimeout || SAVE_TIMEOUT;

    useEffect(() => {
        const interval = setInterval(() => {
            if (isEditMode) {
                callback();
            }
        }, saveTimeOut);

        return () => {
            clearInterval(interval);
        };
    }, [isEditMode, nodeId, ...(deps || [])]);
};
