import React, { FC, useState } from 'react';
import theme from './EdgeStyleSettings.scss';
import { Icon } from '@/modules/UIKit';
import icEdit from '@/resources/icons/edit.svg';
import icCross from '@/resources/icons/ic-cross.svg';
import icArrowDown from '@/resources/icons/ic-arrow-full-down.svg';
import icArrowUp from '@/resources/icons/ic-arrow-full-up.svg';
import icAdd from '@/resources/icons/ic-add.svg';
import messages from './EdgeStyleSettings.messages';
import { useIntl } from 'react-intl';
import { Select } from '@/modules/UIKit/components/Select/Select.component';
import { ColorPicker } from './ColorPicker.component';
import { Form, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
    InternationalString,
    MatrixStyle,
    MatrixStyleCustom,
    MatrixStyleIcon,
    MatrixStyleUserIcon,
} from '@/serverapi/api';
import { MultiLangInputDialog } from '@/modules/MultiLangInputDialog/MultiLangInputDialog.component';
import { v4 as uuid } from 'uuid';
import { SymbolsTable } from './SymbolsTable.component';
import { EdgeSymbolType, IconType } from '../../Matrix.types';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { Checkbox } from '@/modules/UIKit/components/Checkbox/Checkbox.component';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { renderIcon } from '@/modules/Matrix/utils/Matrix.utils';

type TEdgeStyleSettingsProps = {
    currentStyles: MatrixStyle[];
    isAutomaticCellFillingMode?: boolean;
    setCurrentStyles: (newMatrixStyles: MatrixStyle[]) => void;
    setIsAutomaticCellFillingMode: (isAutomaticMode: boolean) => void;
    dataTest?: string;
};

export const EdgeStyleSettings: FC<TEdgeStyleSettingsProps> = ({
    currentStyles,
    isAutomaticCellFillingMode,
    setCurrentStyles,
    setIsAutomaticCellFillingMode,
}) => {
    const intl = useIntl();
    const [generalForm] = Form.useForm();

    const [symbolType, setSymbolType] = useState<EdgeSymbolType>(EdgeSymbolType.custom);
    const [symbol, setSymbol] = useState<string>('');
    const [icon, setIcon] = useState<IconType>(IconType.plus);
    const [userIconBase64, setUserIconBase64] = useState<string>('');
    const [userIconIsLoading, setUserIconIsLoading] = useState<boolean>(false);
    const [symbolDescription, setSymbolDescription] = useState<InternationalString | undefined>();
    const [color, setColor] = useState<string>('#000000');
    const [selectedSymbolId, setSelectedSymbolId] = useState<string>('');
    const [isSymbolEditing, setIsSymbolEditing] = useState<boolean>(false);

    const symbolTypesArr = Object.values(EdgeSymbolType);
    const iconsTypesArr = Object.values(IconType);

    const clearInputs = () => {
        setSymbol('');
        setIcon(IconType.plus);
        setUserIconBase64('');
        setSymbolDescription(undefined);
        setColor('#000000');
    };

    const onChangeSymbolHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSymbol(event.currentTarget.value.toUpperCase());
    };

    const onDropHandler = (file: File) => {
        setUserIconIsLoading(true);
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setUserIconIsLoading(false);
            setUserIconBase64(reader.result as string);
        };
        reader.onerror = () => {
            setUserIconIsLoading(false);
        };
    };

    const addOrEditSymbol = (editedSymbolId?: string) => {
        let newStyle: MatrixStyleCustom | MatrixStyleIcon | MatrixStyleUserIcon | null = null;
        const id = editedSymbolId || uuid();

        if (symbolType === EdgeSymbolType.custom) {
            newStyle = {
                id,
                color,
                text: symbol,
                type: symbolType,
                description: symbolDescription,
            } as MatrixStyleCustom;
        }

        if (symbolType === EdgeSymbolType.icon) {
            newStyle = {
                id,
                color,
                icon,
                type: symbolType,
                description: symbolDescription,
            } as MatrixStyleIcon;
        }

        if (symbolType === EdgeSymbolType.userIcon) {
            newStyle = {
                id,
                type: symbolType,
                description: symbolDescription,
                iconData: userIconBase64,
            } as MatrixStyleUserIcon;
        }

        if (!newStyle) return;

        const updatedStyles: MatrixStyle[] = [...currentStyles];
        if (editedSymbolId) {
            const editedSymbolIndex = updatedStyles.findIndex((style) => style.id === editedSymbolId);
            updatedStyles[editedSymbolIndex] = { ...newStyle };
            setIsSymbolEditing(false);
        } else {
            updatedStyles.push(newStyle);
        }
        setCurrentStyles(updatedStyles);
        clearInputs();
    };

    const onRowSelectHandler = (symbolId: string) => {
        if (isAutomaticCellFillingMode) return;

        if (symbolId !== selectedSymbolId) {
            setIsSymbolEditing(false);
        }
        setSelectedSymbolId(symbolId);
    };

    const changeSymbolOrder = (symbolId: string, up?: boolean) => {
        if (currentStyles.length === 0 || !symbolId) return;

        const currentIndex = currentStyles.findIndex((style) => style.id === symbolId);

        const updatedStyles = [...currentStyles];

        if (up && currentIndex > 0) {
            const tmp = updatedStyles[currentIndex];
            updatedStyles[currentIndex] = updatedStyles[currentIndex - 1];
            updatedStyles[currentIndex - 1] = tmp;
        }

        if (!up && currentIndex >= 0 && currentIndex !== updatedStyles.length - 1) {
            const tmp = updatedStyles[currentIndex];
            updatedStyles[currentIndex] = updatedStyles[currentIndex + 1];
            updatedStyles[currentIndex + 1] = tmp;
        }

        setCurrentStyles(updatedStyles);
    };

    const startEditSymbol = (symbolId: string) => {
        if (!symbolId) return;

        const currentSymbol = currentStyles.find((style) => style.id === symbolId);
        if (!currentSymbol) return;

        setSymbolType(currentSymbol.type as EdgeSymbolType);
        setSymbolDescription(currentSymbol.description);

        if (currentSymbol.type === EdgeSymbolType.custom) {
            setSymbol((currentSymbol as MatrixStyleCustom).text);
            setColor((currentSymbol as MatrixStyleCustom).color);
        }

        if (currentSymbol.type === EdgeSymbolType.icon) {
            setIcon((currentSymbol as MatrixStyleIcon).icon as IconType);
            setColor((currentSymbol as MatrixStyleIcon).color);
        }

        if (currentSymbol.type === EdgeSymbolType.userIcon) {
            setUserIconBase64((currentSymbol as MatrixStyleUserIcon).iconData);
        }

        setIsSymbolEditing(true);
    };

    const deleteSymbol = (symbolId: string) => {
        setCurrentStyles(currentStyles.filter((style) => style.id !== symbolId));
    };

    const automaticModeCheckboxHandler = () => {
        setIsAutomaticCellFillingMode(!isAutomaticCellFillingMode);
    };

    const isAddSybmolDisable =
        isAutomaticCellFillingMode ||
        (symbolType === EdgeSymbolType.custom && !symbol) ||
        (symbolType === EdgeSymbolType.icon && !icon) ||
        (symbolType === EdgeSymbolType.userIcon && !userIconBase64);

    return (
        <div className={theme.edgeStyleSettingsContainer}>
            <Checkbox
                checked={isAutomaticCellFillingMode}
                onChange={automaticModeCheckboxHandler}
                dataTest="automatic-cell-filling-mode_checkbox"
            >
                {intl.formatMessage(messages.automaticCellFillingMode)}
            </Checkbox>
            <div className={theme.header}>
                <div className={theme.tableTitle}>{intl.formatMessage(messages.symbolsVariants)}</div>
                <div className={theme.controlsContainer}>
                    <Icon
                        disabled={isAutomaticCellFillingMode}
                        className={theme.control}
                        spriteSymbol={icArrowUp}
                        onClick={(e) => {
                            changeSymbolOrder(selectedSymbolId, true);
                        }}
                    />
                    <Icon
                        disabled={isAutomaticCellFillingMode}
                        className={theme.control}
                        spriteSymbol={icArrowDown}
                        onClick={(e) => {
                            changeSymbolOrder(selectedSymbolId);
                        }}
                    />
                    <Icon
                        disabled={isAutomaticCellFillingMode}
                        className={theme.control}
                        spriteSymbol={icEdit}
                        onClick={() => startEditSymbol(selectedSymbolId)}
                    />
                    <Icon
                        disabled={isAutomaticCellFillingMode}
                        className={theme.control}
                        spriteSymbol={icCross}
                        onClick={() => deleteSymbol(selectedSymbolId)}
                    />
                </div>
            </div>
            <div className={theme.tableContainer}>
                <SymbolsTable
                    selectedSymbolId={selectedSymbolId}
                    currentStyles={currentStyles}
                    onRowSelect={onRowSelectHandler}
                />
            </div>
            <div className={theme.settingsContainer}>
                <div className={theme.styleContainer}>
                    <Select
                        label={intl.formatMessage(messages.type)}
                        value={intl.formatMessage(messages[symbolType])}
                        onChange={(symbolType) => {
                            if (symbolType) setSymbolType(symbolType as EdgeSymbolType);
                        }}
                        wrapperClassName={theme.symbolTypeSelect}
                    >
                        {symbolTypesArr.map((symbolType) => (
                            <Select.Option
                                key={symbolType}
                                label={intl.formatMessage(messages[symbolType])}
                                value={symbolType}
                            />
                        ))}
                    </Select>
                    {symbolType === EdgeSymbolType.custom && (
                        <div className={theme.symbolContainer}>
                            <div className={theme.symbolInputLabel}>{intl.formatMessage(messages.symbol)}</div>
                            <input
                                disabled={isAutomaticCellFillingMode}
                                maxLength={1}
                                style={{ color: color }}
                                className={theme.symbolInput}
                                value={symbol}
                                onChange={onChangeSymbolHandler}
                            />
                        </div>
                    )}
                    {symbolType === EdgeSymbolType.icon && (
                        <div className={theme.symbolContainer}>
                            <Select
                                label={intl.formatMessage(messages.symbol)}
                                value={renderIcon(icon, color, theme.antdIconsContainer)}
                                onChange={(iconType) => {
                                    if (iconType) setIcon(iconType as IconType);
                                }}
                                wrapperClassName={theme.iconTypeSelect}
                                dropdownWidth={80}
                            >
                                {iconsTypesArr.map((iconType) => (
                                    <Select.Option
                                        key={iconType}
                                        label={intl.formatMessage(messages[iconType])}
                                        value={iconType}
                                    />
                                ))}
                            </Select>
                        </div>
                    )}
                    {symbolType === EdgeSymbolType.userIcon && (
                        <div className={theme.userSymbolContainer}>
                            {intl.formatMessage(messages.fileDropText)}
                            <Upload.Dragger
                                className={theme.fileDragger}
                                showUploadList={false}
                                accept="image/*"
                                customRequest={(e) => onDropHandler(e.file as File)}
                            >
                                {userIconBase64 && <img src={userIconBase64} />}
                                {!userIconBase64 && (
                                    <Spinner children={<InboxOutlined />} loading={userIconIsLoading} />
                                )}
                            </Upload.Dragger>
                        </div>
                    )}
                    {symbolType !== EdgeSymbolType.userIcon && (
                        <ColorPicker color={color} label={intl.formatMessage(messages.color)} setColor={setColor} />
                    )}
                </div>
                <div className={theme.descriptionContainer}>
                    <Form autoComplete="off" className={theme.descriptionForm} form={generalForm}>
                        <MultiLangInputDialog
                            disabled={isAutomaticCellFillingMode}
                            autoFocus
                            generalForm={generalForm}
                            label={`${intl.formatMessage(messages.iconDescription)}:`}
                            mainInputName="description"
                            multiLangValue={symbolDescription}
                            wrapperClassName={theme.descriptionInput}
                            onChange={setSymbolDescription}
                        />
                    </Form>
                </div>
            </div>
            <div className={theme.addButtonContainer}>
                {isSymbolEditing ? (
                    <Button disabled={isAddSybmolDisable} onClick={() => addOrEditSymbol(selectedSymbolId)}>
                        {intl.formatMessage(messages.saveSymbol)}
                    </Button>
                ) : (
                    <Button icon={icAdd} disabled={isAddSybmolDisable} onClick={() => addOrEditSymbol()}>
                        {intl.formatMessage(messages.addSymbol)}
                    </Button>
                )}
            </div>
        </div>
    );
};
