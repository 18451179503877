import { fetchNodesWithAttributes } from '@/actions/nodes.actions';
import { useAutoSave } from '@/hooks/useAutoSave';
import { EditorMode } from '@/models/editorMode';
import modelTypeMessages from '@/models/modelType.messages';
import { TReportTabType } from '@/models/tab.types';
import { Locale } from '@/modules/Header/components/Header/header.types';
import { storageValueToString } from '@/modules/ObjectPropertiesDialog/components/utils';
import { Icon } from '@/modules/UIKit';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { TableUIKit } from '@/modules/UIKit/components/Table/TableUIKit.component';
import { TColumn, TTableData } from '@/modules/UIKit/components/Table/TableUIKit.types';
import { TCurrentUserProfile } from '@/reducers/userProfile.reducer.types';
import addIcon from '@/resources/icons/Add_Other.svg';
import noData from '@/resources/icons/noData.svg';
import { AttributeTypeSelectors } from '@/selectors/attributeType.selectors';
import { getCurrentLocale } from '@/selectors/locale.selectors';
import { getNodesWithPresetIdByIds } from '@/selectors/nodes.selector';
import { TreeSelectors } from '@/selectors/tree.selectors';
import { UserProfileSelectors } from '@/selectors/userProfile.selectors';
import {
    AttributeType,
    AttributeValue,
    NodeId,
    PrincipalDescriptor,
    ReportColumnData,
    ReportData,
    ReportDataFillingTypeEnum,
    ReportNode,
    SearchResult,
} from '@/serverapi/api';
import { ProfileBllService } from '@/services/bll/ProfileBllService';
import { xor } from 'lodash-es';
import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
    reportAddColumn,
    reportDropNodes,
    reportSaveRequest,
    reportSearchRequest,
    reportSelectColumn,
} from '../actions/report.actions';
import { systemAttributeTypes } from '../AttributeSelectDialog/AttributeSelectDialog.utils';
import { ReportEditorSidePanel } from './ReportEditorSidebar/ReportEditorSidePanel.component';
import { ReportSelectors } from '../selectors/report.selectors';
import { ADD_COLUMN_ID } from '../utils/report.const';
import theme from './ReportEditor.scss';
import messages from './ReportEditor.messages';
import { PrincipalsSelectors } from '@/selectors/principals.selectors';
import { mapNodeToSystemAttributeValues } from '@/mxgraph/overlays/BPMMxCellOverlay.utils';
import { LocalesService } from '@/services/LocalesService';
import { resetSearchData } from '@/actions/search.actions';
import { Spinner } from '@/modules/Spinner/Spinner.component';
import { TNodeWithPresetId } from '@/selectors/types/nodesSelector.types';
import { ReportEditorFilterBar } from './ReportEditorFilterBar/ReportEditorFilterBar.component';

type TReportEditorProps = {
    tab: TReportTabType;
};

type TReportColumn = TColumn & {
    columnId?: string;
    selected?: boolean;
};

const COLUMN_WIDTH = 160;

export const ReportEditor: FC<TReportEditorProps> = ({ tab: { nodeId, mode } }) => {
    const report: ReportNode | undefined = useSelector(ReportSelectors.byId(nodeId));
    const reportData: ReportData | undefined = report?.reportData;
    const isReadMode: boolean = mode === EditorMode.Read;
    const reportColumns: ReportColumnData[] = reportData?.columns || [];
    const manuallyFilledNodes: NodeId[] = reportData?.manuallyFilledNodes || [];
    const searchResults: SearchResult[] = useSelector(ReportSelectors.searchResults(nodeId));
    const loading: boolean = useSelector(ReportSelectors.isLoading(nodeId));
    const nodes: TNodeWithPresetId[] = useSelector(getNodesWithPresetIdByIds(manuallyFilledNodes));
    const selectedColumnId: string | undefined = useSelector(ReportSelectors.selectedColumnId(nodeId));
    const fillingType: ReportDataFillingTypeEnum = useSelector(ReportSelectors.fillingType(nodeId));
    const principals: PrincipalDescriptor[] = useSelector(PrincipalsSelectors.getAll);
    const locale: Locale = useSelector(getCurrentLocale);
    const reportPresetId: string = useSelector(TreeSelectors.presetById(nodeId));
    const attributeTypes: AttributeType[] = useSelector(
        AttributeTypeSelectors.allInPresetSorted(nodeId?.serverId, reportPresetId),
    );
    const profile: TCurrentUserProfile | undefined = useSelector(
        UserProfileSelectors.selectUserProfileByNodeId(nodeId),
    );
    const accessibleAttributeTypes: AttributeType[] = attributeTypes.filter(
        (attrType) =>
            ProfileBllService.isAttributeViewable(profile, attrType.id) &&
            !reportColumns.some((column) => column.attributeType === 'USER' && column.attributeTypeId === attrType.id),
    );

    const filtredSystemAttributeTypes = systemAttributeTypes.filter(
        (attrType) =>
            !reportColumns.some(
                (column) => column.attributeType === 'SYSTEM' && column.attributeTypeId === attrType.id,
            ),
    );

    const [checedIds, setCheckedIds] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentIndex, setCurrentIndex] = useState<number>();

    const dispatch = useDispatch();
    const intl = useIntl();

    const saveReportHandler = () => {
        if (report) dispatch(reportSaveRequest(report));
    };

    useAutoSave({ isEditMode: !isReadMode, nodeId, deps: [report], callback: saveReportHandler });

    useEffect(() => {
        dispatch(fetchNodesWithAttributes(manuallyFilledNodes));

        return () => {
            dispatch(resetSearchData(nodeId));
        };
    }, []);

    useEffect(() => {
        if (reportData?.searchRequests && reportData.fillingType === 'AUTO') {
            dispatch(reportSearchRequest(nodeId, reportData.searchRequests));
        }
        setCheckedIds([]);
    }, [reportData?.searchRequests, reportData?.fillingType]);

    useEffect(() => {
        if (searchValue === '') {
            setCurrentIndex(undefined);
        } else {
            setCurrentIndex(0);
        }
    }, [searchValue]);

    useEffect(() => {
        if (isReadMode) {
            setCheckedIds([]);
        }
    }, [isReadMode]);

    const handleAddColumn = () => {
        dispatch(reportAddColumn(nodeId, selectedColumnId));
    };

    const columns: TReportColumn[] = reportColumns
        .sort((a, b) => a.orderNumber - b.orderNumber)
        .map(
            (reportColumn) =>
                ({
                    title: reportColumn.columnName || '',
                    dataKey: `${reportColumn.attributeType}_${reportColumn.attributeTypeId}`,
                    withoutSorter: true,
                    columnId: reportColumn.columnId,
                    selected: reportColumn.columnId === selectedColumnId,
                    width: COLUMN_WIDTH,
                } as TReportColumn),
        );

    const searchedNodes: TNodeWithPresetId[] = searchResults.map(
        (searchResult) =>
            ({
                ...searchResult,
                name: LocalesService.internationalStringToString(searchResult.multilingualName, locale),
                type: searchResult.nodeType,
                //здеcь нужно знать prestId для найденых элементов
                presetId: reportPresetId,
            } as TNodeWithPresetId),
    );

    const tableData: TTableData[] = (fillingType === 'AUTO' ? searchedNodes : nodes).map((node, index) => {
        const id = `${node.nodeId.repositoryId}_${node.nodeId.id}`;
        const res: { [id: string]: string | boolean | number | NodeId } = {
            id,
            nodeId: node.nodeId,
            checked: checedIds.includes(id),
            found: false,
        };
        const systemAttributeValues: AttributeValue[] = mapNodeToSystemAttributeValues(node, undefined);

        reportColumns.forEach((reportColumn) => {
            let stringAttributeValue = '';

            if (node.nodeId.repositoryId !== nodeId.repositoryId) {
                res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
            }

            if (reportColumn.attributeType === 'SYSTEM') {
                const systemAttributeValue: AttributeValue | undefined = systemAttributeValues.find(
                    (value) => value.typeId === reportColumn.attributeTypeId,
                );

                stringAttributeValue = storageValueToString(systemAttributeValue, locale, {
                    system: true,
                    attributeTypes: systemAttributeTypes,
                    principals: principals,
                });

                if (reportColumn.attributeTypeId === 'nodeType') {
                    stringAttributeValue =
                        modelTypeMessages[node.type] && intl.formatMessage(modelTypeMessages[node.type]);
                }
            }

            if (reportColumn.attributeType === 'USER') {
                const attributeValue: AttributeValue | undefined = node.attributes?.find(
                    (attribute) => attribute.typeId === reportColumn.attributeTypeId,
                );
                if (attributeValue && node.presetId === reportPresetId) {
                    stringAttributeValue = storageValueToString(attributeValue, locale, {
                        system: false,
                        attributeTypes: accessibleAttributeTypes,
                        principals: principals,
                    });
                }
            }

            res[`${reportColumn.attributeType}_${reportColumn.attributeTypeId}`] = stringAttributeValue;
            if (!res.found) {
                res.found =
                    searchValue !== '' && stringAttributeValue.toLowerCase().includes(searchValue.toLowerCase());
            }
        });
        return res;
    });

    const foundIndexes: number[] = tableData.reduce((prev, current, index) => {
        if (current.found) {
            prev.push(index);
        }
        return prev;
    }, [] as number[]);

    const incIndexHandler = () => {
        setCurrentIndex((prev) => {
            if (prev === undefined) {
                return 0;
            }
            if (prev !== undefined && prev + 1 < foundIndexes.length) {
                return prev + 1;
            } else {
                return 0;
            }
        });
    };

    const decIndexHandler = () => {
        setCurrentIndex((prev) => {
            if (prev === undefined) {
                return 0;
            }
            if (prev !== undefined && prev - 1 >= 0) {
                return prev - 1;
            } else {
                return foundIndexes.length - 1;
            }
        });
    };

    const currentColumn: ReportColumnData | undefined = reportColumns.find(
        (column) => column.columnId === selectedColumnId,
    );

    const onDropHandler = (event: React.DragEvent) => {
        const droppedNodeId: NodeId = JSON.parse(event.dataTransfer.getData('nodeId'));
        dispatch(reportDropNodes(nodeId, droppedNodeId));
    };

    const onColumnClickHandler = ({ columnData }) => {
        if (isReadMode) return;

        if (columnData?.columnId && columnData.columnId !== ADD_COLUMN_ID) {
            dispatch(reportSelectColumn(nodeId, columnData.columnId));
        }
    };

    const checkRowHandler = (rows) => {
        const ids: string[] = rows.map((row) => row.id);
        if (ids.length === 1) {
            setCheckedIds((prevIds) => xor(prevIds, ids));
        } else {
            if (rows.length > checedIds.length) {
                setCheckedIds(ids);
            } else {
                setCheckedIds([]);
            }
        }
    };

    const disableDelete: boolean = reportColumns.filter((col) => col.columnName).length < 2;

    const getIndexForScroll = (): number | undefined => {
        if (currentIndex !== undefined && foundIndexes.length > 0) {
            return foundIndexes[currentIndex];
        }

        return undefined;
    };

    const selectedNodeIds: NodeId[] = tableData
        .filter((row) => typeof row.id === 'string' && checedIds.includes(row.id))
        .map((row) => row.nodeId as NodeId);

    return (
        <div className={theme.container}>
            <div className={theme.colContainer}>
                <ReportEditorFilterBar
                    reportNodeId={nodeId}
                    selectedNodeIds={selectedNodeIds}
                    searchValue={searchValue}
                    disableSearchButtons={foundIndexes.length === 0}
                    showDeleteBtn={fillingType === 'MANUAL'}
                    setSearchValue={setSearchValue}
                    incIndex={incIndexHandler}
                    decIndex={decIndexHandler}
                />
                <div className={theme.rowContainer}>
                    <div
                        onDrop={onDropHandler}
                        onDragOver={(event: React.DragEvent) => {
                            if (!isReadMode && fillingType !== 'AUTO') {
                                event.preventDefault();
                            }
                        }}
                        className={`${theme.tableContainer} ${tableData.length === 0 ? theme.noData : ''} `}
                    >
                        <TableUIKit
                            withCkeckBox={!isReadMode}
                            checkRows={checkRowHandler}
                            columns={columns}
                            tableData={tableData}
                            onHeaderClick={onColumnClickHandler}
                            onColumnClick={onColumnClickHandler}
                            minWidth={columns.length * COLUMN_WIDTH + 60}
                            scrollToIndex={getIndexForScroll()}
                            disableRowClick
                        />
                        {tableData.length === 0 && (
                            <div className={theme.noDataWrapper}>
                                <Spinner loading={loading}>
                                    <Icon spriteSymbol={noData} className={theme.noDataIcon} />
                                    <div>{intl.formatMessage(messages.noData)}</div>
                                </Spinner>
                            </div>
                        )}
                    </div>
                    {!isReadMode && (
                        <div className={theme.addButtonContainer}>
                            <Button icon={addIcon} visualStyle="text" onClick={handleAddColumn} />
                        </div>
                    )}
                </div>
            </div>
            {!isReadMode && (
                <ReportEditorSidePanel
                    disableDelete={disableDelete}
                    reportNodeId={nodeId}
                    attributeTypes={accessibleAttributeTypes}
                    systemAttributeTypes={filtredSystemAttributeTypes}
                    currentColumn={currentColumn}
                />
            )}
        </div>
    );
};
