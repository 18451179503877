import React from 'react';
import { Icon } from '@/modules/UIKit';
import icUpload from '@/resources/icons/icUpload.svg';
import theme from '../RichTextEditor.component.scss';
import messages from '../RichTextEditor.messages';
import { useIntl } from 'react-intl';

export default ({ title, createdBy, updatedBy, updatedAt }) => {
    const intl = useIntl();

    return (
        <div className={theme.header}>
            <span className={theme.modelHeader}>
                {`${intl.formatMessage(messages.createdBy)} ${createdBy}, ${intl.formatMessage(
                    messages.updatedBy,
                )} ${updatedBy} ${updatedAt}`}
                <Icon spriteSymbol={icUpload} disabled={true} />
            </span>
            <span className={theme.modelName}>{title}</span>
        </div>
    );
};

