import type { TImageAttributes } from '../Editor/extensions/image.types';
import type { TLinkAttributes } from '../Editor/extensions/link-block.types';
import type { EditorView } from '@tiptap/pm/view';
import type { Selection } from '@tiptap/pm/state';
import type { Node } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/core';
import { isNodeActive } from '@tiptap/react';
import { isNodeSelection, isTextSelection } from '@tiptap/core';
import { IMAGE_NODE_NAME, LINK_NODE_NAME } from '../Editor/extensions/constants';

export const isTableSelected = (editor: Editor) => isNodeActive(editor?.view?.state, 'table');

export const isImageSelected = (editor: Editor) => isNodeActive(editor?.view?.state, IMAGE_NODE_NAME);

export const getSelectedText = (editor: Editor) => {
    const { view, state } = editor;
    const { from, to } = view.state.selection;

    return state.doc.textBetween(from, to, '');
};

export const hasSelectedText = (editor: Editor) => {
    return !editor.view.state.selection.empty;
};

export const getSelectedNode = (selection: Selection): Node | null =>
    (isTextSelection(selection) && selection.$head.parent) || (isNodeSelection(selection) && selection.node) || null;

// TODO
// workaround для устранения дефекта, описанного здесь:
// https://jira.silaunion.ru/browse/BPM-10011
export const isLeafNodeEvent = (view: EditorView, event: MouseEvent, position: number): boolean => {
    const domAtPos = view.domAtPos(position);

    return domAtPos.node !== event.target;
};

export const insertImageAt = (editor: Editor, attrs: TImageAttributes, position: number, updateSelection?: boolean) =>
    editor.commands.insertContentAt(position, { type: IMAGE_NODE_NAME, attrs }, { updateSelection: !!updateSelection });

export const insertLinkAt = (editor: Editor, attrs: TLinkAttributes, position: number, updateSelection?: boolean) =>
    editor.commands.insertContentAt(position, { type: LINK_NODE_NAME, attrs }, { updateSelection: !!updateSelection });
