import type { TWikiImage } from '@/models/tab.types';
import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import modelIcon from '@/resources/icons/addAttributeType.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { Button } from '../../../Toolbar/Button.component';

const getChangedBlock = (editor: Editor, image: TWikiImage) =>
    !!image.src && editor.chain().focus().setImage(image).run();

type TPreviewComponent = {
    openDialog: (submitCallback: (image: TWikiImage) => void) => void;
};

export const PreviewComponent: FC<TPreviewComponent> = ({ openDialog }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TWikiImage>({ stateObserver, getChangedBlock });

    return (
        <Button
            tooltipTitle={intl.formatMessage(messages.insertModelPreview)}
            icon={modelIcon}
            onClick={() => openDialog(setCurrentState)}
        />
    );
};
