import type { TImageAttributes } from '../../Editor/extensions/image.types';
import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from '../CommonToolbar.messages';
import icImage from 'icons/toolbar/controls/ic-attach-image.svg';
import { Button } from '../../../Toolbar/Button.component';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';

const getChangedBlock = (editor: Editor, image: TImageAttributes) =>
    !!image.src && editor.chain().focus().setImage(image).run();

type TImageComponent = {
    openDialog: (submitCallback: (file: File, alt: string, title: string) => void) => void;
    uploadImage: (file: File, id: string) => { src: string };
};

export const ImageComponent: FC<TImageComponent> = ({ openDialog, uploadImage }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TImageAttributes>({ stateObserver, getChangedBlock });

    const onReceiveImage = (file: File, alt: string, title: string) => {
        const id = uuid();
        const { src } = uploadImage(file, id);

        setCurrentState({ id, src, alt, title });
    };

    return (
        <Button
            tooltipTitle={intl.formatMessage(messages.insertImage)}
            icon={icImage}
            onClick={() => openDialog(onReceiveImage)}
            dataTest="wiki-toolbar-group_add-image-button"
        />
    );
};
